import { Component, OnInit, ViewChild } from '@angular/core';
import { MatMenu } from '@angular/material';

import { AuthService } from '../../shared/auth.service';
import { ChangeEmailService } from '../change-email-dialog';

@Component({
    selector: 'app-user-profile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent implements OnInit {
    @ViewChild('userMenu')
    userMenu: MatMenu;

    get userInitials() {
        if (this.auth.user && (this.auth.user.firstName || this.auth.user.lastName)) {
            const u = this.auth.user;
            let result = '';
            if (u.lastName && u.firstName) {
                result = u.firstName.substring(0, 1) + u.lastName.substring(0, 1);
            } else if (u.lastName) {
                result = u.lastName.substring(0, 1);
            } else if (u.firstName) {
                result = u.firstName.substring(0, 1);
            }
            return result.toUpperCase();
        }

        return '';
    }

    get userName() {
        if (this.auth.user && (this.auth.user.firstName || this.auth.user.lastName)) {
            const u = this.auth.user;
            let result = '';
            if (u.lastName && u.firstName) {
                result = u.firstName + ' ' + u.lastName;
            } else if (u.lastName) {
                result = u.lastName;
            } else if (u.firstName) {
                result = u.firstName;
            }
            return result;
        }

        return '';
    }
    constructor(public auth: AuthService, public changeEmail: ChangeEmailService) {}

    ngOnInit() {}
}
