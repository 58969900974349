import { DataSource } from '@angular/cdk/collections';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ApiService } from '../api.service';
import { SdkEngine, SdkEngineVersion } from '../sdk.model';

@Injectable()
export class SdkEngineVersionDataSource extends DataSource<SdkEngineVersion> {
    working = false;
    dataChange$ = new BehaviorSubject<SdkEngineVersion[]>([]);

    versionsByEngine: { [key: number]: SdkEngineVersion[] } = {};
    engines: { [key: number]: SdkEngine } = {};

    constructor(private api: ApiService) {
        super();
    }

    update() {
        this.versionsByEngine = {};

        this.working = true;

        this.api.getSdkEngines(true).subscribe(
            p => {
                p.forEach(engine => {
                    this.engines[engine.id] = engine;
                });

                this.api.getSdkEngineVersions(true).subscribe(
                    a => {
                        if (a && a.length > 0) {
                            a.sort((a, b) => {
                                if (a.deleted > b.deleted) {
                                    return 1;
                                } else if (a.deleted < b.deleted) {
                                    return -1;
                                }

                                return this.engines[a.engine].name.toLowerCase() <
                                    this.engines[b.engine].name.toLowerCase()
                                    ? -1
                                    : 1;
                            });

                            a.forEach(i => {
                                if (!this.versionsByEngine[i.engine]) {
                                    this.versionsByEngine[i.engine] = [];
                                }

                                this.versionsByEngine[i.engine].push(i);
                            });
                        }

                        this.working = false;

                        this.dataChange$.next(a);
                    },
                    () => (this.working = false),
                );
            },
            () => (this.working = false),
        );
    }

    connect(): Observable<SdkEngineVersion[]> {
        return this.dataChange$;
    }

    disconnect() {}
}
