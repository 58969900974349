import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { of, timer } from 'rxjs';
import { mergeMap, takeWhile } from 'rxjs/operators';

import { AuthService, SeoService } from '../../shared';
import { ChangeEmailService } from '../change-email-dialog';

@Component({
    selector: 'core-verify-org-approval',
    templateUrl: './verify-org-approval.component.html',
    styleUrls: ['./verify-org-approval.component.scss'],
})
export class VerifyOrgApprovalComponent implements OnDestroy {
    working = true;
    contactFormToggle = false;
    refreshTimer: any;

    constructor(
        public auth: AuthService,
        public changeEmail: ChangeEmailService,
        private router: Router,
        private seo: SeoService,
    ) {
        if (!this.auth.isAuthenticated()) {
            this.router.navigate(['/']);
        }

        this.auth.refreshOrgs().subscribe(() => {
            if (['rejected', 'approved', 'created', 'flagged'].includes(this.auth.activeStudio.status)) {
                this.router.navigate(['/']);
                return false;
            }

            this.seo.setTitle('Organization is Pending Approval');
            this.working = false;

            let countdown = 2 * 4; // two minutes
            this.refreshTimer = timer(15000, 15000)
                .pipe(
                    takeWhile(() => countdown-- > 0),
                    mergeMap(() => {
                        if (!this.auth.isVerified()) {
                            return this.auth.refreshUser();
                        }
                        return of(true);
                    }),
                    mergeMap(() => {
                        if (this.auth.isVerified()) {
                            return this.auth.refreshOrgs(true);
                        } else {
                            return of(true);
                        }
                    }),
                    takeWhile(() => this.auth.activeStudio && this.auth.activeStudio.status == 'pending'),
                )
                .subscribe(
                    () => {},
                    (err) => console.log('err', err),
                );
        });
    }

    ngOnDestroy(): void {
        if (this.refreshTimer) {
            this.refreshTimer.unsubscribe();
        }
    }
}
