import { DataSource } from '@angular/cdk/collections';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ApiService } from '../api.service';
import { SdkEngine } from '../sdk.model';

@Injectable()
export class SdkEngineDataSource extends DataSource<SdkEngine> {
    dataChange$ = new BehaviorSubject<SdkEngine[]>([]);
    working = false;

    constructor(private api: ApiService) {
        super();
    }

    update() {
        this.working = true;
        this.api.getSdkEngines(true).subscribe(
            a => {
                this.working = false;
                if (a) {
                    a.sort((a, b) => {
                        if (a.deleted > b.deleted) {
                            return 1;
                        } else if (a.deleted < b.deleted) {
                            return -1;
                        }

                        return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
                    });
                }

                this.dataChange$.next(a);
            },
            () => (this.working = false),
        );
    }

    connect(): Observable<SdkEngine[]> {
        return this.dataChange$;
    }

    disconnect() {}
}
