import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { Agreement, CanadaProvinces, Permission, USStates, hasPlatformPermission } from 'app/core';
import { SharedErrorDuplicateOrgName } from 'app/core/shared-errors';
import { VivoxPlatform } from 'app/core/vivox-platforms';
import { forkJoin, of } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { sprintf } from 'sprintf-js';

import { ApiService } from '../api.service';
import { AuthService } from '../auth.service';
import { LangService, LocalCountryName } from '../lang/lang.service';
import {
    DEDICATED_SERVER_TAGS,
    DedicatedServer,
    ENTERPRISE_SUPPORT_TAGS,
    EnterpriseSupport,
    Org,
    OrgType,
    OrganizationAgreement,
    setPlatformPermission,
} from '../org.model';
import { User } from '../user.model';

// const DEFAULT_VIVOX_SALES_CONTACT = 'sales@vivox.com';

@Component({
    selector: 'shared-org-form',
    templateUrl: './org-form.component.html',
    styleUrls: ['./org-form.component.scss'],
})
export class OrgFormComponent implements OnInit {
    @ViewChild('form')
    form: NgForm;
    @Output()
    save = new EventEmitter<Org>();
    @Input()
    admin = false;
    @Input()
    editMode = false;
    @Input()
    isCreate = false;
    @Input()
    register = false;

    urlPattern = /(^|\s)((https?:\/\/)?[\w-]+(\.[\w-]+)+\.?(:\d+)?(\/\S*)?)/;

    enterpriseSupportTags = ENTERPRISE_SUPPORT_TAGS;
    dedicatedServerTags = DEDICATED_SERVER_TAGS;

    _org: Org = {
        vivoxPlatform: VivoxPlatform.SDKv5,
    };

    orgType = OrgType;

    sfAccountExistsDlgShowing: boolean;
    existingSfAccountName: string;
    reuseSfAccount = false;

    zdAccountExistsDlgShowing: boolean;
    existingZdOrgName: string;
    reuseZdOrg = false;

    bannedCountry = false;
    bannedCountryMap = new Map([
        ['KP', 'North Korea'],
        ['CU', 'Cuba'],
        ['SY', 'Syria'],
        ['CM', 'Crimea'],
        ['SD', 'Sudan'],
        ['IR', 'Iran'],
    ]);

    orgIsApproved: boolean;
    businessContactEmail: string;
    techSupportContactEmail: string;
    countries: LocalCountryName[];
    members: User[];
    memberMap: { [key: string]: User } = {};
    _serverSideRecording: boolean;

    agreementList: OrganizationAgreement[];

    agreements: { current: Agreement[]; accepted: OrganizationAgreement[] } = {
        current: [],
        accepted: [], // sorted by agreed_when desc so that different MSAs can be found by most recently agreed
    };
    otosChecked = false;
    canadaPostalCodePattern = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
    usPostalCodePattern = /(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)/;
    usStates = USStates;
    caProvinces = CanadaProvinces;
    get serverSideRecording(): boolean {
        return this._serverSideRecording;
    }
    set serverSideRecording(value: boolean) {
        if (!value) {
            this.serverSideRecordingDomain = '';
        }
        this._serverSideRecording = value;
    }
    get serverSideRecordingDomain(): string {
        return this.org ? this.org.serverSideRecordingDomain : '';
    }
    set serverSideRecordingDomain(value: string) {
        if (this.org) {
            this.org.serverSideRecordingDomain = value;
        }
    }

    get org(): Org {
        return this._org;
    }

    @Input('org')
    set org(value: Org) {
        if (!value) {
            value = {
                vivoxPlatform: this.auth.sdkFromUser(),
            };
        }
        this._org = value;
        if (value.status == 'created') {
            // clear out the placeholder name created for zendesk. Now user can enter name
            value.name = '';
        }
        this.originalName = value.name;
        this.orgIsApproved = value.status === 'approved';

        if (this.org.individual !== undefined) {
            this.individual = this.org.individual;
        }

        if (this.editMode) {
            this.originalSize = this.org.size;
            this.originalSubmissionType = this.org.individual;
        }

        if (this.org.id) {
            this.api.getOrgMembers(this.org.id, false).subscribe((r) => {
                this.members = r;
                this.memberMap = {};
                if (this.members && this.members.length) {
                    this.members.forEach((m) => {
                        this.memberMap[m.email] = m;
                    });
                }
                this.updateBizAndTechContact();
            });

            if (this.admin) {
                this.api.getOrgAgreements(this.org.id).subscribe((agreements) => {
                    this.agreementList = agreements;
                });
            }
        }
        if (this.org.serverSideRecordingDomain) {
            this.serverSideRecording = true;
        }
    }

    get ue4Developer(): boolean | null {
        if (this.org) {
            return this.org.vivoxPlatform == VivoxPlatform.SDKue4;
        }

        return null;
    }

    set ue4Developer(value: boolean) {
        if (this.org && value) {
            this.org.vivoxPlatform = VivoxPlatform.SDKue4;
        }
    }

    get v4Developer(): boolean | null {
        if (this.org) {
            return this.org.vivoxPlatform == VivoxPlatform.SDKv4;
        }

        return null;
    }

    get slimForm(): boolean {
        return this.register && this.auth.user.registerOrgName && this.auth.user.registerOrgName.length > 0;
    }

    agreementsValid(): boolean {
        if (this.admin) {
            return true;
        }
        return this.otosChecked;
    }

    agreementLink(agreementId: number) {
        return `/agreement/${agreementId}?standalone=true`;
    }

    set v4Developer(value: boolean) {
        if (this.org && value) {
            this.org.vivoxPlatform = VivoxPlatform.SDKv4;
        }
    }

    get v5Developer(): boolean | null {
        if (this.org) {
            return this.org.vivoxPlatform == VivoxPlatform.SDKv5;
        }

        return null;
    }

    set v5Developer(value: boolean) {
        if (this.org && value) {
            this.org.vivoxPlatform = VivoxPlatform.SDKv5;
        }
    }

    get xboxOneDeveloper(): boolean | null {
        if (this.org) {
            return hasPlatformPermission(this.org.platformPermissions, Permission.PlatformXboxOne);
        }

        return null;
    }

    set xboxOneDeveloper(value: boolean) {
        setPlatformPermission(this.org, Permission.PlatformXboxOne, value);
    }

    get xboxSeriesXSDeveloper(): boolean | null {
        if (this.org) {
            return hasPlatformPermission(this.org.platformPermissions, Permission.PlatformXboxSXS);
        }

        return null;
    }

    set xboxSeriesXSDeveloper(value: boolean) {
        setPlatformPermission(this.org, Permission.PlatformXboxSXS, value);
    }

    get ps4Developer(): boolean | null {
        if (this.org) {
            return hasPlatformPermission(this.org.platformPermissions, Permission.PlatformPS4);
        }

        return null;
    }

    set ps4Developer(value: boolean) {
        setPlatformPermission(this.org, Permission.PlatformPS4, value);
    }

    get ps5Developer(): boolean | null {
        if (this.org) {
            return hasPlatformPermission(this.org.platformPermissions, Permission.PlatformPS5);
        }

        return null;
    }

    set ps5Developer(value: boolean) {
        setPlatformPermission(this.org, Permission.PlatformPS5, value);
    }

    get nintendoDeveloper(): boolean | null {
        if (this.org) {
            return hasPlatformPermission(this.org.platformPermissions, Permission.PlatformNintendo);
        }

        return null;
    }

    set nintendoDeveloper(value: boolean) {
        setPlatformPermission(this.org, Permission.PlatformNintendo, value);
    }

    get webkitDeveloper(): boolean | null {
        if (this.org) {
            return hasPlatformPermission(this.org.platformPermissions, Permission.PlatformWebkit);
        }

        return null;
    }

    set webkitDeveloper(value: boolean) {
        setPlatformPermission(this.org, Permission.PlatformWebkit, value);
    }

    get stadiaDeveloper(): boolean | null {
        if (this.org) {
            return hasPlatformPermission(this.org.platformPermissions, Permission.PlatformStadia);
        }

        return null;
    }

    set stadiaDeveloper(value: boolean) {
        setPlatformPermission(this.org, Permission.PlatformStadia, value);
    }

    get businessContactNameAndEmail() {
        let result = '';
        if (this.org.contactName) {
            result += this.org.contactName;
        }
        if (this.org.contactEmail) {
            if (result.length) {
                result += sprintf(' (%s)', this.org.contactEmail);
            } else {
                result += sprintf('(%s)', this.org.contactEmail);
            }
        }

        return result;
    }

    set businessContactNameAndEmail(value) {
        console.log(value); // these need to be here for the production compile to work
    }

    get techContactNameAndEmail() {
        let result = '';
        if (this.org.supportContactName) {
            result += this.org.supportContactName;
        }
        if (this.org.supportContactEmail) {
            if (result.length) {
                result += sprintf(' (%s)', this.org.supportContactEmail);
            } else {
                result += sprintf('(%s)', this.org.supportContactEmail);
            }
        }

        return result;
    }

    set techContactNameAndEmail(value) {
        console.log(value); // these need to be here for the production compile to work
    }

    working = false;
    nameTaken = false;
    individual: boolean;
    originalName = '';
    originalSize: string;
    originalSubmissionType: boolean;

    nameStateMatcher = {
        isErrorState: (control: FormControl, form: FormGroupDirective | NgForm): boolean => {
            // Error when invalid control is submitted
            const isSubmitted = form && form.submitted;
            const showErrors = this.nameTaken || (control.invalid && (control.dirty || control.touched || isSubmitted));

            return showErrors;
        },
    };

    sizeStateMatcher = {
        isErrorState: (control: FormControl, form: FormGroupDirective | NgForm): boolean => {
            // Error when invalid control is submitted
            const isSubmitted = form && form.submitted;
            const showErrors =
                (!this.sizeValid() || control.invalid) && (control.dirty || control.touched || isSubmitted);

            return showErrors;
        },
    };

    constructor(
        public auth: AuthService,
        private api: ApiService,
        private translate: TranslateService,
        private sb: MatSnackBar,
        private langService: LangService,
    ) {}

    ngOnInit() {
        if (this.slimForm) {
            this.org.name = this.auth.user.registerOrgName;
            this.org.size = this.auth.user.registerOrgSize;
        }

        if (this.isCreate) {
            this.individual = false;
        }

        this.updateBizAndTechContact();

        this.countries = this.langService.getCountries();
        this.langService.onLanguageChange.subscribe(() => {
            this.countries = this.langService.getCountries();
        });
    }

    memberFullName(u: User): string {
        let result = '';
        if (u.firstName) {
            result += u.firstName;
        }
        if (u.lastName) {
            if (result) {
                result += ' ';
            }
            result += u.lastName;
        }
        return result;
    }

    memberNameAndEmail(u: User): string {
        let result = this.memberFullName(u);

        if (u.email) {
            if (result) {
                result += sprintf(' (%s)', u.email);
            } else {
                result = sprintf('(%s)', u.email);
            }
        }
        return result;
    }

    updateBizAndTechContact() {
        let bizContactFound = false;
        let techContactFound = false;
        if (this.members && this.members.length) {
            this.members.forEach((m) => {
                if (this.org.contactEmail && m.email == this.org.contactEmail) {
                    this.org.contactName = this.memberFullName(m);
                    this.org.contactEmail = m.email;
                    this.businessContactEmail = this.org.contactEmail;
                    bizContactFound = true;
                }
                if (this.org.supportContactEmail && m.email == this.org.supportContactEmail) {
                    this.org.supportContactName = this.memberFullName(m);
                    this.org.supportContactEmail = m.email;
                    this.techSupportContactEmail = this.org.supportContactEmail;
                    techContactFound = true;
                }
            });
        }

        if (!this.admin && (!bizContactFound || !techContactFound)) {
            const u = this.auth.user;

            if (!bizContactFound) {
                this.org.contactName = u.firstName + ' ' + u.lastName;
                this.org.contactEmail = u.email;
                this.businessContactEmail = this.org.contactEmail;
            }

            if (!techContactFound) {
                this.org.supportContactName = u.firstName + ' ' + u.lastName;
                this.org.supportContactEmail = u.email;
                this.techSupportContactEmail = this.org.supportContactEmail;
            }
        }
    }

    get individualFlagExists() {
        return this.org && this.org.individual !== undefined;
    }

    isEmpty(str: string) {
        return !str || 0 === str.trim().length;
    }

    get showEnterpriseOptions() {
        return (this.org.status && ['pending', 'approved'].includes(this.org.status)) || this.isCreate;
    }

    get showResetSupportButton() {
        return !this.org.enterpriseSupport && !this.org.dedicatedServer;
    }

    get showIndieSupportButton() {
        return (
            this.org.enterpriseSupport === EnterpriseSupport.FREE && this.org.dedicatedServer === DedicatedServer.NONE
        );
    }

    private setIndividualOrgData() {
        this.form.form.get('size').setValue('1');

        if (!this.originalSubmissionType) {
            const sub = this.api.createOrgName().subscribe(
                (data) => {
                    this.form.form.get('name').setValue(data);
                    sub.unsubscribe();
                },
                () => sub.unsubscribe(),
            );
        } else {
            this.form.form.get('name').setValue(this.originalName);
        }
    }

    onSubmissionTypeChange() {
        if (!this.editMode) {
            if (this.individual) {
                this.setIndividualOrgData();
            } else {
                this.form.form.get('size').reset();
                this.form.form.get('name').reset();
            }

            this.updateBizAndTechContact();
        } else {
            if (this.individual) {
                this.setIndividualOrgData();
            } else {
                this.form.form.get('size').setValue(this.originalSize);
                this.form.form.get('name').setValue(this.originalName);
            }
        }
    }

    onBusinessContactChange(email: string) {
        this.org.contactName = this.memberFullName(this.memberMap[email]);
        this.org.contactEmail = email;
    }

    onTechSupportContactChange(email: string) {
        this.org.supportContactName = this.memberFullName(this.memberMap[email]);
        this.org.supportContactEmail = email;
    }

    sizeChange() {
        if (this.org.size) {
            this.individual = this.org.size.trim() == '1';
        }
    }

    sizeValid() {
        const size = this.org && this.org.size ? this.org.size : '';

        return size != '' && parseInt(size) != 0;
    }

    vivoxSalesContactValid() {
        if (this.admin && !this.isCreate) {
            return this.org && this.org.vivoxSalesContact && this.org.vivoxSalesContact != '';
        }
        return true;
    }

    onLeaveName() {
        this.nameTaken = false;
    }

    trimOrgField(field: string) {
        this.org[field] = this.org[field] ? this.org[field].trim() : '';
    }

    trimOrgFields() {
        this.org.name = this.org.name ? this.org.name.trim() : '';
        this.org.description = this.org.description ? this.org.description.trim() : '';
        this.org.homePage = this.org.homePage ? this.org.homePage.trim() : '';
        this.org.country = this.org.country ? this.org.country.trim() : '';
        this.org.size = this.org.size ? this.org.size.trim() : '';
        this.org.vivoxSalesContact = this.org.vivoxSalesContact ? this.org.vivoxSalesContact.trim() : '';
        this.org.streetAddress = this.org.streetAddress ? this.org.streetAddress.trim() : '';
        this.org.city = this.org.city ? this.org.city.trim() : '';
        this.org.state = this.org.state ? this.org.state.trim() : '';
        this.org.postalCode = this.org.postalCode ? this.org.postalCode.trim() : '';
    }

    onChangeHomePage(homePage: string) {
        if (this.urlPattern.test(homePage)) {
            const withHttp = (url: string) => (!/^https?:\/\//i.test(url) ? `http://${url}` : url);
            if (homePage) {
                this.org.homePage = withHttp(homePage);
            }
        }
    }

    async onSubmit() {
        this.working = true;
        this.trimOrgFields();
        // If the country provided in the input form is a flagged one - disallow submission.
        if (this.bannedCountryMap.has(this.org.country)) {
            this.bannedCountry = true;
            return;
        }

        if (this.isCreate && this.admin) {
            this.org.status = 'admincreate';

            const dbOrgExists = await this.api.getOrgName(this.org.name).toPromise();
            if (dbOrgExists > 0) {
                this.nameTaken = true;
                this.working = false;
                return;
            }

            this.working = false;
        }

        if (this.existingSfAccountName) {
            this.reuseSfAccount = false;
            this.sfAccountExistsDlgShowing = true;
        } else if (this.existingZdOrgName) {
            this.reuseZdOrg = false;
            this.zdAccountExistsDlgShowing = true;
        } else {
            this.submit();
        }
    }

    async submit() {
        this.working = true;

        if (!this.isCreate && this.auth.isVivox && this.originalName != this.org.name) {
            this.working = false;
            // "By changing an organization name, this will be propagated to both Salesforce and Zendesk,
            // "changing the organization/account that already exists."
            // "Please do not do this lightly as it can be confusing to Sales and Support agents.
            // "Are you sure you want to rename the org
            // "'{{oldName}}' to '{{newName}}'?\n\n Type CONFIRM to confirm."
            let dialog: any = this.translate.instant('user.org-form.renameConfirm', {
                oldName: this.originalName,
                newName: this.org.name,
            });
            let conf = window.prompt(dialog);
            if (conf != 'CONFIRM') {
                return;
            }
        }

        if (!this.admin && !this.org.id) {
            // only set if new org
            this.org.vivoxPlatform = this.auth.sdkFromUser();
        }
        this.org.individual = this.individual;
        this.org.skipSfAccountCreate = this.reuseSfAccount;
        this.org.reuseZdOrg = this.reuseZdOrg;

        // only set vivox platform if adding new org
        this.api
            .saveOrg(this.org)
            .pipe(
                mergeMap((org) => forkJoin([of(org), this.auth.refreshOrgs(true)])),
                tap(() => {
                    this.working = false;
                }),
            )
            .subscribe(
                (r) => {
                    this.save.emit(r[0]);
                },
                (err) => {
                    this.working = false;
                    let errorText = err.message;
                    if (err.error && err.error.data) {
                        if (typeof err.error.data == 'number') {
                            switch (err.error.data) {
                                case SharedErrorDuplicateOrgName:
                                    if (this.originalName !== undefined && this.originalName.trim() !== '') {
                                        this.org.name = this.originalName;
                                    }
                                    this.nameTaken = true;
                                    errorText = this.translate.instant('user.settings.org.duplicateOrgName');
                                    break;
                                default:
                                    errorText = this.translate.instant('user.settings.org.defaultSaveError');
                            }
                        } else {
                            errorText = err.error.data;
                        }
                    }
                    console.log(err);
                    this.sb.open(`Error saving: ${errorText}`, null, {
                        duration: 5000,
                        panelClass: 'bad-snack',
                    });
                },
            );
    }

    openSnackBar() {
        this.sb.open(this.translate.instant('user.settings.org.billingRequired'), null, {
            duration: 8000,
            panelClass: 'bad-snack',
        });
    }

    vivoxSdkRadioClick(event: MouseEvent, value: OrgType) {
        event.preventDefault();

        const switchType = (value: string) => {
            switch (value) {
                case OrgType.UE4:
                    this.org.vivoxPlatform = VivoxPlatform.SDKue4;
                    break;
                case OrgType.V4:
                    this.org.vivoxPlatform = VivoxPlatform.SDKv4;
                    break;
                default:
                    this.org.vivoxPlatform = VivoxPlatform.SDKv5;
                    break;
            }
        };

        if (!this.isCreate && this.org.id) {
            if (this.working) {
                return;
            }

            // check if org has apps
            this.working = true;
            this.api.getApps(null, null, true, this.org.id).subscribe(
                (data: any) => {
                    this.working = false;

                    if (data === 0) {
                        switchType(value);
                    } else {
                        const dialog = this.translate.instant('user.org-form.organizationHasApps');
                        window.alert(dialog);
                    }
                },
                () => (this.working = false),
            );
        } else {
            switchType(value);
        }
    }

    resetSupport() {
        this.org.enterpriseSupport = '';
        this.org.dedicatedServer = '';
        this.org.nonStandardMSA = false;
    }

    setIndieSupport() {
        this.org.enterpriseSupport = EnterpriseSupport.FREE;
        this.org.dedicatedServer = DedicatedServer.NONE;
        this.org.nonStandardMSA = true;
    }

    closeBg(e: any) {
        if (e.target.classList.contains('modal-bg') || e.target.classList.contains('close')) {
            this.close();
        }
    }

    close() {
        this.sfAccountExistsDlgShowing = false;
        this.zdAccountExistsDlgShowing = false;
        this.reuseSfAccount = null;
        this.reuseSfAccount = null;
    }

    reuseSalesforce() {
        this.sfAccountExistsDlgShowing = false;
        this.reuseSfAccount = true;

        if (this.existingZdOrgName) {
            this.reuseZdOrg = false;
            this.zdAccountExistsDlgShowing = true;
        } else {
            this.submit();
        }
    }

    reuseZendesk() {
        this.reuseZdOrg = true;
        this.zdAccountExistsDlgShowing = false;

        this.submit();
    }

    delete() {
        this.working = true;
        this.api.deleteOrg(this.org).subscribe(
            () => {
                this.auth.logout('org-form.component');
            },
            () => {
                this.working = false;
                this.sb.open(this.translate.instant('user.verify.org.deleteFail'), null, {
                    duration: 2000,
                    panelClass: 'bad-snack',
                });
            },
        );
    }
}
