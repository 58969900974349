import { Component, Input, OnChanges } from '@angular/core';
import { MatButtonToggleChange, MatSelectChange } from '@angular/material';
import { Permission } from 'app/core';
import * as moment from 'moment';

import { ApiService } from '../api.service';
import { App } from '../app.model';
import { CookieService } from '../cookie.service';

import { UserChart } from './user-chart';

@Component({
    selector: 'app-shared-user-chart',
    templateUrl: './user-chart.component.html',
    styleUrls: ['./user-chart.component.scss'],
})
export class UserChartComponent implements OnChanges {
    @Input()
    app: App;
    @Input()
    apps: App[];

    chartWorking = false;
    chartEmpty = false;

    usersConnected: UserChart;
    usersChannel: UserChart;
    activeChart = 'connected';

    dateFilterOpen = false;
    dateFilter = '7d';

    chartFilterMin: any = moment().subtract(24, 'months').toDate();
    chartFilterMax: any = moment().toDate();

    chartFilterStartDate: any = moment().subtract(30, 'days').toDate();
    chartFilterEndDate: any = moment().toDate();

    weekFormat: any = 'isoweek';

    envToggle = 'test';

    constructor(private api: ApiService, private cookieService: CookieService) {
        if (this.cookieService.vivoxEnvFilter) {
            this.envToggle = this.cookieService.vivoxEnvFilter;
        }
    }

    ngOnChanges() {
        if (this.app) {
            if (!this.app.status || this.app.status !== 'approved') {
                this.envToggle = 'test';
            }

            if (
                this.app.vivoxPlatform &&
                (this.app.vivoxPlatform == Permission.SDKue4 || this.app.vivoxPlatform == Permission.SDKv4)
            ) {
                this.activeChart = 'chanusers';
            }
            this.onChartFilter();
        }
    }

    formatCustom(d: any) {
        return moment(d).format('YYYY-MM-DD');
    }

    setDateFilter(f: string) {
        this.dateFilter = f;
        this.dateFilterOpen = false;
        this.cookieService.vivoxDateFilter = f;

        this.onChartFilter();
    }

    onEnvToggle(event: MatButtonToggleChange) {
        this.envToggle = event.value;
        this.cookieService.vivoxEnvFilter = event.value;
        this.onChartFilter();
    }

    onAppChange(event: MatSelectChange) {
        this.app = event.value;
        this.cookieService.vivoxAppFilter = this.app.id.toString();
        if (
            this.app.vivoxPlatform &&
            (this.app.vivoxPlatform == Permission.SDKue4 || this.app.vivoxPlatform == Permission.SDKv4)
        ) {
            this.activeChart = 'chanusers';
        }
        this.onChartFilter();
    }

    onChartFilter() {
        if (this.app === null) {
            this.chartWorking = false;
            this.chartEmpty = true;
            return;
        }

        this.chartWorking = true;
        this.chartEmpty = false;

        if (this.cookieService.vivoxDateFilter) {
            this.dateFilter = this.cookieService.vivoxDateFilter;
        }

        // custom
        let start = moment(this.chartFilterStartDate);
        let end = moment(this.chartFilterEndDate);

        let interval = '1d';
        if (start.format('YYYY-MM-DD') == end.format('YYYY-MM-DD')) {
            end = end.add(1, 'day').subtract(1, 'second');
        }

        // predefined range
        switch (this.dateFilter) {
            case '24h':
                start = moment().subtract(24, 'hours');
                end = moment();
                interval = '10m';
                break;
            case '2d':
                start = moment().subtract(1, 'days');
                end = moment();
                break;
            case '7d':
                start = moment().subtract(6, 'days');
                end = moment();
                break;
            case '30d':
                start = moment().subtract(29, 'days');
                end = moment();
                break;
            case '90d':
                start = moment().subtract(89, 'days');
                end = moment();
                break;
            case '6m':
                start = moment().subtract(5, 'months');
                end = moment();
                break;
            case '1y':
                start = moment().subtract(1, 'year');
                end = moment();
                break;
            case 'yesterday':
                start = moment().subtract(24, 'hours').set('hour', 0).set('minute', 0).set('second', 0);
                end = moment().subtract(24, 'hours').set('hour', 23).set('minute', 59).set('second', 59);
                interval = '10m';
                break;
            case 'dayBeforeYesterday':
                start = moment().subtract(48, 'hours').set('hour', 0).set('minute', 0).set('second', 0);
                end = moment().subtract(48, 'hours').set('hour', 23).set('minute', 59).set('second', 59);
                interval = '10m';
                break;
            case 'dayLastWeek':
                start = moment().subtract(7, 'days').set('hour', 0).set('minute', 0).set('second', 0);
                end = moment().subtract(7, 'days').set('hour', 23).set('minute', 59).set('second', 59);
                interval = '10m';
                break;
            case 'prevWeek':
                interval = '1d';
                start = moment()
                    .subtract(7, 'days')
                    .startOf(this.weekFormat)
                    .set('hour', 0)
                    .set('minute', 0)
                    .set('second', 0);

                end = moment()
                    .subtract(7, 'days')
                    .endOf(this.weekFormat)
                    .set('hour', 23)
                    .set('minute', 59)
                    .set('second', 59);
                break;
            case 'prevMonth':
                start = moment().subtract(1, 'month').startOf('month').set('hour', 0).set('minute', 0).set('second', 0);

                end = moment().subtract(1, 'month').endOf('month').set('hour', 23).set('minute', 59).set('second', 59);
                break;
            case 'prevYear':
                start = moment().subtract(1, 'year').startOf('year').set('hour', 0).set('minute', 0).set('second', 0);

                end = moment().subtract(1, 'year').endOf('year').set('hour', 23).set('minute', 59).set('second', 59);
                break;
            case 'today':
                start = moment().set('hour', 0).set('minute', 0).set('second', 0);
                end = moment();
                interval = '10m';
                break;
            case 'week':
                start = moment().startOf(this.weekFormat).set('hour', 0).set('minute', 0).set('second', 0);
                end = moment();
                break;
            case 'month':
                start = moment().startOf('month').set('hour', 0).set('minute', 0).set('second', 0);
                end = moment();
                break;
            case 'year':
                start = moment().startOf('year').set('hour', 0).set('minute', 0).set('second', 0);
                end = moment();
                break;
        }

        if (start.format('YYYY-MM-DD') == end.format('YYYY-MM-DD')) {
            interval = '10m';
        }

        this.api
            .getAppStatisticsDateRange(
                this.app,
                this.envToggle,
                this.activeChart,
                start.format(),
                end.format(),
                interval,
            )
            .subscribe(
                (stats) => {
                    this.chartWorking = false;

                    if (!stats || !stats.results || stats.results.length == 0 || !stats.results[0].series) {
                        this.chartEmpty = true;
                    }

                    if (this.activeChart == 'connected') {
                        this.usersConnected = new UserChart(stats, interval);
                    } else {
                        this.usersChannel = new UserChart(stats, interval);
                    }
                },
                () => {
                    this.chartWorking = false;
                    this.chartEmpty = true;
                },
            );
    }

    setChart(chart: string) {
        if (chart == this.activeChart) {
            return;
        }

        this.activeChart = chart;
        this.onChartFilter();
    }
}
