import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import moment from 'moment';

import { APIResponse, Announcement, AnnouncementMediaType, AnnouncementType, SeoService } from '../../shared';

@Component({
    selector: 'app-announcement',
    templateUrl: './announcement.component.html',
    styleUrls: ['./announcement.component.scss'],
})
export class AnnouncementComponent implements OnInit {
    announcements: Announcement[];
    working = false;

    mediaType = AnnouncementMediaType;

    constructor(private http: HttpClient, private seo: SeoService, private sanitizer: DomSanitizer) {}

    ngOnInit() {
        this.seo.setTitle('Announcements');

        this.working = true;
        this.http.get<APIResponse<Announcement[]>>('api/v1/announcement/check?all=1').subscribe(r => {
            this.announcements = [];

            if (r.data) {
                r.data.forEach(a => {
                    if (a.type === AnnouncementType.FEATURES) {
                        if (a.mediaType === this.mediaType.YOUTUBE) {
                            a.youtubeEmbedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
                                `https://www.youtube.com/embed/${a.media}?modestbranding=1&rel=0&showinfo=0`,
                            );
                        }

                        this.announcements.push(a);
                    }
                });

                this.announcements = this.announcements.sort((a, b) =>
                    moment(a.sendAt).unix() < moment(b.sendAt).unix() ? 1 : -1,
                );
            }

            this.working = false;
        });
    }
}
