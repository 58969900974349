import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Subject } from 'rxjs';

import { Announcement, AnnouncementMediaType } from '../../shared';

@Component({
    selector: 'app-announcement-dialog',
    templateUrl: './announcement-dialog.component.html',
    styleUrls: ['./announcement-dialog.component.scss'],
})
export class AnnouncementDialogComponent implements OnInit, OnDestroy {
    private ngUnsubscribe = new Subject<void>();

    announcement: Announcement;
    youtubeEmbedUrl: SafeResourceUrl;

    mediaType = AnnouncementMediaType;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private sanitizer: DomSanitizer,
        public dialogRef: MatDialogRef<AnnouncementDialogComponent>,
    ) {}

    ngOnInit() {
        this.announcement = this.data.announcement;

        if (this.announcement.mediaType === 'youtube') {
            this.youtubeEmbedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
                `https://www.youtube.com/embed/${this.announcement.media}?modestbranding=1&rel=0&showinfo=0`,
            );
        }
    }

    closeDialog() {
        this.dialogRef.close();
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
