import { Component } from '@angular/core';

import { AuthService } from '../../shared/auth.service';
import { SeoService } from '../../shared/seo.service';
import { UserLoginForgotRequest } from '../../shared/user.model';

@Component({
    selector: 'app-login-forgot',
    templateUrl: './login-forgot.component.html',
    styleUrls: ['./login-forgot.component.scss'],
})
export class LoginForgotComponent {
    working = false;
    step = 1;
    createAccountRedirectModalOpen = false;

    model: UserLoginForgotRequest = {
        email: '',
    };

    constructor(private auth: AuthService, private seo: SeoService) {
        this.seo.setTitle('Password Recovery');
    }

    onSubmit() {
        this.working = true;

        this.auth.resetPassword(this.model.email).subscribe(
            () => (this.step = 2),
            (err) => console.log(err),
        );
    }

    toggleCreateAccountRedirectModal() {
        this.createAccountRedirectModalOpen = !this.createAccountRedirectModalOpen;
    }
}
