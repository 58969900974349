import { AfterViewInit, Component } from '@angular/core';

import { FTUEComponent } from '../ftue.component';

@Component({
    selector: 'app-core-ftue-pre-app-exit',
    templateUrl: './pre-app-exit.component.html',
    styleUrls: [
        '../ftue.scss',
    ],
})
export class FTUEPreAppExitComponent extends FTUEComponent implements AfterViewInit {
    isActive = false;
    isExit = true;

    constructor() {
        super();
    }

    ngAfterViewInit() {
        this.isActive = true;
    }
}
