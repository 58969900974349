import { Permission, updatePlatformPermissions } from 'app/core/permissions';

export interface Org {
    id?: number;
    name?: string;
    description?: string;
    status?: string;
    applicationHeroImage?: string;
    applicationIcon?: string;
    contactEmail?: string;
    contactName?: string;
    supportContactName?: string;
    supportContactEmail?: string;
    vivoxTechnicalContactEmail?: string;
    createdBy?: string;
    createdByEmail?: string;
    individual?: boolean;
    logo?: string;
    slug?: string;
    type?: string;
    rejectionNotes?: string;
    deleted?: boolean;
    acceptedMsa?: boolean;
    acceptedMsaAt?: Date;
    parent?: Org;
    vivoxPlatform?: string;
    platformPermissions?: Permission[];
    pendingPermissions?: Permission[];
    migratedToUdash?: 'NOT_NEEDED' | 'MIGRATED' | 'NOT_MIGRATED';
    migratedByName?: string;
    migratedByEmail?: string;
    migratedToOrgId?: string;
    flagNotes?: string;
    skipApprovedEmail?: boolean;
    skipSfAccountCreate?: boolean;
    reuseZdOrg?: boolean;
    lastUpdated?: Date;

    rejectSalesContact?: OrgSalesContact;

    remoteApiEnabled?: boolean;
    remoteApiUsername?: string;
    remoteApiPassword?: string;

    size?: string;
    streetAddress?: string;
    city?: string;
    state?: string;
    postalCode?: string;
    country?: string;
    billingAddress?: string;
    homePage?: string;
    nonStandardMSA?: boolean;
    vivoxSalesContact?: string;
    regionalSalesContact?: string;
    vivoxTechnicalAccountManager?: string;
    regionalTechnicalAccountManager?: string;
    estimatedPCU?: number;
    estimatedGoLiveDate?: string;
    enterpriseSupport?: string;
    dedicatedServer?: string;
    duplicate?: string;
    speechToText?: boolean;
    serverSideRecordingDomain?: string;
}

export function setPlatformPermission(org: Org, permission: Permission, value: boolean) {
    org.platformPermissions = updatePlatformPermissions(org.platformPermissions, permission, value);
}

export interface OrgSalesContact {
    salesName: string;
    salesEmail: string;
}

export interface QuickOrg {
    id?: number;
    name?: string;
    type?: string;
    status?: string;
    vivoxPlatform?: string;
    platformPermissions?: Permission[];
    pendingPermissions?: Permission[];
    migratedToUdash?: 'NOT_NEEDED' | 'MIGRATED' | 'NOT_MIGRATED';
}

export interface OrgSelect {
    selected: boolean;
    orgId: number;
    orgName: string;
}

export interface OrgUploadRequest {
    id: number;
    contentType: string;
    fileName: string;
}

export interface OrganizationAgreement {
    id?: number;
    orgId?: number;
    type?: string;
    subType?: string;
    agreementId?: number;
    agreedWhen?: Date;
}

export enum OrgType {
    UE4 = 'ue4',
    V4 = 'v4',
    V5 = 'v5',
}

export enum EnterpriseSupport {
    NONE = 'enterprise_support_none',
    FREE = 'enterprise_support_free',
    BILLED = 'enterprise_support_billed',
    PAID = 'enterprise_support_paid',
}

export enum DedicatedServer {
    NONE = 'dedicated_server_none',
    FREE = 'dedicated_server_free',
    PAID = 'dedicated_server_paid',
}

export const ENTERPRISE_SUPPORT_TAGS = Object.keys(EnterpriseSupport).map((key) => EnterpriseSupport[key]);
export const DEDICATED_SERVER_TAGS = Object.keys(DedicatedServer).map((key) => DedicatedServer[key]);
