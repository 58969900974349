import { Pipe, PipeTransform } from '@angular/core';

import { environment } from '../../../environments/environment';

@Pipe({
    name: 'otAppContent',
})
export class OTAppContentPipe implements PipeTransform {
    constructor() {}

    transform(str: string): string {
        if (str) {
            return `${environment.otAppContent}${str}`;
        }

        return null;
    }
}
