import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, LangService } from 'app/shared';

import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements OnInit {
    isFaq = false;
    isForums = false;
    zendeskForumLink: string;
    zendeskFaqLink: string;
    constructor(private router: Router, public auth: AuthService, private langService: LangService) {}

    ngOnInit() {
        if (this.router.url.search('/faqs') === 0) {
            this.isFaq = true;
        }
        if (this.router.url.search('/forums') === 0) {
            this.isForums = true;
        }
        this.langService.onLanguageChange.subscribe((lang) => {
            this.switchZendeskLinks(lang);
        });
        this.switchZendeskLinks(this.langService.currentLang);
    }

    switchZendeskLinks(lang: string) {
        const zendeskLanguage = this.langService.getLangCode(lang);

        this.zendeskForumLink = environment.zendeskURL + '/hc/' + zendeskLanguage + '/community/topics';
        this.zendeskFaqLink = environment.zendeskURL + '/hc/' + zendeskLanguage;
    }
}
