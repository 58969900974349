import { Component } from '@angular/core';

import { AuthService } from 'app/shared';

@Component({
    selector: 'app-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss'],
})
export class ErrorComponent {
    constructor(public auth: AuthService) {}
}
