import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';

// todo: fixme when bug is fixed with BrowserDomAdapter not being exported
@Injectable()
export class LogService {
    constructor() {}

    public warn(ctx: any, msg: string, params?: any): void {
        this.log(
            `%c [${ctx.constructor.name}]: ${msg}`,
            `border-left: 2px solid yellow; color: yellow; padding: 3px; background: #222`,
            params,
        );
    }

    public info(ctx: any, msg: string, params?: any): void {
        this.log(
            `%c [${ctx.constructor.name}]: ${msg}`,
            `border-left: 2px solid #4990e2; color: #4990e2; padding: 3px; background: #222`,
            params,
        );
    }

    public error(ctx: any, msg: string, params?: any): void {
        this.log(
            `%c [${ctx.constructor.name}]: ${msg}`,
            `border-left: 2px solid #921c1c; background-color: #921c1c; color: #fff; padding: 3px; background: #222`,
            params,
        );
    }

    private log(message, styles, params) {
        if (environment.production) {
            return;
        }

        if (params) {
            console.log(message, styles, params);
        } else {
            console.log(message, styles);
        }
    }
}
