import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewContainerRef } from '@angular/core';
import { MatSlideToggleChange, MatSnackBar } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { interval, of } from 'rxjs';
import { concatMap, filter, map, mergeMap, takeWhile } from 'rxjs/operators';

import { APIResponse, ApiService, AuthService, SeoService, Suppression } from '../../../shared';
import { FTUECCPAMailComponent } from '../../ftue/ccpa-mail/ccpa-mail.component';
import { FTUEService } from '../../ftue/ftue.service';

@Component({
    selector: 'core-settings-user',
    templateUrl: 'settings-user.component.html',
    styleUrls: ['settings-user.component.scss'],
})
export class SettingsUserComponent implements OnInit {
    profilePicture: File;

    passwordEmailSent = false;
    userFirstName: string;
    userLastName: string;
    userTitle: string;
    userPicture: string;
    ccpaGenOk = false;
    building = false;
    lastCCPAGenDate = null;

    suppressions: Suppression[] = [];
    ccpaJobGuid: any;

    working = false;

    constructor(
        public auth: AuthService,
        private api: ApiService,
        private http: HttpClient,
        private sb: MatSnackBar,
        private translate: TranslateService,
        private seo: SeoService,
        private ftue: FTUEService,
        private viewContainer: ViewContainerRef,
        @Inject(DOCUMENT) private document: Document,
    ) {
        this.seo.setTitle('Account Settings');
    }

    ngOnInit() {
        this.userFirstName = this.auth.user.firstName;
        this.userLastName = this.auth.user.lastName;
        this.userTitle = this.auth.user.title;
        this.userPicture = this.auth.user.picture;

        this.http.get<APIResponse<Suppression[]>>(`api/v1/user/suppressions`).subscribe((res) => {
            this.suppressions = res.data;
        });

        this.refreshCCPA();

        this.api.checkCCPAReportActiveJob().subscribe((guid) => {
            this.ccpaJobGuid = guid;

            if (guid) {
                this.startPollingForCompletion(guid);
            }
        });
    }

    refreshCCPA() {
        this.auth.refreshUser().subscribe(() => {
            if (!this.auth.user.lastCCPAAt) {
                this.ccpaGenOk = true;
                this.lastCCPAGenDate = null;
            } else {
                this.ccpaGenOk =
                    moment().utc().startOf('day').subtract(6, 'months') >
                    moment.utc(this.auth.user.lastCCPAAt).startOf('day');

                this.lastCCPAGenDate = this.translate.instant('user.settings.user.lastCCPAGenDate', {
                    genDate: moment.utc(this.auth.user.lastCCPAAt).format('YYYY/MM/DD'),
                });
            }
        });
    }

    toggleSuppression(event: MatSlideToggleChange, id: number) {
        if (event.checked) {
            // turn suppression off (subscribe)
            this.http.delete(`api/v1/user/suppressions/` + id).subscribe(() => {});
        } else {
            // turn suppression on (unsubscribe)
            this.http.post(`api/v1/user/suppressions/` + id, {}).subscribe(() => {});
        }
    }

    uploadImage(file) {
        if (!file) {
            return of(null);
        }

        return this.api
            .createUserUpload(this.auth.user.userID, {
                id: this.auth.user.userID,
                contentType: file.type,
                fileName: file.name,
            })
            .pipe(
                mergeMap((url) => {
                    let headers = new HttpHeaders();
                    headers = headers.set('x-amz-acl', 'public-read');

                    const req = new HttpRequest('PUT', url, file, {
                        reportProgress: true,
                        headers: headers,
                        responseType: 'text',
                    });

                    return this.http.request(req).pipe(
                        filter((e) => e instanceof HttpResponse),
                        map(() => url),
                    );
                }),
                map((url) => {
                    url = url.split('?')[0];
                    this.auth.updatePicture(url);

                    this.sb.open(this.translate.instant('user.settings.user.profilePictureUpdated'), null, {
                        duration: 5000,
                    });

                    return url;
                }),
            );
    }

    onSubmit() {
        this.working = true;

        this.uploadImage(this.profilePicture).subscribe(
            (url) => {
                if (
                    this.userFirstName != this.auth.user.firstName ||
                    this.userLastName != this.auth.user.lastName ||
                    this.userTitle != this.auth.user.title ||
                    this.userPicture != url
                ) {
                    this.auth.user.firstName = this.userFirstName;
                    this.auth.user.lastName = this.userLastName;
                    this.auth.user.title = this.userTitle;
                    this.auth.user.picture = url;
                    this.auth.updateUser(this.auth.user).subscribe(
                        () => {
                            this.working = false;
                            this.sb.open(this.translate.instant('user.settings.user.metadataUpdated'), null, {
                                duration: 5000,
                            });
                        },
                        () => {
                            this.working = false;
                            this.sb.open(this.translate.instant('user.settings.user.metadataError'), null, {
                                duration: 5000,
                                panelClass: 'bad-snack',
                            });
                        },
                    );
                } else {
                    this.working = false;
                }
            },
            () => {
                this.working = false;
            },
        );
    }

    onChangePassword() {
        this.auth.changePassword().subscribe(() => (this.passwordEmailSent = true));
    }

    generateCCPA() {
        if (this.building || !this.ccpaGenOk) {
            return;
        }

        this.ftue.show(null, this.viewContainer, FTUECCPAMailComponent, null, (params) => {
            if (params.mailToCaliResident !== undefined) {
                this.building = true;

                this.api.startCCPAReportBuild(params).subscribe(
                    (guid) => {
                        this.sb.open(this.translate.instant('user.settings.user.ccpaEmailWhenDone'), null, {
                            duration: 5000,
                        });
                        this.startPollingForCompletion(guid);
                    },
                    (err) => {
                        this.reportBuildFailed(err);
                        this.building = false;
                    },
                );
            }
        });
    }

    downloadCCPA() {
        this.api.ccpaDownloadForCurrentUser().subscribe(
            (ccpaUrl) => {
                this.document.location.href = ccpaUrl;
            },
            (err) => {
                console.log(err);
            },
        );
    }

    startPollingForCompletion(guid: string) {
        this.building = true;

        interval(5000)
            .pipe(
                concatMap((_) => this.api.checkCCPAReportBuildJob(guid)),
                map((r) => {
                    return r;
                }),
                takeWhile((_) => this.building),
            )
            .subscribe(
                (r) => {
                    if (r.status == 'done') {
                        this.auth.refreshUser().subscribe(
                            () => {
                                this.refreshCCPA();
                                this.building = false;
                            },
                            () => {
                                this.building = false;
                            },
                        );
                    }
                    if (r.status == 'failed') {
                        this.reportBuildFailed(r.result);
                        this.building = false;
                    }
                },
                (err) => {
                    this.reportBuildFailed(err);
                    this.building = false;
                },
            );
    }

    reportBuildFailed(err: any) {
        this.sb.open(
            `Error generating CCPA report: ${err.error && err.error.data ? err.error.data : err.message}`,
            null,
            {
                duration: 5000,
                panelClass: 'bad-snack',
            },
        );
    }
}
