import { DataSource } from '@angular/cdk/collections';
import { Injectable } from '@angular/core';
import { allSettled } from 'app/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';

import { ApiService } from '../api.service';
import { Env } from '../env.model';

@Injectable()
export class EnvDataSource extends DataSource<Env> {
    working = false;
    dataChange$ = new BehaviorSubject<Env[]>([]);

    constructor(private api: ApiService) {
        super();
    }

    async update(params?: { platform?: string; sort?: string; direction?: string; skipEnvStats?: boolean }) {
        const { platform, sort, direction, skipEnvStats } = params;
        this.working = true;
        const envs = await this.api.getEnvs({ platform, sort, direction }).toPromise();
        const finalEnvs = envs.slice();

        // render the envs when first loaded (should be quick)
        this.dataChange$.next(envs);

        const envStatsPromises = [];
        if (!skipEnvStats) {
            // then process to load the stats in the background with regular promises (so we can handle aborted calls better)
            for (let i = 0; i < envs.length; i++) {
                finalEnvs[i].currentUsers = -1;
                envStatsPromises.push(
                    this.api
                        .getEnvStatistics(finalEnvs[i])
                        .pipe(timeout(5000))
                        .toPromise()
                        .then((stat) => (finalEnvs[i].currentUsers = stat))
                        .catch((err) =>
                            console.log(
                                'api.getEnvStatistics failed for env with the following error:',
                                finalEnvs[i].id,
                                err,
                            ),
                        ),
                );
            }
        }
        await allSettled(envStatsPromises);

        this.working = false;
        this.dataChange$.next(finalEnvs);
    }

    connect(): Observable<Env[]> {
        return this.dataChange$;
    }

    disconnect() {}
}
