import { Observable, of } from 'rxjs';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';

import { AuthService, LogService } from '../shared';

import { AuthenticatedGuard } from './authenticated.guard';

@Injectable()
export class AdminGuard extends AuthenticatedGuard implements CanActivate, CanActivateChild {
    constructor(private adminAuth: AuthService, private adminRouter: Router, private adminLog: LogService) {
        super(adminAuth, adminRouter, adminLog);
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        if (route.queryParams.r) {
            this.adminAuth.loginRedirectURI = route.queryParams.r;
        }
        if (route.queryParams.return_to) {
            this.adminAuth.loginReturnURL = route.queryParams.return_to;
        }

        if (!this.adminAuth.isAuthenticated() || !this.adminAuth.user) {
            this.adminLog.warn(this, 'User not authenticated');

            this.adminAuth.loginReturnPath = state.url;
            this.adminRouter.navigate(['']);
            return of(false);
        }

        if (this.adminAuth.isVivox) {
            return of(true);
        }

        this.adminLog.warn(this, 'Not an admin');
        this.adminRouter.navigate(['/apps']);
        return of(false);
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.canActivate(route, state);
    }
}
