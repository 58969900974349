import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, Inject, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { DOCUMENT } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, forkJoin } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { sprintf } from 'sprintf-js';

import { APIResponse, ApiService, AuthService, SeoService, addToClipboard } from '../../shared';
import {
    Sdk,
    SdkArchitecture,
    SdkEngine,
    SdkEngineVersion,
    SdkPlatform,
    SdkPlatformVersion,
    SdkVersion,
} from '../../shared/sdk.model';
import { ConditionalAccessOption } from '../models';
import { Permission, platformPermissionsOnly } from '../permissions';

import { SDKsService } from './sdks.service';

interface Sort {
    active: string;
    direction: number;
}

@Component({
    selector: 'core-sdks',
    templateUrl: './sdks.component.html',
    styleUrls: ['./sdks.component.scss'],
})
export class SdksComponent implements OnDestroy, AfterViewInit {
    engines: { [key: number]: SdkEngine } = {};
    engineVersions: { [key: number]: SdkEngineVersion } = {};
    sdkVersions: { [key: number]: SdkVersion } = {};
    platforms: { [key: number]: SdkPlatform };
    selectedPlatformId: number;
    platformsArr: SdkPlatform[];
    platformVersions: { [key: number]: SdkPlatformVersion } = {};
    platformVersionsArr: SdkPlatformVersion[] = [];
    architectures: { [key: number]: SdkArchitecture } = {};
    sdksLive: { [key: number]: Sdk[] };
    sdksArchived: { [key: number]: Sdk[] } = {};
    activeSdk: Sdk;
    sdkDetailsOpen = false;
    showDownloadText: boolean;
    link: string;
    ConditionalAccessOption = ConditionalAccessOption;

    private ngUnsubscribe = new Subject<void>();
    sdksArchivedObs: Observable<{ [key: number]: Sdk[] }>;
    sdksLiveObs: Observable<{ [key: number]: Sdk[] }>;

    sort: Sort = {
        active: 'version',
        direction: 1,
    };

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private api: ApiService,
        private seo: SeoService,
        public auth: AuthService,
        private http: HttpClient,
        private translate: TranslateService,
        public snackBar: MatSnackBar,
        public sdksService: SDKsService,
        route: ActivatedRoute,
        router: Router,
    ) {
        this.seo.setTitle('Downloads');

        this.link = route.snapshot.paramMap.get('link');
        if (this.link) {
            this.auth.deepSdkLink = this.link;
            router.navigate(['/downloads']);
            return;
        }

        this.sdksLiveObs = this.api
            .getSdks({ status: 'live', org: this.auth.activeStudio && this.auth.activeStudio.id })
            .pipe(
                takeUntil(this.ngUnsubscribe),
                map((r) => {
                    const res = {};

                    if (r && r.length > 0) {
                        r = this.filterSdks(r);
                        r.forEach((e) => {
                            if (!res[e.platform]) {
                                res[e.platform] = [];
                            }

                            res[e.platform].push(e);
                        });

                        for (let i in res) {
                            res[i] = res[i].sort((a, b) => {
                                return this.comparableVersionNumber(b.version) < this.comparableVersionNumber(a.version)
                                    ? -1
                                    : 1;
                            });
                        }
                    }

                    return res;
                }),
            );

        this.sdksArchivedObs = this.api
            .getSdks({ status: 'archived', org: this.auth.activeStudio && this.auth.activeStudio.id })
            .pipe(
                takeUntil(this.ngUnsubscribe),
                map((r) => {
                    const res = {};

                    if (r && r.length > 0) {
                        r = this.filterSdks(r);
                        r.forEach((e) => {
                            if (!res[e.platform]) {
                                res[e.platform] = [];
                            }

                            res[e.platform].push(e);
                        });

                        for (let i in res) {
                            res[i] = res[i].sort((a, b) => {
                                return this.comparableVersionNumber(a.version) < this.comparableVersionNumber(b.version)
                                    ? -1
                                    : 1;
                            });
                        }
                    }

                    return res;
                }),
            );

        this.api
            .getSdkEngines()
            .pipe(
                takeUntil(this.ngUnsubscribe),
                map((r) => {
                    const res = {};
                    r.forEach((e) => (res[e.id] = e));

                    return res;
                }),
            )
            .subscribe((r) => {
                this.engines = r;
            });

        this.api
            .getSdkEngineVersions()
            .pipe(
                takeUntil(this.ngUnsubscribe),
                map((r) => {
                    const res = {};
                    if (r && r.length > 0) {
                        r.forEach((e) => (res[e.id] = e));
                    }

                    return res;
                }),
            )
            .subscribe((r) => {
                this.engineVersions = r;
            });

        this.api
            .getSdkVersions()
            .pipe(
                takeUntil(this.ngUnsubscribe),
                map((r) => {
                    const res = {};
                    if (r && r.length > 0) {
                        r.forEach((e) => (res[e.id] = e));
                    }

                    return res;
                }),
            )
            .subscribe((r) => {
                this.sdkVersions = r;
            });

        this.api
            .getSdkPlatforms()
            .pipe(
                takeUntil(this.ngUnsubscribe),
                map((r) => {
                    const res = {};
                    r.forEach((e) => (res[e.id] = e));

                    r.sort((a, b) => {
                        return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
                    });

                    this.platformsArr = r;

                    return res;
                }),
            )
            .subscribe((r) => {
                this.platforms = r;
            });

        this.api
            .getSdkPlatformVersions()
            .pipe(
                takeUntil(this.ngUnsubscribe),
                map((r) => {
                    const res = {};
                    r.forEach((e) => (res[e.id] = e));
                    this.platformVersionsArr = r;

                    return res;
                }),
            )
            .subscribe((r) => {
                this.platformVersions = r;
            });

        this.api
            .getSdkArchitectures()
            .pipe(
                takeUntil(this.ngUnsubscribe),
                map((r) => {
                    const res = {};
                    r.forEach((e) => (res[e.id] = e));

                    return res;
                }),
            )
            .subscribe((r) => {
                this.architectures = r;
            });
    }

    ngAfterViewInit() {
        if (this.link) {
            return;
        }

        forkJoin(this.sdksLiveObs, this.sdksArchivedObs).subscribe((r) => {
            this.sdksLive = r[0];
            this.sdksArchived = r[1];

            if (this.auth.deepSdkLink) {
                let found = false;
                for (let key in this.sdksLive) {
                    this.sdksLive[key].forEach((x) => {
                        if (x.directDownloadId == this.auth.deepSdkLink) {
                            found = true;
                            this.setActiveSdk(x, true);
                            this.http
                                .get<APIResponse<string>>(
                                    `api/v1/sdks/${x.id}/download?org=${this.auth.activeStudio.id}`,
                                )
                                .subscribe(
                                    (res) => (window.location.href = res.data),
                                    (err) => console.log(err),
                                );
                        }
                    });
                }
                for (let key in this.sdksArchived) {
                    this.sdksArchived[key].forEach((x) => {
                        if (x.directDownloadId == this.auth.deepSdkLink) {
                            found = true;
                            this.setActiveSdk(x, true);
                        }
                    });
                }

                this.auth.deepSdkLink = '';
                if (!found) {
                    this.snackBar.open('Unable to find SDK.', 'Dismiss', {
                        duration: 2000,
                        panelClass: 'bad-snack',
                    });
                }
            }
        });
    }

    get isAsc() {
        return this.sort.direction === -1;
    }

    get isDesc() {
        return this.sort.direction === 1;
    }

    onChangeSelectedPlatformId(platformId: number) {
        this.selectedPlatformId = platformId;
        this.setSort(this.sort.active, this.sort.direction);
    }

    filterSdks(sdks: Sdk[]): Sdk[] {
        return sdks.filter((sdk) => {
            const hasTargetOrgs = sdk.targetOrgs && sdk.targetOrgs.length > 0;

            const targetOrgsOk =
                !hasTargetOrgs ||
                (this.auth.activeStudio &&
                    this.auth.activeStudio.id &&
                    sdk.targetOrgs.includes(this.auth.activeStudio.id));

            return targetOrgsOk;
        });
    }

    setActiveSdk(sdk: Sdk, directDownload: boolean) {
        this.activeSdk = sdk;
        this.sdkDetailsOpen = true;
        this.showDownloadText = directDownload;
    }

    closeBg(e: any) {
        if (e.target.classList.contains('modal-bg') || e.target.classList.contains('close')) {
            this.close();
            this.showDownloadText = false;
            this.auth.deepSdkLink = '';
        }
    }

    close() {
        this.sdkDetailsOpen = false;
    }

    platformVisibleToOrg(platform: SdkPlatform): boolean {
        const platformPermissions = platformPermissionsOnly(platform.permissions as Permission[]);
        if (this.auth.isSDKue4Org()) {
            return platformPermissions.length == 0 || platformPermissions.includes(Permission.SDKue4);
        } else if (this.auth.isSDKv4Org()) {
            return platformPermissions.length == 0 || platformPermissions.includes(Permission.SDKv4);
        } else {
            return platformPermissions.length == 0 || platformPermissions.includes(Permission.SDKv5);
        }
    }

    setSort(active: string, direction?: number) {
        if (active !== this.sort.active) {
            this.sort.direction = 1;
        }

        this.sort = {
            active,
            direction: direction || -this.sort.direction,
        };

        if (this.sdksLive && this.sdksLive[this.selectedPlatformId]) {
            this.sdksLive[this.selectedPlatformId] = this.sortSDKs(this.sdksLive[this.selectedPlatformId], active);
        }

        if (this.sdksArchived && this.sdksArchived[this.selectedPlatformId]) {
            this.sdksArchived[this.selectedPlatformId] = this.sortSDKs(
                this.sdksArchived[this.selectedPlatformId],
                active,
            );
        }
    }

    private sortSDKs(sdks: Sdk[], active: string) {
        return sdks.sort((a, b) => {
            switch (active) {
                case 'name':
                    return b[active].toLowerCase() < a[active].toLowerCase()
                        ? -this.sort.direction
                        : this.sort.direction;
                case 'version':
                    const va = this.sdkVersions[a.sdkVersion];
                    const vb = this.sdkVersions[b.sdkVersion];

                    let result = 0;
                    if (va && vb) {
                        result = this.comparableVersionNumber(va.name) < this.comparableVersionNumber(vb.name) ? -1 : 1;
                    } else if (va) {
                        result = 1;
                    } else if (vb) {
                        result = -1;
                    } else {
                        result = 0;
                    }

                    return result * -this.sort.direction;
                case 'releaseDate':
                    const aDate = +new Date(a[active]);
                    const bDate = +new Date(b[active]);
                    return aDate === bDate ? 0 : bDate < aDate ? -this.sort.direction : this.sort.direction;
                case 'engine':
                    return this.engines[b[active]].name < this.engines[a[active]].name
                        ? -this.sort.direction
                        : this.sort.direction;
                default:
                    return b[active] < a[active] ? -this.sort.direction : this.sort.direction;
            }
        });
    }

    comparableVersionNumber(name: string) {
        if (name) {
            const pieces = name.split('.');
            return pieces
                .map((x) => {
                    if (isNaN(x as any)) {
                        return sprintf('%7v', x);
                    } else {
                        return (+x + 1000000).toString();
                    }
                })
                .join('.');
        }
        return name;
    }

    copyDownloadLink(toCopy: string) {
        toCopy = `${this.document.location.origin}/downloads/link/${toCopy}`;
        addToClipboard(toCopy);
        this.snackBar.open(this.translate.instant('user.sdks.linkCopied'), null, { duration: 2000 });
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
        this.showDownloadText = false;
    }
}
