import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'shared-file-drop',
    templateUrl: './file-drop.component.html',
    styleUrls: ['./file-drop.component.scss'],
})
export class FileDropComponent implements OnInit {
    @Input() types: any = [];
    @Input() isImage = false;
    @Input() imageHeight: number = null;
    @Input() imageWidth: number = null;
    @Input() placeholder: any = null;
    @Input() name: string;

    @Output() file: EventEmitter<File> = new EventEmitter();

    fileData: any;
    isInvalid: boolean = null;
    accepts: any = [];

    lengthError = false;
    typeError = false;

    thumbnailHeight = 96;
    thumbnailWidth = 96;

    acceptTypes: any = {
        jpg: 'image/jpeg',
        jpeg: 'image/jpeg',
        png: 'image/png',
        csv: 'text/csv',
        xls: 'application/vnd.ms-excel',
        xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        zip: 'application/zip,application/x-zip,application/x-zip-compressed,application/octet-stream',
        tar: 'application/x-tar',
        'tar.gz': 'application/x-gzip',
        tgz: 'application/x-gzip',
        exe: 'application/octet-stream',
    };

    constructor(private ele: ElementRef) {}

    ngOnInit() {
        let ele = this.ele.nativeElement;
        let fileInput = ele.querySelector('input[type=file]');

        this.types.forEach(type => {
            if (this.acceptTypes[type]) {
                this.accepts.push(this.acceptTypes[type]);
            }
        });

        fileInput.addEventListener('change', e => {
            this.lengthError = false;
            this.typeError = false;
            this.isInvalid = null;
            this.fileData = false;

            let target: any = e.target || e.srcElement;

            if (!target.files[0] || target.files.length != 1) {
                this.isInvalid = true;
                this.lengthError = true;
                return;
            }

            let file = target.files[0];
            let ext = file.name
                .split('.')
                .pop()
                .toLowerCase();

            if (this.types.indexOf(ext) == -1) {
                this.isInvalid = true;
                this.typeError = true;
                return;
            }

            this.setFile(file);
        });

        ele.addEventListener(
            'dragenter',
            e => {
                this.dragEnterLeave(e);
            },
            false,
        );

        ele.addEventListener(
            'dragleave',
            e => {
                this.dragEnterLeave(e);
            },
            false,
        );

        ele.addEventListener(
            'dragover',
            e => {
                this.dragOver(e);
            },
            false,
        );

        ele.addEventListener(
            'drop',
            e => {
                this.drop(e);
            },
            false,
        );

        if (this.imageHeight && this.imageWidth) {
            this.thumbnailHeight = 96;
            this.thumbnailWidth = 96 * (this.imageWidth / this.imageHeight);
        }
    }

    swapFile() {
        let ele = this.ele.nativeElement;
        let fileInput = ele.querySelector('input[type=file]');

        fileInput.click();
    }

    dragEnterLeave(e: any) {
        e.stopPropagation();
        e.preventDefault();

        this.isInvalid = null;
    }

    dragOver(e: any) {
        e.stopPropagation();
        e.preventDefault();

        let dataTransfer = e.dataTransfer;
        let lengthCheck = dataTransfer.items.length == 1;
        let typeCheck = false;
        // let extCheck = false;

        if (lengthCheck) {
            typeCheck = dataTransfer.types.indexOf('Files') !== -1;
            // extCheck = this.isValidFile(dataTransfer.items[0]);
        }

        if (!lengthCheck) {
            this.lengthError = true;
            this.isInvalid = true;
        } else if (!typeCheck) {
            this.typeError = true;
            this.isInvalid = true;
        } else {
            this.isInvalid = null;
            this.typeError = false;
            this.lengthError = false;
        }
    }

    setFile(file: File) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            this.fileData = file;
            this.isInvalid = false;

            if (this.isImage) {
                this.fileData.base64 = reader.result;

                let image = new Image();
                image.src = reader.result as string;
                image.onload = () => {
                    this.fileData.width = image.width;
                    this.fileData.height = image.height;
                };
            }

            this.file.emit(file);
        };

        reader.onerror = () => {
            this.isInvalid = true;
            this.typeError = true;
        };
    }

    drop(e: any) {
        e.stopPropagation();
        e.preventDefault();

        this.fileData = false;

        if (this.isInvalid == false) {
            return false;
        }

        let file = e.dataTransfer.files[0];
        if (!file.name) {
            return false;
        }

        let ext = file.name
            .split('.')
            .pop()
            .toLowerCase();

        if (this.types.indexOf(ext) == -1) {
            this.isInvalid = true;
            this.typeError = true;
            return false;
        }

        this.setFile(file);
    }
}
