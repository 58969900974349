import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Permission } from '../core';

import { AuthService } from './auth.service';

@Directive({
    selector: '[vxIfAllowed]',
})
export class IfAllowedDirective implements OnInit, OnDestroy {
    private hasView = false;
    private permission: Permission;

    private ngUnsubscribe = new Subject<void>();

    @Input()
    set vxIfAllowed(permission: Permission) {
        this.permission = permission;
        this.update();
    }

    constructor(
        private auth: AuthService,
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
    ) {}

    ngOnInit() {
        this.auth.activeStudioChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => this.update());
        this.auth.userChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => this.update());
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    private update() {
        let condition = this.auth.activeStudio != null;

        if (condition) {
            condition =
                condition && ((this.permission as string) == 'general' || this.auth.hasPermission(this.permission));
        }

        if (condition && !this.hasView) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.hasView = true;
        } else if (!condition && this.hasView) {
            this.viewContainer.clear();
            this.hasView = false;
        }
    }
}
