import { DataSource } from '@angular/cdk/collections';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { Agreement } from '../../core/models';
import { APIResponse } from '../api';

@Injectable()
export class AgreementDataSource extends DataSource<Agreement> {
    dataChange$ = new BehaviorSubject<Agreement[]>([]);
    deleted = false;
    subTypes = ['FREE', 'ENTERPRISE', 'DEDICATED'];
    working = false;

    constructor(private http: HttpClient) {
        super();
    }

    update() {
        this.working = true;
        this.http.get<APIResponse<Agreement[]>>(`api/v1/agreements`).subscribe(
            res => {
                this.working = false;

                if (!res.data) {
                    this.dataChange$.next([]);
                    return;
                }

                const agreements = res.data.sort((a, b) => {
                    if (a.deleted == b.deleted) {
                        if (a.vivoxPermission == b.vivoxPermission) {
                            if (a.type == b.type) {
                                if (a.subType == b.subType) {
                                    if (a.effectiveDate && b.effectiveDate == null) {
                                        return 1;
                                    } else if (a.effectiveDate == null && b.effectiveDate) {
                                        return -1;
                                    } else {
                                        return a.effectiveDate > b.effectiveDate
                                            ? -1
                                            : a.effectiveDate < b.effectiveDate
                                            ? 1
                                            : 0;
                                    }
                                }

                                const aIndex = this.subTypes.findIndex(item => a.subType == item);
                                const bIndex = this.subTypes.findIndex(item => b.subType == item);
                                if (aIndex > bIndex) {
                                    return 1;
                                } else if (aIndex < bIndex) {
                                    return -1;
                                } else {
                                    return 0;
                                }
                            }
                            return a.type.localeCompare(b.type);
                        }
                        return a.vivoxPermission.localeCompare(b.vivoxPermission) * -1;
                    } else if (a.deleted && !b.deleted) {
                        return 1;
                    } else if (!a.deleted && b.deleted) {
                        return -1;
                    }
                });

                this.dataChange$.next(agreements);
            },
            () => {
                this.working = false;
            },
        );
    }

    connect(): Observable<Agreement[]> {
        return this.dataChange$;
    }

    disconnect() {}
}
