import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { VivoxPlatform } from 'app/core/vivox-platforms';
import * as auth0 from 'auth0-js';
import Cookies from 'js-cookie';
import { Observable, Subject, throwError } from 'rxjs';
import { of } from 'rxjs/observable/of';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { ApiResponse, Permission, permissions } from '../core';

import { ApiService } from './api.service';
import { App } from './app.model';
import { CookieService } from './cookie.service';
import { LogService } from './log.service';
import { Org, QuickOrg } from './org.model';
import { UpdateUserEmail, User, ZendeskSSO } from './user.model';

@Injectable()
export class AuthService {
    realm = 'Username-Password-Authentication';

    auth0 = new auth0.WebAuth({
        clientID: 'O3SgwmRoOS3AQAjy3pCX5sTZBDGBfF7g',
        domain: 'vivox.auth0.com',
        responseType: 'token id_token',
        audience: 'https://vivox.auth0.com/userinfo',
        redirectUri: environment.auth0callback,
    });
    inMaintenance = false;

    // vivox admin
    isVivox = false;

    permissions: { [orgId: number]: { [permission: string]: boolean } } = {};
    organizations: QuickOrg[] | null;
    activeStudioChange = new Subject<Org>();
    userChange = new Subject<User>();
    activeAppChange = new Subject<App>();
    working = false;

    loginRedirectURI = '';
    loginReturnPath = '';
    loginReturnURL = '';

    private _activeApp: App;
    private _activeStudio: Org;
    private _user: User;
    deepSdkLink: string;

    get activeApp(): App {
        return this._activeApp;
    }

    set activeApp(app: App) {
        this._activeApp = app;
        this.activeAppChange.next(this._activeApp);
    }

    get activeStudio(): Org {
        return this._activeStudio;
    }

    set activeStudio(org: Org) {
        this._activeStudio = org;
        this.activeStudioChange.next(this._activeStudio);
    }

    get user(): User {
        return this._user;
    }

    set user(user: User) {
        this._user = user;

        this.userChange.next(this._user);
    }

    public get orgAvailable() {
        return this.activeStudio || this.isVivox;
    }

    constructor(
        private api: ApiService,
        private router: Router,
        private http: HttpClient,
        private log: LogService,
        private cookieService: CookieService,
    ) {
        // not in CookieService because it is a strictly necessary cookie
        const userCookie = Cookies.get('user');

        if (userCookie) {
            this.log.info(this, 'User cookie exists');

            this.user = JSON.parse(userCookie);
            this.isVivox = this.user.isAdmin;

            this.redirectIfNecessary();
        }
    }

    isLoginRedirect() {
        return this.loginRedirectURI.match(/https?:\/\//);
    }

    redirectIfNecessary() {
        if (this.isLoginRedirect()) {
            this.redirectToURI();
            return true;
        } else if (this.isZendeskSSOReq()) {
            this.goToZendesk(this.loginReturnURL, false);
            return true;
        }
        this.loginRedirectURI = '';
        if (this.loginReturnPath) {
            this.router.navigate([this.loginReturnPath]);
            this.loginReturnPath = '';
            return true;
        }
        return false;
    }

    setFTUEShown(key: string): Observable<any> {
        return this.api.setFTUEShown(this.user, key).pipe(switchMap(() => this.refreshUser()));
    }

    hasPermission(permission: Permission, orgId: number = null, common = false): boolean {
        if (!orgId && this.activeStudio) {
            orgId = this.activeStudio.id;
        }

        if (!orgId) {
            return false;
        }

        if (this.isVivox && !common) {
            return true;
        }

        if (!permission) {
            return true;
        }

        if (permissions.indexOf(permission) < 0) {
            throw new Error(`permission ${permission} is not a valid permission`);
        }

        if (!this.isAuthenticated()) {
            return false;
        }

        if (!this.permissions[orgId]) {
            return false;
        }

        return !!this.permissions[orgId][permission];
    }

    hasPendingPermission(permission: Permission): boolean {
        if (!this.activeStudio || this.isVivox || !permission) {
            return false;
        }

        return this.activeStudio.pendingPermissions.indexOf(permission) >= 0;
    }

    isSDKue4Org(): boolean {
        return (
            this.activeStudio &&
            this.activeStudio.vivoxPlatform &&
            this.activeStudio.vivoxPlatform == VivoxPlatform.SDKue4
        );
    }

    isSDKv4Org(): boolean {
        return (
            this.activeStudio &&
            this.activeStudio.vivoxPlatform &&
            this.activeStudio.vivoxPlatform == VivoxPlatform.SDKv4
        );
    }

    isSDKv5Org(): boolean {
        return !this.isSDKue4Org() && !this.isSDKv4Org();
    }

    approvedOrgAvailable(): boolean {
        return this.activeStudio && this.activeStudio.status == 'approved';
    }

    sdkFromOrg(): string {
        if (this.isSDKue4Org()) {
            return VivoxPlatform.SDKue4;
        } else if (this.isSDKv4Org) {
            return VivoxPlatform.SDKv4;
        }
        return VivoxPlatform.SDKv5;
    }

    sdkFromUser(): string {
        let result = VivoxPlatform.SDKv5;
        if (this.isUe4User()) {
            result = VivoxPlatform.SDKue4;
        } else if (this.isV4User()) {
            result = VivoxPlatform.SDKv4;
        }
        return result;
    }

    isUe4User(): boolean {
        return (
            this.user && this.isAuthenticated() && this.user.registrationType && this.user.registrationType === 'ue4'
        );
    }

    isV4User(): boolean {
        return this.user && this.isAuthenticated() && this.user.registrationType && this.user.registrationType === 'v4';
    }

    isV5User(): boolean {
        return this.user && this.isAuthenticated() && !this.isUe4User() && !this.isV4User();
    }

    isZendeskSSOReq(): boolean {
        return this.loginRedirectURI === 'zendesk_sso';
    }

    acceptInviteIfNecessary(email: string) {
        if (localStorage.getItem('invite_token')) {
            return this.api.acceptInvite(localStorage.getItem('invite_token'), email).pipe(
                tap(() => {
                    localStorage.removeItem('invite_token');
                    localStorage.removeItem('invite_email');
                }),
                catchError((err) => {
                    console.log('accept invite failed', err);
                    return of(true);
                }),
            );
        }
        return of(true);
    }

    login(email: string, password: string): Observable<any> {
        return Observable.create((observer) => {
            this.auth0.client.login(
                {
                    username: email,
                    password: password,
                    realm: this.realm,
                    scope: 'openid profile email update:users_app_metadata',
                    audience: 'https://vivox.auth0.com/userinfo',
                },
                (err: any, res: any) => {
                    if (err || !res || !res.accessToken || !res.idToken) {
                        observer.error(err);
                        return;
                    }
                    this.createSession(res);

                    observer.next();
                },
            );
        }).pipe(
            mergeMap(() => this.acceptInviteIfNecessary(email)),
            mergeMap(() => this.refreshUser()),
            mergeMap(() => this.refreshOrgs(true)),
            tap(() => {
                if (this.isLoginRedirect()) {
                    this.redirectToURI();
                    return false;
                } else if (this.isZendeskSSOReq()) {
                    this.goToZendesk(this.loginReturnURL, false);
                    return;
                }
                this.loginRedirectURI = '';

                if (this.loginReturnPath) {
                    this.router.navigate([this.loginReturnPath]);
                    this.loginReturnPath = '';
                    return false;
                }

                this.router.navigate(['/apps']);
            }),
        );
    }

    register(
        email: string,
        password: string,
        firstName: string,
        lastName: string,
        registerOrgName: string,
        registerOrgSize: string,
        token = '',
        registrationType = 'v5', // can also be 'v4' or 'ue4'
    ): Observable<User> {
        return Observable.create((observer) => {
            let params: any = {
                connection: this.realm,
                email: email,
                scope: 'openid profile email user_metadata app_metadata',
                audience: 'https://vivox.auth0.com/userinfo',
                password: password,
                user_metadata: {
                    first_name: firstName,
                    last_name: lastName,
                    org_name: registerOrgName,
                    org_size: registerOrgSize,
                    accepted_pp_at: new Date().toISOString(),
                    registrationType: registrationType,
                },
            };

            if (token) {
                params.user_metadata.invite_token = token;
            }

            this.auth0.signupAndAuthorize(params, (err: any, res: any) => {
                if (err || !res || !res.accessToken || !res.idToken) {
                    observer.error(err);
                    return;
                }

                this.createSession(res);

                observer.next();
            });
        }).pipe(
            mergeMap(() => this.acceptInviteIfNecessary(email)),
            mergeMap(() => this.refreshUser()),
            switchMap(() => {
                if (!this.user.emailVerified) {
                    return this.http
                        .get(`api/v1/user/send-verification-email`)
                        .pipe(mergeMap(() => this.router.navigate(['/'])));
                } else {
                    return this.router.navigate(['/apps']);
                }
            }),
        );
    }

    resetPassword(email: string): Observable<void> {
        return Observable.create((observer) => {
            this.auth0.changePassword(
                {
                    connection: this.realm,
                    email: email,
                },
                (err: any) => {
                    if (err) {
                        observer.error(err);
                        return;
                    }

                    observer.next();
                },
            );
        });
    }

    updateMaintenance(): Observable<void> {
        return new Observable((observer) => {
            this.api.getMaintenanceState().subscribe(
                (r) => {
                    this.inMaintenance = r.mode == 1;
                    observer.next();
                },
                () => {
                    observer.next();
                },
            );
        });
    }

    refreshOrgs(force = false): Observable<any> {
        force = force || this.activeStudio == undefined || !this.organizations || this.organizations.length == 0;

        if (!force) {
            return of(false);
        }

        this.working = true;
        let newActiveOrg = null;
        return <Observable<QuickOrg[]>>this.api.get('api/v1/organizations?deleted=0&data=quick').pipe(
            tap((orgList) => {
                // see if we're in vivox master org
                this.organizations = orgList ? orgList.filter((o) => o.id !== 1) : [];
                this.updatePermissions();
            }),
            mergeMap((orgList) => {
                let studioId = this.cookieService.activeStudio;
                if (!isNaN(Number(studioId))) {
                    // if user is admin and active_studio was found then make sure that
                    // the selected app was an approved app (may not be from testing)
                    const newActive = orgList ? orgList.find((x) => x.id == Number(studioId)) : undefined;
                    if (newActive !== undefined) {
                        return this.api.getOrg(newActive.id).pipe(
                            tap((fullOrg) => {
                                if (this.isVivox) {
                                    if (fullOrg.status == 'approved') {
                                        newActiveOrg = fullOrg;
                                    }
                                } else {
                                    // only set to active studio id if there are no non-approved orgs
                                    if (orgList.every((x) => x.status == 'approved')) {
                                        newActiveOrg = fullOrg;
                                    }
                                }
                            }),
                            catchError(() => of(true)), // stored studio id may not exist so eat error and carry on
                            map(() => orgList),
                        );
                    }
                }
                return of(orgList);
            }),
            mergeMap((orgList) => {
                if (newActiveOrg != null) {
                    // no need to set active_studio cookie here. We find this org by using it
                    return of(orgList);
                }
                let newActive: any;
                if (this.isVivox) {
                    // admin? Load the first approved org we can find if admin
                    if (newActive === undefined && orgList) {
                        newActive = orgList.find((x) => x.status == 'approved');
                    }
                }
                if (newActive === undefined && orgList) {
                    newActive = orgList.find((x) => x.status == 'created');
                }
                if (newActive === undefined && orgList) {
                    newActive = orgList.find((x) => x.status == 'pending');
                }
                if (newActive === undefined && orgList) {
                    newActive = orgList.find((x) => x.status == 'rejected');
                }
                if (newActive === undefined && orgList) {
                    newActive = orgList.find((x) => x.status == 'approved');
                }
                if (newActive !== undefined) {
                    return this.api.getOrg(newActive.id).pipe(
                        tap((fullOrg) => {
                            newActiveOrg = fullOrg;
                        }),
                        map(() => orgList), // don't hide the error in this one if it fails. It shouldn't fail.
                    );
                }
                return of(orgList);
            }),
            mergeMap((orgList) => {
                if (newActiveOrg != null) {
                    return of(true);
                }
                const newActive = orgList && orgList.length > 0 ? orgList[0] : undefined;
                if (newActive !== undefined) {
                    return this.api.getOrg(newActive.id).pipe(
                        tap((fullOrg) => {
                            newActiveOrg = fullOrg;
                        }),
                        map(() => orgList), // don't hide the error in this one if it fails. It shouldn't fail.
                    );
                }
                return of(true);
            }),
            mergeMap(() => {
                if (newActiveOrg != null) {
                    return of(true);
                }

                // no orgs for this user
                const newOrg: Org = {
                    vivoxPlatform: this.sdkFromUser(),
                };
                return this.api.saveOrg(newOrg).pipe(
                    tap((o) => {
                        let permissions = [];
                        if (this.isUe4User()) {
                            permissions = [Permission.SDKue4];
                        } else if (this.isV4User()) {
                            permissions = [Permission.SDKv4];
                        } else {
                            permissions = [Permission.SDKv5];
                        }
                        newActiveOrg = o;
                        this.organizations = [
                            <QuickOrg>{
                                id: o.id,
                                name: o.name,
                                type: o.type,
                                status: o.status,
                                permissions: permissions,
                            },
                        ];
                    }),
                );
            }),
            tap(() => {
                this.activeStudio = newActiveOrg;
                this.cookieService.activeStudio = newActiveOrg.id.toString();
                this.working = false;
            }),
            catchError((err) => {
                this.working = false;
                console.log('Update Orgs error:', err);
                return throwError(err);
            }),
        );
    }

    updatePermissions() {
        const addOrgPermissions = (org: QuickOrg) => {
            this.permissions[org.id] = {};
            if (org.vivoxPlatform) {
                this.permissions[org.id][org.vivoxPlatform] = true;
            }
            if (org.platformPermissions) {
                for (const perm of org.platformPermissions) {
                    this.permissions[org.id][perm] = true;
                }
            }
        };

        if (!this.user || !this.organizations) {
            this.log.info(this, 'User has no permissions/orgs');
            return;
        }

        this.log.info(this, 'Received user permissions');

        this.permissions = {};

        for (const org of this.organizations) {
            addOrgPermissions(org);

            if (org.type === 'vivox') {
                continue;
            }
        }
    }

    refreshUser(): Observable<User> {
        return this.http.get<ApiResponse<User>>(`api/v1/user`).pipe(
            tap((r) => {
                this.user = r.data;
                // not in CookieService because it is a strictly necessary cookie
                Cookies.set('user', JSON.stringify(r.data), { sameSite: 'strict' });
                this.isVivox = this.user.isAdmin;
            }),
            map((r) => {
                return r.data;
            }),
        );
    }

    updateUser(user: User): Observable<User> {
        return this.api.updateUser(user).pipe(mergeMap(() => this.refreshUser()));
    }

    changePassword(): Observable<any> {
        return new Observable((obs) => {
            this.auth0.changePassword(
                {
                    connection: this.realm,
                    email: this.user.email,
                },
                (err, resp) => {
                    if (err) {
                        obs.error(err);
                    } else {
                        obs.next(resp);
                    }
                },
            );
        });
    }

    changeEmail(model: UpdateUserEmail) {
        return this.api.changeUserEmail(model).pipe(
            mergeMap(() => this.refreshUser()),
            mergeMap(() => this.http.get<ApiResponse<boolean>>(`api/v1/user/send-verification-email`)),
        );
    }

    // updateNickname(nickname: string): void {
    //     this.user.userNickname = nickname;
    //     Cookies.set('user', JSON.stringify(this.user));
    // }

    updatePicture(picture: string): void {
        this.user.picture = picture;
        // not in CookieService because it is a strictly necessary cookie
        Cookies.set('user', JSON.stringify(this.user));
    }

    logout(whereAreWe: string, defaultNav = true): void {
        this._user = null;
        this._activeStudio = null;
        this.isVivox = false;

        console.log(whereAreWe);

        // not in CookieService because they are strictly necessary cookie
        Cookies.remove('access_token', { sameSite: 'strict' });
        Cookies.remove('id_token', { sameSite: 'strict' });
        Cookies.remove('user', { sameSite: 'strict' });
        Cookies.remove('expires_at', { sameSite: 'strict' });

        if (defaultNav) {
            this.router.navigate(['/']);
        }
    }

    isAuthenticated(): boolean {
        const expiresAt = Cookies.get('expires_at');
        const idToken = Cookies.get('id_token');

        if (!expiresAt || !idToken) {
            return false;
        }

        if (new Date().getTime() < +expiresAt) {
            return true;
        }

        this.logout('isAuthenticated');
        return false;
    }

    isVerified(): boolean {
        if (!this.isAuthenticated() || !this.user) {
            return false;
        }

        return this.user.emailVerified;
    }

    goToZendesk(zendeskURL: string, newWindow: boolean): void {
        this.http.post<ApiResponse<ZendeskSSO>>(`api/v1/zendesk/sso`, { return_to: zendeskURL }).subscribe(
            (r) => {
                if (newWindow) {
                    this.log.info(this, `Opening new window to Zendesk`);
                    window.open(r.data.redirect, '_blank');
                } else {
                    this.log.info(this, `Redirecting page to Zendesk`);
                    window.location.href = r.data.redirect;
                }
            },
            (err) => {
                this.log.error(this, `Error generating Zendesk SSO`, err);
            },
        );
    }

    private redirectToURI() {
        const decodedURI = decodeURIComponent(this.loginRedirectURI);
        if (decodedURI.indexOf('docs.vivox.com/') > 0) {
            this.http
                .get<void>(`api/v1/documentation-signed-permission?page=${decodedURI}`, { withCredentials: true })
                .subscribe(
                    () => {
                        this.log.info(this, `Redirecting to docs ${decodedURI}`);
                        window.location.href = decodedURI;
                    },
                    (err) => {
                        console.log(err);
                        return throwError(err);
                    },
                );
        } else {
            this.log.info(this, `Redirecting to ${decodedURI}`);
            window.location.href = decodedURI;
        }
    }

    private createSession(response: any) {
        // TODO - Auth0 has a reminder that a system using auth0 not rely on a fixed size for the Access Token.
        // The change to their systems becomes permanent on April 12, 2022.
        // Cookies are size limited to 4K so these might need to be converted to other storage if access tokens
        // grow over 4k
        Cookies.set('access_token', response.accessToken, { sameSite: 'strict' });
        Cookies.set('id_token', response.idToken, { sameSite: 'strict' });
        // response.expiresIn
        this.updateExpires();
    }

    updateExpires(value?: number) {
        Cookies.set('expires_at', ((value || environment.auth.expiresIn) * 1000 + new Date().getTime()).toString(), {
            sameSite: 'strict',
        });
    }

    seenFTUE(name: string): boolean {
        if (this.user && this.user.ftues) {
            return this.user.ftues.findIndex((x) => x == name) >= 0;
        }
        return false;
    }
}
