import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/shared';

@Component({
    selector: 'enterprise',
    templateUrl: './enterprise.component.html',
    styleUrls: ['./enterprise.component.scss'],
})
export class EnterpriseComponent {
    constructor(public auth: AuthService, private router: Router) {
        this.auth.refreshOrgs().subscribe(() => {
            if (
                !this.auth.activeStudio ||
                this.auth.activeStudio.nonStandardMSA ||
                this.auth.activeStudio.dedicatedServer ||
                this.auth.activeStudio.enterpriseSupport
            ) {
                this.router.navigate([`/`]);
            }
        });
    }
}
