import { Overlay } from '@angular/cdk/overlay';
import { Component, Input, OnChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { GetVerifiedDialogComponent } from '../get-verified-dialog/get-verified-dialog.component';
import { ConditionalAccessOption } from '../models';
import { Permission } from '../permissions';

@Component({
    selector: 'core-get-verified-blurb',
    templateUrl: './get-verified-blurb.component.html',
    styleUrls: ['./get-verified-blurb.component.scss'],
})
export class GetVerifiedBlurbComponent implements OnChanges {
    @Input() missingPermission: string;
    @Input() platform: string;
    @Input() pending: boolean;
    working: boolean;
    public ConditionAccessOption = ConditionalAccessOption;

    constructor(private dialog: MatDialog, private overlay: Overlay) {}

    ngOnChanges() {
        if (this.missingPermission == Permission.PlatformXboxOne) {
            this.platform = 'Xbox One';
        } else if (this.missingPermission == Permission.PlatformXboxSXS) {
            this.platform = 'Xbox Series XS';
        } else if (this.missingPermission == Permission.PlatformPS4) {
            this.platform = 'PlayStation 4';
        } else if (this.missingPermission == Permission.PlatformPS5) {
            this.platform = 'PlayStation 5';
        } else if (this.missingPermission == Permission.PlatformNintendo) {
            this.platform = 'Nintendo';
        } else if (this.missingPermission == Permission.PlatformWebkit) {
            this.platform = 'Webkit';
        } else if (this.missingPermission == Permission.PlatformStadia) {
            this.platform = 'Stadia';
        }
    }

    getVerified() {
        this.working = true;
        const dialogRef = this.dialog.open(GetVerifiedDialogComponent, {
            data: {
                missingPermission: this.missingPermission,
                platform: this.platform,
                showForm: true,
            },
            scrollStrategy: this.overlay.scrollStrategies.noop(),
        });

        dialogRef.afterClosed().subscribe(() => (this.working = false));
    }
}
