import { HttpClient } from '@angular/common/http';
import { Directive, ElementRef, HostListener, Input, OnChanges, Renderer2 } from '@angular/core';

import { APIResponse } from '../api';
import { AuthService } from '../auth.service';

@Directive({
    selector: '[sdkDownload]',
})
export class SdkDownloadDirective implements OnChanges {
    @Input() sdkDownload: number;

    constructor(
        private el: ElementRef,
        private http: HttpClient,
        private renderer: Renderer2,
        private auth: AuthService,
    ) {}

    @HostListener('click')
    onclick() {
        if (this.auth.activeStudio) {
            this.http
                .get<APIResponse<string>>(`api/v1/sdks/${this.sdkDownload}/download?org=${this.auth.activeStudio.id}`)
                .subscribe(
                    res => (window.location.href = res.data),
                    err => console.log(err),
                );
        } else {
            this.http.get<APIResponse<string>>(`api/v1/sdks/${this.sdkDownload}/download`).subscribe(
                res => (window.location.href = res.data),
                err => console.log(err),
            );
        }
    }

    ngOnChanges() {
        if (!this.sdkDownload) {
            return;
        }

        this.renderer.removeAttribute(this.el.nativeElement, 'target');
    }
}
