import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent {
    @Input() page = 1;
    @Input() working = false;
    @Input() pages = 1;
    @Input() isLastPage: boolean;

    maxPage = 1000;

    @Output() pagePrev = new EventEmitter<void>();
    @Output() pageFirst = new EventEmitter<void>();
    @Output() pageNext = new EventEmitter<void>();

    filterPagePrev() {
        this.pagePrev.emit();
    }

    filterPageFirst() {
        this.pageFirst.emit();
    }

    filterPageNext() {
        this.pageNext.emit();
    }
}
