import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from 'app/shared';
import * as moment from 'moment';

import { FTUEComponent } from '../ftue.component';

const DEFAULT_BILLING_ADDRESS = 'See Sales Contact for billing information';

@Component({
    selector: 'app-core-ftue-co-brand',
    templateUrl: './co-brand.component.html',
    styleUrls: ['../ftue.scss'],
})
export class FTUECoBrandComponent extends FTUEComponent implements OnInit {
    @ViewChild('form') form: NgForm;
    isActive = false;
    _optOut = false;
    _cobrandScreenshot;
    showBillingAddress: boolean;
    billingAddress: string;
    billingAddressExist = false;
    pcu = 0;
    launchDate: any;
    currentStep = 1;
    steps = 1;
    stepsArr = [];

    callbackParams: any = {};

    get optOut() {
        return this._optOut;
    }

    set optOut(value) {
        this._optOut = value;
        this.updateSteps();
    }

    get cobrandScreenshot() {
        return this._cobrandScreenshot;
    }

    set cobrandScreenshot(value) {
        this._cobrandScreenshot = value;
        this.updateSteps();
    }

    constructor(private auth: AuthService) {
        super();
    }

    ngOnInit() {
        this.showBillingAddress = !this.auth.activeStudio.nonStandardMSA;
        this.billingAddress = this.showBillingAddress ? this.auth.activeStudio.billingAddress : DEFAULT_BILLING_ADDRESS;
        this.billingAddressExist = !!this.auth.activeStudio.billingAddress;

        if (this.auth.activeApp && this.auth.activeApp.pcu) {
            this.pcu = this.auth.activeApp.pcu;
        } else {
            this.pcu = 0;
        }
        if (this.auth.activeApp && this.auth.activeApp.launchDate) {
            const temp = moment.utc(this.auth.activeApp.launchDate);
            if (temp.isBefore('2010-01-01')) {
                this.launchDate = '';
            } else {
                this.launchDate = temp;
            }
        }
    }

    register() {
        this.callbackParams.cobrandScreenshot = this.cobrandScreenshot;
        this.callbackParams.optOut = this.optOut;
        this.callbackParams.billingAddress = this.billingAddress;
        this.callbackParams.pcu = this.pcu;
        if (this.launchDate) {
            const mom = moment.utc(this.launchDate).set('hour', 0).set('minute', 0).set('second', 0);
            const formatted = mom.toISOString();
            this.callbackParams.launchDate = formatted;
        }
        this.hide();
    }

    updateSteps() {
        this.steps = this.showBillingAddress ? 4 : 3;
        this.stepsArr = new Array(this.steps);
    }

    dateFilter(date) {
        return moment(date, 'MM-DD-YYYY', true).isValid();
    }

    verifyLaunchDate() {
        if (this.launchDate) {
            return moment(moment(this.launchDate), 'MM-DD-YYYY', true).isValid();
        }
        return false;
    }

    billingAddressEmpty() {
        return this.billingAddress == undefined || this.billingAddress.trim() == '';
    }

    tryPageChange(newStep: number) {
        if (newStep !== 4 || !this.billingAddressEmpty()) {
            this.page(newStep);
        }
    }
}
