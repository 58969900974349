import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { USPSAddress } from 'app/core/models';
import { ApiService } from 'app/shared';

import { FTUEComponent } from '../ftue.component';

@Component({
    selector: 'app-core-ftue-ccpa-mail',
    templateUrl: './ccpa-mail.component.html',
    styleUrls: ['../ftue.scss', './ccpa-mail.component.scss'],
})
export class FTUECCPAMailComponent extends FTUEComponent implements OnInit {
    @ViewChild('form') form: NgForm;
    isActive = false;
    addressLine1: string;
    addressLine2: string;
    addressCity: string;
    addressState = 'CA';
    addressZip: string;
    _californiaRes: boolean;
    currentStep = 1;
    steps = 1;
    stepsArr = [];
    expanded = false;

    callbackParams: any = {};
    hasError: boolean;
    uspsResult: USPSAddress;
    working: boolean;

    get californiaRes() {
        return this._californiaRes;
    }

    set californiaRes(value) {
        this._californiaRes = value;
        this.updateSteps();
    }

    constructor(private api: ApiService) {
        super();
        this.updateSteps();
    }

    expand() {
        this.expanded = true;
    }

    contract() {
        this.expanded = false;
    }

    submitUSPSAddress() {
        this.callbackParams.mailToCaliResident = true;
        this.callbackParams.addressLine1 = this.uspsResult.address1;
        this.callbackParams.addressLine2 = this.uspsResult.address2;
        this.callbackParams.addressCity = this.uspsResult.city;
        this.callbackParams.addressState = this.uspsResult.state;
        this.callbackParams.addressZip = this.uspsResult.zip;
        this.hide();
    }

    submitUserAddress() {
        this.callbackParams.mailToCaliResident = true;
        this.callbackParams.addressLine1 = this.addressLine2; // USPS expects these in the opposite order
        this.callbackParams.addressLine2 = this.addressLine1; // USPS expects these in the opposite order
        this.callbackParams.addressCity = this.addressCity;
        this.callbackParams.addressState = this.addressState;
        this.callbackParams.addressZip = this.addressZip;
        this.hide();
    }

    submit() {
        this.hasError = false;
        if (this.californiaRes && !this.form.valid) {
            return;
        }

        if (this.californiaRes) {
            this.callbackParams.addressLine1 = this.addressLine2; // USPS expects these in the opposite order
            this.callbackParams.addressLine2 = this.addressLine1; // USPS expects these in the opposite order
            this.callbackParams.addressCity = this.addressCity;
            this.callbackParams.addressState = this.addressState;
            this.callbackParams.addressZip = this.addressZip;

            this.working = true;
            this.api.checkMailingAddress(this.callbackParams).subscribe(
                ret => {
                    this.working = false;
                    if (!ret || !ret.userAddress || ret.error) {
                        this.hasError = true;
                    } else {
                        if (ret.different) {
                            this.uspsResult = ret.uspsSuggestion;
                        } else {
                            this.callbackParams.mailToCaliResident = true;
                            this.hide();
                        }
                    }
                },
                err => {
                    this.working = false;
                    this.hasError = true;
                    console.log(err);
                },
            );
        } else {
            this.callbackParams.mailToCaliResident = false;
            this.hide();
        }
    }

    ngOnInit() {}

    updateSteps() {
        this.steps = this._californiaRes ? 2 : 1;
        this.stepsArr = new Array(this.steps);
    }
}
