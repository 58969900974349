import { HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SharedErrorDuplicateOrgName, SharedErrorDuplicateSlug } from 'app/core/shared-errors';
import { filter, mergeMap } from 'rxjs/operators';
import { sprintf } from 'sprintf-js';

import { ApiService, AuthService, LangService, LocalCountryName, Org, SeoService, User } from '../../../shared';

@Component({
    selector: 'core-settings-org',
    templateUrl: 'settings-org.component.html',
    styleUrls: ['settings-org.component.scss'],
})
export class SettingsOrgComponent implements OnInit, AfterViewInit {
    orgLogo: File;
    orgUpdated = false;
    working = false;
    fragment: string;
    countries: LocalCountryName[];
    members: User[];
    memberMap: { [key: string]: User } = {};
    businessContactEmail: string;
    techSupportContactEmail: string;
    orgCopy: Org;

    bannedCountry = false;
    bannedCountryMap = new Map([
        ['KP', 'North Korea'],
        ['CU', 'Cuba'],
        ['SY', 'Syria'],
        // Crimea doesn't actually exist as of now
        ['CM', 'Crimea'],
        ['SD', 'Sudan'],
        ['IR', 'Iran'],
    ]);

    constructor(
        private api: ApiService,
        public auth: AuthService,
        private http: HttpClient,
        private seo: SeoService,
        private translate: TranslateService,
        private sb: MatSnackBar,
        private route: ActivatedRoute,
        private langService: LangService,
        private router: Router,
    ) {
        this.seo.setTitle('Organization Settings');
        this.auth.refreshOrgs().subscribe(() => {
            this.orgCopy = JSON.parse(JSON.stringify(this.auth.activeStudio));
            this.api.getOrgMembers(this.orgCopy.id, false).subscribe((r) => {
                this.members = r;
                this.members.forEach((m) => {
                    this.memberMap[m.email] = m;
                });
                this.updateBizAndTechContact();
            });
        });
    }

    ngOnInit() {
        this.route.fragment.subscribe((fragment) => {
            this.fragment = fragment;
        });

        this.countries = this.langService.getCountries();
        this.langService.onLanguageChange.subscribe(() => {
            this.countries = this.langService.getCountries();
        });
    }

    ngAfterViewInit(): void {
        if (this.fragment) {
            document.querySelector('#' + this.fragment).scrollIntoView();
        }
    }

    sizeStateMatcher = {
        isErrorState: (control: FormControl, form: FormGroupDirective | NgForm): boolean => {
            // Error when invalid control is submitted
            const isSubmitted = form && form.submitted;
            const showErrors =
                (!this.sizeValid() || control.invalid) && (control.dirty || control.touched || isSubmitted);

            return showErrors;
        },
    };

    get remoteApiEnabled(): boolean {
        return this.orgCopy.remoteApiEnabled;
    }

    set remoteApiEnabled(value: boolean) {
        this.orgCopy.remoteApiEnabled = value;
        if (value && !this.orgCopy.remoteApiUsername) {
            this.api.activateRemoteApi(this.orgCopy.id).subscribe((r) => {
                this.orgCopy.remoteApiUsername = r.Username;
                this.orgCopy.remoteApiPassword = r.Secret;
            });
        }
    }

    get businessContactNameAndEmail() {
        let result = '';
        if (this.orgCopy.contactName) {
            result += this.orgCopy.contactName;
        }
        if (this.orgCopy.contactEmail) {
            if (result.length) {
                result += sprintf(' (%s)', this.orgCopy.contactEmail);
            } else {
                result += sprintf('(%s)', this.orgCopy.contactEmail);
            }
        }

        return result;
    }

    get techContactNameAndEmail() {
        let result = '';
        if (this.orgCopy.supportContactName) {
            result += this.orgCopy.supportContactName;
        }
        if (this.orgCopy.supportContactEmail) {
            if (result.length) {
                result += sprintf(' (%s)', this.orgCopy.supportContactEmail);
            } else {
                result += sprintf('(%s)', this.orgCopy.supportContactEmail);
            }
        }

        return result;
    }

    get originalOrgName() {
        return this.auth.activeStudio.name;
    }

    get newOrgName() {
        return this.orgCopy.name;
    }

    set newOrgName(value) {
        this.orgCopy.name = value;
    }

    get isApproved() {
        return this.orgCopy && this.orgCopy.status == 'approved';
    }

    memberFullName(u: User): string {
        let result = '';
        if (u.firstName) {
            result += u.firstName;
        }
        if (u.lastName) {
            if (result) {
                result += ' ';
            }
            result += u.lastName;
        }
        return result;
    }

    memberNameAndEmail(u: User): string {
        let result = this.memberFullName(u);

        if (u.email) {
            if (result) {
                result += sprintf(' (%s)', u.email);
            } else {
                result = sprintf('(%s)', u.email);
            }
        }
        return result;
    }

    updateBizAndTechContact() {
        let bizContactFound = false;
        let techContactFound = false;
        if (this.members && this.members.length) {
            this.members.forEach((m) => {
                if (this.orgCopy.contactEmail && m.email == this.orgCopy.contactEmail) {
                    this.orgCopy.contactName = this.memberFullName(m);
                    this.orgCopy.contactEmail = m.email;
                    this.businessContactEmail = this.orgCopy.contactEmail;
                    bizContactFound = true;
                }
                if (this.orgCopy.supportContactEmail && m.email == this.orgCopy.supportContactEmail) {
                    this.orgCopy.supportContactName = this.memberFullName(m);
                    this.orgCopy.supportContactEmail = m.email;
                    this.techSupportContactEmail = this.orgCopy.supportContactEmail;
                    techContactFound = true;
                }
            });
        }

        if (!this.auth.isVivox && (!bizContactFound || !techContactFound)) {
            const u = this.auth.user;

            if (!bizContactFound) {
                this.orgCopy.contactName = u.firstName + ' ' + u.lastName;
                this.orgCopy.contactEmail = u.email;
                this.businessContactEmail = this.orgCopy.contactEmail;
            }

            if (!techContactFound) {
                this.orgCopy.supportContactName = u.firstName + ' ' + u.lastName;
                this.orgCopy.supportContactEmail = u.email;
                this.techSupportContactEmail = this.orgCopy.supportContactEmail;
            }
        }
    }

    regenerateRemoteApiPassword() {
        let dialog: any = this.translate.instant('user.settings.org.regenerateRemoteApiPassword');
        let conf = window.confirm(dialog);

        if (conf) {
            this.api.regenerateRemoteApiPassword(this.orgCopy.id).subscribe((r) => {
                this.orgCopy.remoteApiPassword = r;
            });
        }
    }

    sizeValid() {
        const size = this.orgCopy.size ? this.orgCopy.size : '';

        return size != '' && parseInt(size) != 0;
    }

    isEmpty(str) {
        return !str || 0 === str.trim().length;
    }

    async uploadImage(file, prop: string) {
        if (!file) {
            return;
        }

        const url = await this.api
            .createOrgUpload(this.orgCopy, {
                id: this.orgCopy.id,
                contentType: file.type,
                fileName: file.name,
            })
            .toPromise();

        let headers = new HttpHeaders();
        headers = headers.set('x-amz-acl', 'public-read');

        const req = new HttpRequest('PUT', url, file, {
            reportProgress: true,
            headers: headers,
            responseType: 'text',
        });

        await this.http
            .request(req)
            .pipe(filter((e) => e instanceof HttpResponse))
            .toPromise();

        this.orgCopy[prop] = url.split('?')[0];
    }

    trimOrgField(field: string) {
        this.orgCopy[field] = this.orgCopy[field] ? this.orgCopy[field].trim() : '';
    }

    trimOrgFields() {
        this.orgCopy.name = this.orgCopy.name ? this.orgCopy.name.trim() : '';
        this.trimOrgField('description');
    }

    onBusinessContactChange(email: string) {
        this.orgCopy.contactName = this.memberFullName(this.memberMap[email]);
        this.orgCopy.contactEmail = email;
    }

    onTechSupportContactChange(email: string) {
        this.orgCopy.supportContactName = this.memberFullName(this.memberMap[email]);
        this.orgCopy.supportContactEmail = email;
    }

    remoteApiMouseDown(event) {
        console.log('nope');
        if (!this.orgCopy.remoteApiEnabled) {
            console.log('yep');
            event.preventDefault();
        }
    }
    async onSubmit() {
        this.trimOrgFields();
        this.bannedCountry = this.bannedCountryMap.has(this.orgCopy.country);
        if (this.bannedCountry) {
            return;
        }

        if (this.auth.isVivox && this.originalOrgName != this.newOrgName) {
            let dialog: any = this.translate.instant('user.settings.org.renameConfirm', {
                oldName: this.originalOrgName,
                newName: this.newOrgName,
            });
            let conf = window.prompt(dialog);
            if (conf != 'CONFIRM') {
                return;
            }
        }
        this.working = true;
        await this.uploadImage(this.orgLogo, 'logo');

        const wasRejected = this.orgCopy.status == 'rejected';
        this.api
            .saveOrg(this.orgCopy)
            .pipe(mergeMap(() => this.auth.refreshUser()))
            .pipe(mergeMap(() => this.auth.refreshOrgs(true)))
            .subscribe(
                () => {
                    this.working = false;
                    this.orgUpdated = true;

                    this.auth.activeStudio = JSON.parse(JSON.stringify(this.orgCopy));

                    if (wasRejected) {
                        setTimeout(() => {
                            this.orgUpdated = false;
                            this.router.navigate(['/']);
                        }, 1000);
                    } else {
                        setTimeout(() => {
                            this.orgUpdated = false;
                        }, 4000);
                    }
                },
                (err) => {
                    this.working = false;
                    let errorText = err.message;
                    if (err.error && err.error.data) {
                        if (typeof err.error.data == 'number') {
                            switch (err.error.data) {
                                case SharedErrorDuplicateOrgName:
                                    this.orgCopy.name = this.originalOrgName;
                                    errorText = this.translate.instant('user.settings.org.duplicateOrgName');
                                    break;
                                case SharedErrorDuplicateSlug:
                                    errorText = this.translate.instant('user.settings.org.duplicateSlugName');
                                    break;
                                default:
                                    errorText = this.translate.instant('user.settings.org.defaultSaveError');
                            }
                        }
                    }
                    console.log(err);
                    this.sb.open(`Error saving: ${errorText}`, null, {
                        duration: 5000,
                        panelClass: 'bad-snack',
                    });
                },
            );
    }
}
