import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

import { LangService } from './shared';
import { AuthService } from './shared/auth.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    constructor(lang: LangService, router: Router, auth: AuthService) {
        lang.load();
        router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe(() => {
            auth.updateMaintenance().subscribe(() => {
                if (!auth.inMaintenance && router.url === '/admin/login') {
                    router.navigate(['/']);
                }

                if (auth.isAuthenticated() && auth.inMaintenance) {
                    if (
                        !auth.user ||
                        !auth.user.email ||
                        !auth.isVivox ||
                        !(
                            auth.user.email.startsWith('dknel') ||
                            auth.user.email.startsWith('ddouglas') ||
                            auth.user.email.startsWith('eryan')
                        )
                    ) {
                        auth.logout('app.component.ts');
                    }
                }
            });
        });
    }
}
