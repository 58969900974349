import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { AuthService } from '../auth.service';
import { StudioDataSource } from '../datasource/studio.datasource';
import { Org } from '../org.model';

@Component({
    selector: 'app-shared-studio-picker',
    templateUrl: './studio-picker.component.html',
    styleUrls: ['./studio-picker.component.scss'],
})
export class StudioPickerComponent implements OnInit {
    @Input() organizations: Org[];
    @Input() set toggle(value: boolean) {
        this._toggle = value;
        if (value && this.data) {
            this.filterName = '';
            this.resetFilters();
        }
    }
    @Output() toggleStatus: EventEmitter<boolean> = new EventEmitter();
    @Output() filterChanged: EventEmitter<string> = new EventEmitter<string>();
    @Output() orgPicked: EventEmitter<number> = new EventEmitter<number>();

    get toggle() {
        return this._toggle;
    }

    _toggle = false;
    filterName = '';

    constructor(public data: StudioDataSource, public auth: AuthService) {}

    ngOnInit() {}

    closeBg(e: any) {
        if (e.target.classList.contains('modal-bg') || e.target.classList.contains('close')) {
            this.close();
        }
    }

    close() {
        this.toggle = false;
        this.toggleStatus.emit(false);
    }

    resetFilters() {
        this.filterName = '';
        this.data.resetFilter();
    }

    filterByName() {
        this.data.updateFilter(this.filterName);
    }

    setStudio(studio: Org) {
        if (this.auth.activeStudio.id != studio.id) {
            this.orgPicked.emit(studio.id);
        }
    }
}
