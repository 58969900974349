import { Component, OnInit } from '@angular/core';

import { AuthService } from '../../shared';

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
})
export class LogoutComponent implements OnInit {
    constructor(public auth: AuthService) {}

    ngOnInit() {
        this.auth.logout('logout.component');
    }
}
