import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { APIResponse, Faq, SeoService } from '../../../shared';

@Component({
    selector: 'app-faq-search',
    templateUrl: './faq-search.component.html',
    styleUrls: ['./faq-search.component.scss'],
})
export class FaqSearchComponent implements OnInit {
    faqs: Faq[];
    query = null;
    working = false;

    constructor(private http: HttpClient, private seo: SeoService, private route: ActivatedRoute) {}

    onSearch(query: string) {
        this.working = true;

        this.http.get<APIResponse<Faq[]>>(`api/v1/faqs`, { params: { search: query } }).subscribe(r => {
            this.faqs = r.data;
            this.working = false;
        });
    }

    ngOnInit() {
        this.seo.setTitle('FAQ Search Results');
        this.query = this.route.snapshot.queryParamMap.get('query');

        this.route.queryParams.subscribe(p => {
            this.query = p['query'];

            if (p['query'] && p['query'].length >= 3) {
                this.onSearch(p['query']);
            }
        });
    }
}
