import { HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material';
import { Subject } from 'rxjs';
import { filter, map, mergeMap, takeUntil } from 'rxjs/operators';

import { ApiService } from '../api.service';
import { App } from '../app.model';
import { AuthService } from '../auth.service';
import { SdkEngine, SdkPlatform, SdkPlatformVersion } from '../sdk.model';

@Component({
    selector: 'shared-app-form',
    templateUrl: './app-form.component.html',
    styleUrls: ['./app-form.component.scss'],
})
export class AppFormComponent implements OnInit, OnChanges, OnDestroy {
    @ViewChild('form')
    form: NgForm;

    @Output()
    saveEvent = new EventEmitter<App>();
    @Output()
    stepEvent = new EventEmitter<number>();

    @Input()
    app: App = {};

    nameStateMatcher = {
        isErrorState: (control: FormControl, form: FormGroupDirective | NgForm): boolean => {
            // Error when invalid control is dirty, touched, or submitted
            const isSubmitted = form && form.submitted;
            const showErrors =
                this.nameTaken || !!(control.invalid && (control.dirty || control.touched || isSubmitted));
            return showErrors;
        },
    };

    appChannels: number[][] = [];

    addingChannel = false;
    nameTaken = false;
    working = false;

    sdkEngines: SdkEngine[] = [];
    sdkPlatforms: SdkPlatform[] = [];
    sdkPlatformsById: { [key: number]: SdkPlatform } = {};
    sdkPlatformVersions: { [key: number]: SdkPlatformVersion[] } = {};

    appIcon: string;
    appHero: string;
    genre: any = {};
    genreModel: { [key: string]: boolean } = {};
    otherGenre: string;
    step = 1;

    hasErrors = false;
    error: any = {};

    genres = [
        'Action',
        'Adventure',
        'Arcade',
        'Fighter',
        'Horror',
        'MMO',
        'MOBA',
        'Party',
        'Platform',
        'Racing',
        'RPG',
        'Shooter',
        'Simulation',
        'Sports',
        'Strategy',
        'Other',
    ];

    private ngUnsubscribe = new Subject<void>();

    constructor(private api: ApiService, private auth: AuthService, private http: HttpClient) {}

    ngOnInit() {
        this.stepEvent.emit(this.step);

        this.app.genre.forEach((g, i) => {
            this.genreModel[g] = true;

            if (g.indexOf('Other:') > -1) {
                this.genreModel['Other'] = true;
                this.otherGenre = g.replace('Other:', '');
                this.app.genre[i] = 'Other';
            }
        });

        this.api
            .getSdkEngines()
            .pipe(
                mergeMap(engines => {
                    this.sdkEngines = engines;

                    if (this.app.engine == undefined && this.auth.isSDKv4Org()) {
                        // assign Vivox Core engine id to v4 Org's apps
                        this.sdkEngines.forEach(e => {
                            if (e.name && e.name.toLowerCase().includes('vivox core')) {
                                this.app.engine = e.id;
                            }
                        });
                    }

                    return this.api.getVivoxSdkPlatforms(this.auth.activeStudio.id);
                }),
                mergeMap(platforms => {
                    this.sdkPlatforms = platforms.sort((a, b) =>
                        a.name.toLocaleUpperCase().localeCompare(b.name.toLocaleUpperCase()),
                    );

                    platforms.forEach(p => (this.sdkPlatformsById[p.id] = p));
                    return this.api.getSdkPlatformVersions();
                }),
                map(versions => {
                    versions.forEach(v => {
                        if (!this.sdkPlatformVersions[v.platform]) {
                            this.sdkPlatformVersions[v.platform] = [];
                        }
                        this.sdkPlatformVersions[v.platform].push(v);
                    });
                }),
                takeUntil(this.ngUnsubscribe),
            )
            .subscribe();
    }

    ngOnChanges() {
        for (let key of ['genre', 'platform', 'platformVersion']) {
            if (!this.app[key]) {
                this.app[key] = [];
            }
        }
    }

    get isSdkV5Org(): boolean {
        return this.auth.isSDKv5Org();
    }

    canContinue(): boolean {
        if (this.step === 1 && (!this.app.name || this.app.genre.length == 0)) {
            return false;
        } else if (this.step === 1 && this.genreModel['Other'] && !this.otherGenre) {
            return false;
        } else if (
            this.step == 2 &&
            (!this.app.platform || this.app.platform.length == 0 || (this.auth.isSDKv5Org() && !this.app.engine))
        ) {
            return false;
        }

        return true;
    }

    prev() {
        this.step -= 1;
        if (this.step <= 0) {
            this.step = 1;
        }

        this.stepEvent.emit(this.step);
    }

    next() {
        if (!this.canContinue()) {
            return;
        }

        if (this.step == 2) {
            this.onSubmit();
            return;
        }

        this.step += 1;
        if (this.step > 2) {
            this.step = 2;
        }

        this.stepEvent.emit(this.step);
    }

    onCheckboxArrayChange(key: string, id: number, value: boolean) {
        if (!this.app[key]) {
            return;
        }

        if (!value) {
            this.app[key] = this.app[key].filter(v => v.toString() !== id.toString());
            return;
        }

        if (this.app[key].indexOf(id) > -1) {
            return;
        }

        this.app[key].push(id);
    }

    onGenreChange(id: any, value: MatCheckboxChange) {
        let idx = this.app.genre.indexOf(id);

        if (!value.checked && idx >= 0) {
            this.app.genre.splice(idx, 1);
            this.genreModel[id] = false;
        } else if (value.checked && idx == -1) {
            this.app.genre.push(id);
            this.genreModel[id] = true;
        }
    }

    onBlurName() {
        if (!this.app.name || this.app.name.length === 0) {
            return;
        }

        this.api.getAppName(this.app.name, this.auth.activeStudio.id).subscribe(res => {
            this.nameTaken = res > 0;
        });
    }

    async uploadImage(file, prop: string) {
        if (!file) {
            return;
        }

        const url = await this.api
            .createAppUpload(this.app, { id: this.app.id, contentType: file.type, fileName: file.name })
            .toPromise();

        let headers = new HttpHeaders();
        headers = headers.set('x-amz-acl', 'public-read');

        const req = new HttpRequest('PUT', url, file, {
            reportProgress: true,
            headers: headers,
            responseType: 'text',
        });

        await this.http
            .request(req)
            .pipe(filter(e => e instanceof HttpResponse))
            .toPromise();

        this.app[prop] = url.split('?')[0];
    }

    async onSubmit() {
        this.hasErrors = false;

        this.error.generic = false;
        this.error.channelLength = false;
        this.error.issuerUnique = false;
        this.error.nameUnique = false;

        let channels: number[][] = [];

        if (this.genreModel['Other'] && !this.otherGenre) {
            return;
        } else if (this.genreModel['Other'] && this.otherGenre) {
            let idx = this.app.genre.indexOf('Other');
            this.app.genre[idx] = 'Other:' + this.otherGenre;
        }

        this.app.applicationCrossplay = 1;
        channels.push(this.app.platform);

        if (channels.length == 0) {
            this.hasErrors = true;
            this.error.channelLength = true;
            return;
        }

        try {
            this.working = true;

            if (this.auth.activeStudio) {
                this.app.customerType = 'vivox';
                this.app.parentOrganization = this.auth.activeStudio.id;
            }

            let promises = [];

            if (!this.app.id) {
                this.app = await this.api.saveApp(this.app).toPromise();

                // add channels to new apps
                channels.forEach(platforms => {
                    promises.push(this.api.createAppEnvironment(this.app, 0, platforms).toPromise());
                });
            }

            promises.push(await this.uploadImage(this.appIcon, 'applicationIcon'));
            promises.push(await this.uploadImage(this.appHero, 'logo'));

            await Promise.all(promises);
            await this.api.saveApp(this.app).toPromise();

            this.saveEvent.emit(this.app);

            this.working = false;
        } catch (err) {
            this.working = false;
            this.hasErrors = true;
            if (err.error && err.error.data) {
                let body = err.error.data;

                if (body == 'The specified abbreviation is already in use') {
                    this.step = 1;
                    this.error.issuerUnique = true;
                } else if (body == 'An application with that name already exists') {
                    this.step = 1;
                    this.error.nameUnique = true;
                } else {
                    this.error.generic = true;
                }
            } else {
                this.error.generic = true;
            }
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
