export enum Permission {
    Blank = '',
    PlatformNintendo = 'platform.nintendo',
    PlatformPS4 = 'platform.ps4',
    PlatformPS5 = 'platform.ps5',
    PlatformWebkit = 'platform.webkit',
    PlatformXboxOne = 'platform.xbox-one',
    PlatformXboxSXS = 'platform.xbox-series-xs',
    PlatformStadia = 'platform.stadia',
    SDKue4 = 'sdk.ue4',
    SDKv4 = 'sdk.v4',
    SDKv5 = 'sdk.v5',
}

export const permissions = [
    Permission.PlatformNintendo,
    Permission.PlatformPS4,
    Permission.PlatformPS5,
    Permission.PlatformWebkit,
    Permission.PlatformXboxOne,
    Permission.PlatformXboxSXS,
    Permission.PlatformStadia,
    Permission.SDKue4,
    Permission.SDKv4,
    Permission.SDKv5,
];

export interface ISelectPermission {
    name: Permission;
    selected: boolean;
}

export function SdkPermissionsOnly(permissions: Permission[]): Permission[] {
    return permissions.filter((p) => p.startsWith('sdk.'));
}

export function platformPermissionsOnly(permissions: Permission[]): Permission[] {
    return permissions.filter((p) => p.startsWith('platform.'));
}

export function hasPlatformPermission(permissions: Permission[], permission: Permission): boolean {
    if (permissions) {
        return permissions.indexOf(permission) > -1;
    }

    return false;
}

export function updatePlatformPermissions(
    permissions: Permission[],
    permission: Permission,
    value: boolean,
): Permission[] {
    if (permissions) {
        const idx = permissions.indexOf(permission);
        if (value) {
            // doesn't exist and needs to be set
            if (idx < 0) {
                permissions.push(permission);
            }
        } else {
            if (idx > -1) {
                permissions.splice(idx, 1);
            }
        }
    }

    return permissions;
}
