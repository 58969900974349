import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { allSettled, formatMigrationUrl, Permission } from 'app/core';
import { CookieService } from 'app/shared/cookie.service';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { map, takeUntil, timeout } from 'rxjs/operators';

import {
    APIResponse,
    ApiService,
    App,
    AuthService,
    InviteComponent,
    LangService,
    Org,
    Sdk,
    SdkEngine,
    SdkPlatform,
    SdkPlatformVersion,
    SeoService,
} from '../../../shared';
import { AnnouncementService } from '../../announcement/announcement.service';
import { environment } from 'environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'core-apps-dashboard',
    templateUrl: 'apps-dashboard.component.html',
    styleUrls: ['apps-dashboard.component.scss'],
})
export class AppsDashboardComponent implements OnDestroy, OnInit {
    apps: App[];
    appsFiltered: App[] = [];
    filter: any = {
        status: 'all',
        sort: 'name',
    };
    chartApp: App;

    orgs: Org[];
    sdks: Sdk[];
    engines: { [key: number]: SdkEngine } = {};
    platforms: { [key: number]: SdkPlatform } = {};
    platformVersions: { [key: number]: SdkPlatformVersion } = {};
    lastUpdated: any;
    lastUpdatedStr: string;
    lastUpdatedTimer: any;
    working = false;
    lastActiveStudioID: number;
    showMigrationDeadlinePassedContent = false;
    loginMigrationUrl: string;
    signupMigrationUrl: string;
    migrationDeadlinePassedContent: string;

    private ngUnsubscribe = new Subject<void>();

    constructor(
        public announcements: AnnouncementService,
        private api: ApiService,
        public auth: AuthService,
        private seo: SeoService,
        private translate: TranslateService,
        public dialog: MatDialog,
        private http: HttpClient,
        private langService: LangService,
        private cookieService: CookieService,
        private router: Router,
    ) {
        this.seo.setTitle('Application Dashboard');
    }

    ngOnInit() {
        if (!this.auth.isVerified()) {
            this.router.navigate(['/guest']);
            return;
        }
        this.announcements.check();
        this.api
            .getSdkEngines()
            .pipe(
                takeUntil(this.ngUnsubscribe),
                map((r) => {
                    const res = {};
                    r.forEach((e) => (res[e.id] = e));

                    return res;
                }),
            )
            .subscribe((r) => (this.engines = r));

        this.api
            .getSdkPlatforms()
            .pipe(
                takeUntil(this.ngUnsubscribe),
                map((r) => {
                    const res = {};
                    r.forEach((e) => (res[e.id] = e));

                    return res;
                }),
            )
            .subscribe((r) => (this.platforms = r));

        this.api
            .getSdkPlatformVersions()
            .pipe(
                takeUntil(this.ngUnsubscribe),
                map((r) => {
                    const res = {};
                    r.forEach((e) => (res[e.id] = e));

                    return res;
                }),
            )
            .subscribe((r) => (this.platformVersions = r));

        this.load();
        this.auth.activeStudioChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
            if (this.lastActiveStudioID == undefined) {
                this.load();
            } else if (this.lastActiveStudioID != this.auth.activeStudio.id) {
                this.load();
            }
        });

        this.langService.onLanguageChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe((lang) => {
            this.setLastUpdatedStr({ inp: this.lastUpdated, lang });
        });
    }

    onInviteUser() {
        this.dialog.open(InviteComponent, {
            width: '600px',
        });
    }

    onRefresh() {
        this.load();
    }

    doFilter() {
        let apps = this.apps;
        apps.sort((a, b) => {
            if (this.filter.sort == 'name') {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            } else if (this.filter.sort == '-name') {
                if (a.name > b.name) {
                    return -1;
                }
                if (a.name < b.name) {
                    return 1;
                }
                return 0;
            } else if (this.filter.sort == 'updated') {
                return new Date(a.lastUpdated).getTime() - new Date(b.lastUpdated).getTime();
            } else if (this.filter.sort == '-updated') {
                return new Date(b.lastUpdated).getTime() - new Date(a.lastUpdated).getTime();
            }
        });

        if (this.filter.status != 'all') {
            apps = apps.filter((a) => a.status == this.filter.status);
        }

        this.appsFiltered = apps;
    }

    private async getMigrationUrl(orgId: number) {
        this.loginMigrationUrl = null;
        this.signupMigrationUrl = null;
        const url = await this.api.getOrgMigrationUrl(orgId).toPromise();
        [this.loginMigrationUrl, this.signupMigrationUrl] = formatMigrationUrl(url);
    }

    private setLastUpdatedStr(params: { inp?: moment.MomentInput; lang?: string }) {
        const { inp, lang } = params;
        this.lastUpdatedStr = (inp ? moment(inp) : moment())
            .locale(this.langService.getLangCode(lang || this.langService.currentLang))
            .fromNow();
    }

    private async load() {
        this.lastActiveStudioID = this.auth.activeStudio.id;
        this.working = true;
        if (this.lastUpdatedTimer) {
            clearInterval(this.lastUpdatedTimer);
        }

        try {
            const { data: apps } = await this.http
                .get<APIResponse<App[]>>(`api/v1/applications?parent=${this.auth.activeStudio.id}`)
                .toPromise();
            const finalApps = apps ? apps.slice() : [];
            const defaultAppId = this.cookieService.vivoxAppFilter ? Number(this.cookieService.vivoxAppFilter) : null;
            this.chartApp = null;

            // display apps as soon as they're loaded
            this.apps = finalApps;
            this.doFilter();

            const appStatPromises = [];

            // Hack: skipping Ubisoft in prod otherwise it crashes the app, if more are needed, start collecting them in a separate file
            if (this.auth.activeStudio.id !== 1963) {
                // then proceed to load statistics using regular promises to handle errors better
                for (let i = 0; i < this.apps.length; i++) {
                    const app = finalApps[i];
                    if (defaultAppId && app.id === defaultAppId) {
                        this.chartApp = app;
                    }

                    const sdk4Platform =
                        app.vivoxPlatform &&
                        (app.vivoxPlatform == Permission.SDKue4 || app.vivoxPlatform == Permission.SDKv4);

                    app.statsCount.liveChanUsers = null;
                    app.statsCount.liveConnected = null;
                    app.statsCount.testChanUsers = null;
                    app.statsCount.testConnected = null;

                    if (app.status == 'approved') {
                        app.liveChanUsersWorking = true;
                        appStatPromises.push(
                            this.api
                                .getAppStatisticsSpotValue(app, 'live', 'chanusers')
                                .pipe(timeout(1000))
                                .toPromise()
                                .then(
                                    (res) => {
                                        app.liveChanUsersWorking = false;
                                        app.statsCount.liveChanUsers = res;
                                    },
                                    () => (app.liveChanUsersWorking = false),
                                )
                                .catch(() =>
                                    console.log('getAppStatisticsSpotValue live channusers failed for app:', app.id),
                                ),
                        );
                        if (!sdk4Platform) {
                            app.liveConnectedWorking = true;
                            appStatPromises.push(
                                this.api
                                    .getAppStatisticsSpotValue(app, 'live', 'connected')
                                    .pipe(timeout(1000))
                                    .toPromise()
                                    .then(
                                        (res) => {
                                            app.liveConnectedWorking = false;
                                            app.statsCount.liveConnected = res;
                                        },
                                        () => (app.liveConnectedWorking = false),
                                    )
                                    .catch(() =>
                                        console.log('getAppStatisticsSpotValue live connected failed for app:', app.id),
                                    ),
                            );
                        }
                    }

                    app.testChanUsersWorking = true;
                    appStatPromises.push(
                        this.api
                            .getAppStatisticsSpotValue(app, 'test', 'chanusers')
                            .pipe(timeout(1000))
                            .toPromise()
                            .then(
                                (res) => {
                                    app.testChanUsersWorking = false;
                                    app.statsCount.testChanUsers = res;
                                },
                                () => (app.testChanUsersWorking = false),
                            )
                            .catch(() =>
                                console.log('getAppStatisticsSpotValue test channusers failed for app:', app.id),
                            ),
                    );

                    app.testConnectedWorking = true;
                    if (!sdk4Platform) {
                        appStatPromises.push(
                            this.api
                                .getAppStatisticsSpotValue(app, 'test', 'connected')
                                .pipe(timeout(1000))
                                .toPromise()
                                .then(
                                    (res) => {
                                        app.testConnectedWorking = false;
                                        app.statsCount.testConnected = res;
                                    },
                                    () => (app.testConnectedWorking = false),
                                )
                                .catch(() =>
                                    console.log('getAppStatisticsSpotValue test connected failed for app:', app.id),
                                ),
                        );
                    }
                }
            }

            if (!this.chartApp) {
                this.cookieService.removeVivoxAppFilterCookie();
                this.chartApp = this.apps[0];
            }

            await allSettled(appStatPromises);

            // finally render the final results
            this.apps = finalApps;
            this.doFilter();

            this.lastUpdated = moment().format();
            this.setLastUpdatedStr({});

            this.lastUpdatedTimer = setInterval(() => {
                this.setLastUpdatedStr({ inp: this.lastUpdated });
            }, 5000);

            this.showMigrationDeadlinePassedContent =
                !this.auth.isVivox &&
                this.auth.activeStudio &&
                this.auth.activeStudio.migratedToUdash === 'NOT_MIGRATED' &&
                moment.utc().isAfter(moment.utc(environment.migrationEndDate));

            this.migrationDeadlinePassedContent = this.translate.instant('user.migration.deadlinePassed.body', {
                orgName: this.auth.activeStudio.name,
            });

            await this.getMigrationUrl(this.auth.activeStudio.id);

            this.working = false;
        } catch (err) {
            console.error('error handling get applications:', err);
            this.apps = [];
            this.appsFiltered = [];
            this.chartApp = null;

            this.cookieService.removeVivoxAppFilterCookie();
            this.working = false;
        }
    }

    ngOnDestroy() {
        if (this.lastUpdatedTimer) {
            clearInterval(this.lastUpdatedTimer);
        }

        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
