import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { addToClipboard } from '../../shared/utils';

@Component({
    selector: 'app-survey-popup-dialog',
    templateUrl: './survey-popup-dialog.component.html',
    styleUrls: ['./survey-popup-dialog.component.scss'],
})
export class SurveyPopupDialogComponent implements OnInit {
    surveyText: string;
    surveyLink: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<SurveyPopupDialogComponent>,
        private sb: MatSnackBar,
        private translate: TranslateService,
    ) {
        this.surveyLink = data.surveyLink;
        this.surveyText = data.surveyText;
    }

    ngOnInit() {}

    copyToClipboard() {
        addToClipboard(this.surveyLink);
        this.sb.open(this.translate.instant('user.survey-popup.linkCopied'), null, { duration: 2000 });
    }

    hide() {
        this.dialogRef.close();
    }
}
