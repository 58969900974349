import { Pipe, PipeTransform } from '@angular/core';
import * as showdown from 'showdown';

@Pipe({
    name: 'markdown',
})
export class MarkdownPipe implements PipeTransform {
    constructor() {}

    transform(str: string): string {
        let converter = new showdown.Converter();
        converter.setFlavor('github');
        converter.setOption('tables', 'true');
        converter.setOption('emojis', 'true');
        return converter.makeHtml(str);
    }
}
