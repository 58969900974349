import { HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, of as observableOf } from 'rxjs';
import { filter } from 'rxjs/operators';

import { APIResponse } from '../api';
import { LogService } from '../log.service';

interface UploadResponse {
    uuid: string;
    url: string;
}
@Component({
    selector: 'shared-form-file-drop',
    template: `
        <shared-file-drop
            formControlname="gameLogo"
            [types]="types"
            [imageWidth]="imageWidth"
            [imageHeight]="imageHeight"
            [isImage]="isImage"
            [placeholder]="placeholder"
            [name]="name"
            (file)="file($event)"
        ></shared-file-drop>
    `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: FormFileDropComponent, // forwardRef(() => FormFileDropComponent),
            multi: true,
        },
    ],
})
export class FormFileDropComponent implements ControlValueAccessor {
    @Input() types: string[] = [];
    @Input() isImage = false;
    @Input() imageHeight: number = null;
    @Input() imageWidth: number = null;
    @Input() placeholder: any = null;
    @Input() name: string;
    @Input() endpoint: string;
    private _file: File;
    private onChange: any;
    constructor(private http: HttpClient, private log: LogService) {}
    file(file: any) {
        this.onChange(file.base64);
        this._file = file;
    }
    save(): Observable<void> {
        if (!this._file) {
            return observableOf(null);
        }
        return Observable.create(obs => {
            this.http
                .post<APIResponse<UploadResponse>>(this.endpoint, {
                    contentType: this._file.type,
                    filename: this._file.name,
                })
                .subscribe(upload => {
                    this.http
                        .request(
                            new HttpRequest('PUT', upload.data.url, this._file, {
                                reportProgress: false,
                                headers: new HttpHeaders().set('x-amz-acl', 'public-read'),
                                responseType: 'text',
                            }),
                        )
                        .pipe(filter(e => e instanceof HttpResponse))
                        .subscribe(() => {
                            if (this.onChange) {
                                this.onChange(upload.data.url.split('?')[0]);
                            } else {
                                this.log.warn(this, 'failed to find onChange for form-file-drop');
                            }
                            obs.next();
                            obs.complete();
                        });
                });
        });
    }
    registerOnChange(fn: any) {
        this.onChange = fn;
    }
    registerOnTouched() {}
    writeValue(value: any) {
        this.placeholder = value;
    }
}
