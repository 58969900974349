import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AuthService, DedicatedServer, EnterpriseSupport } from 'app/shared';
import moment from 'moment';

@Component({
    selector: 'app-enterprise-dialog',
    templateUrl: 'enterprise-options-dialog.component.html',
    styleUrls: ['./enterprise-options-dialog.component.scss'],
})
export class EnterpriseOptionsDialogComponent implements OnInit {
    @ViewChild('form') form: NgForm;
    public plan: string;
    isChanged: boolean;
    currentStep = 1;
    steps = 1;
    stepsArr = [];
    hasData: boolean;
    originalPlan = '';
    billingAddress = '';
    estimatedPCU = 0;
    estimatedGoLiveDate: any;

    constructor(
        public dialogRef: MatDialogRef<EnterpriseOptionsDialogComponent>,
        public auth: AuthService,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        if (data.enterprise == EnterpriseSupport.NONE && data.dedicated == DedicatedServer.NONE) {
            this.originalPlan = 'free';
            this.setPlan('free');
        } else if (data.enterprise == EnterpriseSupport.BILLED && data.dedicated == DedicatedServer.NONE) {
            this.originalPlan = 'support';
            this.setPlan('support');
        } else if (data.enterprise == EnterpriseSupport.BILLED && data.dedicated == DedicatedServer.PAID) {
            this.originalPlan = 'hosting';
            this.setPlan('hosting');
        }
        this.billingAddress = data.billingAddress;
        this.estimatedPCU = data.estimatedPCU;
        if (data.estimatedGoLiveDate) {
            this.estimatedGoLiveDate = moment.utc(data.estimatedGoLiveDate);
        } else {
            this.estimatedGoLiveDate = null;
        }
    }

    ngOnInit() {
        this.stepsArr = new Array(this.steps);
    }

    public onClose() {
        this.dialogRef.close({ cancel: true, submit: false });
    }

    public setPlan(plan: string) {
        this.plan = plan;
        if (plan != 'free') {
            this.steps = 3;
        } else {
            this.steps = 1;
        }
        this.stepsArr = new Array(this.steps);
    }

    public next() {
        if (this.steps > 1 && this.currentStep < this.steps) {
            this.currentStep++;
        }
    }

    public prev() {
        if (this.steps > 1 && this.currentStep > 1) {
            this.currentStep--;
        }
    }

    public page(page: number) {
        this.currentStep = page;
    }

    public dateFilter(date) {
        return moment(date, 'MM-DD-YYYY', true).isValid();
    }

    public verifyLaunchDate() {
        if (this.estimatedGoLiveDate) {
            return moment(moment(this.estimatedGoLiveDate), 'MM-DD-YYYY', true).isValid();
        }
        return false;
    }

    public onSubmit() {
        let support: string;
        let server: string;
        switch (this.plan) {
            case 'free': {
                support = EnterpriseSupport.NONE;
                server = DedicatedServer.NONE;
                break;
            }
            case 'support': {
                support = EnterpriseSupport.BILLED;
                server = DedicatedServer.NONE;
                break;
            }
            case 'hosting': {
                support = EnterpriseSupport.BILLED;
                server = DedicatedServer.PAID;
                break;
            }
        }
        this.dialogRef.close({
            cancel: false,
            submit: true,
            support: support,
            server: server,
            billingAddress: this.billingAddress,
            estimatedPCU: this.estimatedPCU,
            estimatedGoLiveDate: this.estimatedGoLiveDate,
        });
    }
}
