import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { AuthService } from 'app/shared/auth.service';

@Component({
    selector: 'app-needs-approved-org-dialog',
    templateUrl: './needs-approved-org-dialog.component.html',
    styleUrls: ['./needs-approved-org-dialog.component.scss'],
})
export class NeedsApprovedOrgDialogComponent {
    constructor(
        public auth: AuthService,
        private router: Router,
        public dialogRef: MatDialogRef<NeedsApprovedOrgDialogComponent>,
    ) {
        if (this.auth.approvedOrgAvailable()) {
            this.router.navigate(['/']);
        }
    }
}
