import { Component, OnInit } from '@angular/core';

import { LangService } from './lang.service';

@Component({
    selector: 'app-shared-lang-picker',
    templateUrl: './lang-picker.component.html',
    styleUrls: ['./lang-picker.component.scss'],
})
export class LangPickerComponent implements OnInit {

    constructor(public lang: LangService) { }

    ngOnInit() {
    }
}
