import { AfterViewInit, Component } from '@angular/core';

import { AuthService } from '../../../shared/auth.service';
import { FTUEComponent } from '../ftue.component';

@Component({
    selector: 'app-core-ftue-pre-app',
    templateUrl: './pre-app.component.html',
    styleUrls: ['../ftue.scss'],
})
export class FTUEPreAppComponent extends FTUEComponent implements AfterViewInit {
    isActive = false;
    currentStep = 1;
    steps = 5;
    stepsArr = [];

    constructor(private auth: AuthService) {
        super();
    }

    get isSDKue4Org() {
        return this.auth.isSDKue4Org();
    }

    get isSDKv4Org() {
        return this.auth.isSDKv4Org();
    }

    get isSDKv5Org() {
        return this.auth.isSDKv5Org();
    }

    ngAfterViewInit() {
        this.isActive = true;
        this.stepsArr = new Array(this.steps);
    }
}
