export function deepCopy(obj) {
    let copy;

    // Handle the 3 simple types, and null or undefined
    if (null == obj || 'object' != typeof obj) {
        return obj;
    }

    // Handle Date
    if (obj instanceof Date) {
        copy = new Date();
        copy.setTime(obj.getTime());
        return copy;
    }

    // Handle Array
    if (obj instanceof Array) {
        copy = [];
        for (let i = 0, len = obj.length; i < len; i++) {
            copy[i] = deepCopy(obj[i]);
        }
        return copy;
    }

    // Handle Object
    if (obj instanceof Object) {
        copy = {};
        for (let attr in obj) {
            if (obj.hasOwnProperty(attr)) {
                copy[attr] = deepCopy(obj[attr]);
            }
        }
        return copy;
    }

    throw new Error("Unable to copy obj! Its type isn't supported.");
}

export function allSettled(promises: Promise<any>[]) {
    let mappedPromises = promises.map((p) => {
        return p
            .then((value) => {
                return {
                    status: 'fulfilled',
                    value,
                };
            })
            .catch((reason) => {
                return {
                    status: 'rejected',
                    reason,
                };
            });
    });
    return Promise.all(mappedPromises);
}

export function formatMigrationUrl(url: string): [string, string] {
    if (url) {
        // url comes in a format https://auth.(staging.).cloud.unity.com/...
        // & we need to redirect to https://auth.(staging.).cloud.unity.com/auth/unity?clientId=vivox-migration&redirectTo=https://(staging.).cloud.unity.com/...
        const uDashBase = `${url.split('.com')[0]}.com`;
        const queryParams = `clientId=vivox-migration&redirectTo=${btoa(url.replace('auth.', ''))}`;
        const finalLoginUrl = `${uDashBase}/auth/unity?${queryParams}`;
        localStorage.setItem('migration-redirect-url', finalLoginUrl);
        return [finalLoginUrl, `${finalLoginUrl}&isRegistration=true`];
    }
    return [url, url];
}
