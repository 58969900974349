import { DataSource } from '@angular/cdk/collections';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from '../api.service';
import { AuditLog } from '../audit-log.model';

@Injectable()
export class AuditLogDataSource extends DataSource<AuditLog> {
    dataChange$ = new BehaviorSubject<AuditLog[]>([]);
    filter: any = {
        limit: 50,
        page: 1,
        target: null,
        action: null,
        subAction: null,
        startDate: null,
        endDate: null,
        user: null,
        sort: 'date',
        direction: 'desc',
        includeErrors: true,
        orgId: null,
        orgMemberId: null,
    };
    maxPage = 1000;
    working = false;

    constructor(private api: ApiService) {
        super();
    }

    firstPage() {
        this.filter.page = 1;
        this.update();
    }

    prevPage() {
        this.filter.page -= 1;
        if (this.filter.page == 0) {
            this.filter.page = 1;
        }

        this.update();
    }

    nextPage() {
        this.filter.page += 1;
        this.update();
    }

    update() {
        if (this.filter.page >= this.maxPage) {
            this.filter.page = this.maxPage;
            return;
        }

        this.working = true;

        this.api
            .getAuditLogs(this.filter)
            .pipe(
                map(res => {
                    if (!res) {
                        res = [];
                    }

                    for (let r of res) {
                        const spl = r.action.split('.');
                        let actionType = spl[0];
                        let actionSubType = spl.length > 1 ? spl[1] : '';
                        if (actionType === 'agreement') {
                            r.link = '/admin/agreements/edit/' + r.target;
                        } else if (actionType === 'application') {
                            r.link = '/apps/edit/' + r.target;
                            if (r.target2SubType === 'sdk_platform') {
                                r.link2 = '/admin/attributes/platforms/edit/' + r.target2;
                            } else if (r.target2SubType === 'environment') {
                                r.link2 = '/admin/envs/edit/' + r.target2;
                            } else if (r.target2SubType === 'issuer') {
                                r.link2 = '/apps/detail/' + r.target; // point to detail page of app
                            }
                        } else if (actionType === 'organization') {
                            if (r.targetSubType === 'application') {
                                r.link = '/apps/edit/' + r.target;
                            } else if (r.targetSubType === 'studio') {
                                r.link = '/admin/orgs/edit/' + r.target;
                            }
                        } else if (actionType === 'salesforce') {
                            r.link = '/admin/orgs/edit/' + r.target;
                        } else if (actionType === 'sdk') {
                            r.link = '/admin/sdks/edit/' + r.target;
                        } else if (actionType === 'sdk-engine') {
                            r.link = '/admin/attributes/engines/edit/' + r.target;
                        } else if (actionType === 'sdk-engine-version') {
                            r.link = '/admin/attributes/engine-versions/edit/' + r.target;
                        } else if (actionType === 'sdk-architecture') {
                            r.link = '/admin/attributes/architectures/edit/' + r.target;
                        } else if (actionType === 'sdk-platform') {
                            r.link = '/admin/attributes/platforms/edit/' + r.target;
                        } else if (actionType === 'sdk-platform-version') {
                            r.link = '/admin/attributes/platform-versions/edit/' + r.target;
                        } else if (actionType === 'ot') {
                            r.link = '/admin-overtone/files/edit/' + r.target;
                        } else if (actionType === 'ot-platform') {
                            r.link = '/admin-overtone/attributes/platforms/edit/' + r.target;
                        } else if (actionType === 'ot-architecture') {
                            r.link = '/admin-overtone/attributes/architectures/edit/' + r.target;
                        } else if (actionType === 'ot-platform-version') {
                            r.link = '/admin-overtone/attributes/platform-versions/edit/' + r.target;
                        } else if (actionType === 'environment') {
                            r.link = '/admin/envs/edit/' + r.target;
                        } else if (actionType === 'threshold') {
                            r.link = '/admin/envs/thresholds';
                        } else if (actionType == 'faq') {
                            r.link = '/admin/faqs/' + r.target;
                        } else if (actionType == 'faq-category') {
                            r.link = '/admin/faq-categories/' + r.target;
                        } else if (actionType == 'announcement') {
                            r.link = '/admin/announcements/edit/' + r.target;
                        } else if (actionType == 'gamerfaq') {
                            r.link = '/admin-cms/faqs/' + r.target;
                        } else if (actionType == 'gamerfaq-category') {
                            r.link = '/admin-cms/faq-categories/' + r.target;
                        } else if (actionType == 'email') {
                            r.link = '/admin/emails/' + r.target;
                        } else if (actionType == 'role') {
                            r.link = '/admin/roles/' + r.target;
                        } else if (actionType == 'documentation') {
                            r.link = '/admin/documentation/' + r.target;
                        } else if (
                            actionType == 'report-ccpa' &&
                            (actionSubType == 'download' || actionSubType == 'generated')
                        ) {
                            if (r.target) {
                                r.externalLink = r.target;
                                const parts = r.target.split('/');
                                if (parts.length > 0) {
                                    r.targetName = parts[parts.length - 1];
                                }
                            }
                            if (r.target2) {
                                r.externalLink2 = r.target2;
                                const parts = r.target2.split('/');
                                if (parts.length > 0) {
                                    r.target2Name = parts[parts.length - 1];
                                }
                            }
                        }

                        if (r.relatedOrg) {
                            r.relatedOrgLink = '/admin/orgs/edit/' + r.relatedOrg;
                        }
                    }

                    return res;
                }),
            )
            .subscribe(
                a => {
                    this.dataChange$.next(a);
                    this.working = false;
                },
                () => (this.working = false),
            );
    }

    connect(): Observable<AuditLog[]> {
        return this.dataChange$;
    }

    disconnect() {}
}
