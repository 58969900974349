import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { APIResponse, Faq, FaqCategory, SeoService } from '../../shared';

@Component({
    selector: 'app-faq-category',
    templateUrl: './faq-category.component.html',
    styleUrls: ['./faq-category.component.scss'],
})
export class FaqCategoryComponent implements OnInit {
    category: FaqCategory;
    parent: FaqCategory;
    subcats: FaqCategory[];
    items: Faq[] = [];

    constructor(
        private http: HttpClient,
        private route: ActivatedRoute,
        private router: Router,
        private seo: SeoService,
    ) {}

    ngOnInit() {
        this.route.params.subscribe(p => {
            this.parent = null;
            this.category = null;

            this.http.get<APIResponse<FaqCategory>>(`api/v1/faq-categories/${p.id}`).subscribe(
                r => {
                    this.category = r.data;
                    this.seo.setTitle(this.category.name + ' - FAQs');

                    if (this.category.parentId) {
                        this.http
                            .get<APIResponse<FaqCategory>>(`api/v1/faq-categories/${this.category.parentId}`)
                            .subscribe(r => (this.parent = r.data));
                    }
                },
                () => this.router.navigate(['/faqs']),
            );

            this.http
                .get<APIResponse<FaqCategory[]>>(`api/v1/faq-categories?parentId=${p.id}`)
                .subscribe(r => (this.subcats = r.data));

            this.http.get<APIResponse<Faq[]>>(`api/v1/faqs?categoryId=${p.id}`).subscribe(r => {
                r.data.sort((a, b) => a.sort - b.sort);
                this.items = r.data;
            });
        });
    }
}
