import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UpdateUserEmail } from 'app/shared';

import { ChangeEmailService } from './change-email.service';

@Component({
    selector: 'app-change-email-dialog',
    templateUrl: './change-email-dialog.component.html',
    styleUrls: ['./change-email-dialog.component.scss'],
})
export class ChangeEmailDialogComponent {
    @Output() close = new EventEmitter<void>();

    emailPattern = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    model: UpdateUserEmail;
    errorMessage: string;
    working = false;

    constructor(
        private changeEmailService: ChangeEmailService,
        private translate: TranslateService,
        private router: Router,
    ) {
        this.model = this.changeEmailService.initModel;
    }

    changeEmail() {
        this.working = true;
        this.errorMessage = '';
        this.changeEmailService.change(this.model).subscribe(
            () => {
                this.working = false;

                this.closeBg();

                this.router
                    .navigate(['/apps'], { skipLocationChange: true })
                    .then(() => this.router.navigate(['/verify/email']));
            },
            (err: HttpErrorResponse) => {
                this.working = false;
                const { error } = err;
                if (error && error.data) {
                    this.errorMessage = error.data;
                    switch (error.data) {
                        case 'wrong_email':
                            this.errorMessage = this.translate.instant('user.change-email-dialog.errors.wrongEmail');
                            break;
                        case 'no_match':
                            this.errorMessage = this.translate.instant('user.change-email-dialog.errors.noMatch');
                            break;
                        default:
                            this.errorMessage = this.translate.instant(
                                'user.change-email-dialog.errors.defaultEmailError',
                            );
                    }
                } else {
                    this.errorMessage = this.translate.instant('user.change-email-dialog.errors.defaultEmailError');
                }
            },
        );
    }

    closeBg() {
        this.close.emit();
    }
}
