import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { AuthService, SeoService } from '../../shared';

@Component({
    selector: 'core-verify-email',
    styleUrls: ['./verify-email.component.scss'],
    templateUrl: './verify-email.component.html',
})
export class VerifyEmailComponent {
    working = false;

    constructor(
        public auth: AuthService,
        private router: Router,
        private seo: SeoService,
        private http: HttpClient,
        private sb: MatSnackBar,
        private translate: TranslateService,
    ) {
        if (!this.auth.isAuthenticated()) {
            this.router.navigate(['/']);
            return;
        } else if (this.auth.user.emailVerified) {
            this.router.navigate(['/']);
        }

        this.seo.setTitle('Verify Email Address');

        this.onRefresh(false);
    }

    onRefresh(clicked: boolean) {
        this.working = true;

        this.auth.refreshUser().subscribe(() => {
            if (this.auth.user.emailVerified) {
                this.router.navigate(['/']);
                return;
            }

            if (clicked) {
                this.sb.open(this.translate.instant('user.verify.email.notVerified'), null, {
                    duration: 5000,
                    panelClass: 'bad-snack',
                });
            }

            this.working = false;
        });
    }

    onResend() {
        this.working = true;
        this.http.get(`api/v1/user/send-verification-email`).subscribe(() => {
            this.working = false;
        });
    }
}
