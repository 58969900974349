import { DataSource } from '@angular/cdk/collections';
import { Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { BehaviorSubject ,  Observable } from 'rxjs';

import { ApiService } from '../api.service';
import { Overtone } from '../overtone.model';

@Injectable()
export class OvertoneDataSource extends DataSource<Overtone> {
  dataChange$ = new BehaviorSubject<Overtone[]>([]);
  sort: Sort = { direction: 'asc', active: 'version' };
  filter = {
    engine: null,
    platform: null,
  };

  constructor(private api: ApiService) {
    super();
  }

  update() {
    this.api.getOvertones().subscribe(res => {
      this.dataChange$.next(res);
    });
  }

  connect(): Observable<Overtone[]> {
    return this.dataChange$;
  }

  disconnect() {}
}
