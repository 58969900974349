import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ApiService, App, SeoService } from '../../../shared';

@Component({
    selector: 'core-apps-create',
    templateUrl: './apps-create.component.html',
})
export class AppsCreateComponent implements OnDestroy {
    working = true;

    app: App;

    step = 1;

    private ngUnsubscribe = new Subject<void>();

    constructor(
        private api: ApiService,
        private router: Router,
        private route: ActivatedRoute,
        private seo: SeoService,
    ) {
        this.route.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe(async res => {
            if (!res.id) {
                this.seo.setTitle('Create Application');
                this.working = false;
                this.app = {
                    genre: [],
                };
                return;
            }

            this.api
                .getApp(res.id)
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(app => {
                    this.app = app;

                    this.seo.setTitle(this.app.name + ' - Application Management');
                });
        });
    }

    onSave(app: App) {
        this.router.navigate(['/apps/detail', app.id]);
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
