
import {throwError as observableThrowError,  Observable ,  ReplaySubject } from 'rxjs';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { catchError, finalize } from 'rxjs/operators';

@Injectable()
export class PendingInterceptor implements HttpInterceptor {
    pendingRequests = 0;
    pendingRequestsStatus: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // TODO: add support to skip urls
        this.pendingRequests++;

        if (1 === this.pendingRequests) {
            this.pendingRequestsStatus.next(true);
        }

        return next.handle(req).pipe(
            catchError(error => observableThrowError(error)),
            finalize(() => {
                // TODO: add support to skip urls
                this.pendingRequests--;

                if (0 === this.pendingRequests) {
                    this.pendingRequestsStatus.next(false);
                }
            }),
        );
    }
}
