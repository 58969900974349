import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'targetBlank',
})
export class TargetBlankPipe implements PipeTransform {
    transform(str: string) {
        return str.replace(/\<a href=/, '<a target="_blank" href=');
    }
}
