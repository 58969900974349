import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as Cookies from 'js-cookie';
import moment from 'moment';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import {
    ApiResponse,
    DescriptiveWords,
    DomainFilter,
    JobQueueStatus,
    Maintenance,
    USPSAddressResult,
} from '../core/models';

import { AnnouncementMediaUploadRequest } from './announcement.model';
import { APIResponse } from './api';
import { App, AppEnv, AppStatistics, AppUploadRequest } from './app.model';
import { AuditLog } from './audit-log.model';
import { Env, EnvThreshold } from './env.model';
import { Org, OrgUploadRequest, OrganizationAgreement, QuickOrg } from './org.model';
import {
    Overtone,
    OvertoneArchitecture,
    OvertoneMediaUploadRequest,
    OvertonePlatform,
    OvertonePlatformVersion,
} from './overtone.model';
import {
    Sdk,
    SdkArchitecture,
    SdkCreateRequest,
    SdkEngine,
    SdkEngineUploadRequest,
    SdkEngineVersion,
    SdkPlatform,
    SdkPlatformUploadRequest,
    SdkPlatformVersion,
    SdkUploadRequest,
    SdkVersion,
} from './sdk.model';
import { UpdateUserEmail, User, UserUploadRequest } from './user.model';

@Injectable()
export class ApiService {
    constructor(private http: HttpClient) {}

    activateRemoteApi(orgId: number) {
        return this.post(`api/v1/organizations/${orgId}/remote-api/enable`, {});
    }

    regenerateRemoteApiPassword(orgId: number) {
        return this.post(`api/v1/organizations/${orgId}/remote-api/new-secret`, {});
    }

    emailRemoteApiCheck() {
        return this.get(`api/v1/announcement/email/remote-api/check`);
    }

    emailRemoteApiEnable() {
        return this.post(`api/v1/announcement/email/remote-api/enable`, {});
    }

    emailRemoteApiDisable() {
        return this.delete(`api/v1/announcement/email/remote-api/disable`);
    }

    emailRemoteApiNewSecret() {
        return this.post(`api/v1/announcement/email/remote-api/new-secret`, {});
    }

    getUser(id: string): Observable<User> {
        return this.get(`api/v1/users/${encodeURIComponent(id)}`);
    }

    getUsers(params?: { query?: string; count?: boolean }): Observable<User[]> {
        if (params) {
            let query = params.query || '';
            query += query ? `&count=${!!params.count}` : `count=${!!params.count}`;
            return this.get(`api/v1/users?${query}`);
        }
        return this.get(`api/v1/users`);
    }

    updateUser(user: User): Observable<User> {
        return this.put(`api/v1/users/${user.userID}`, user);
    }

    changeUserEmail(model: UpdateUserEmail) {
        return this.http
            .put<APIResponse<boolean>>(`api/v1/user/change-email`, { ...model })
            .pipe(map((res) => res.data));
    }

    createOrgName(): Observable<any> {
        return this.get(`api/v1/organization-name`);
    }

    createRegisterOrgName(firstName, lastName: string): Observable<any> {
        return this.post(`api/v1/organization-name`, { firstName: firstName, lastName: lastName });
    }

    getAuditLogs(filter: any): Observable<AuditLog[]> {
        let queryString = [];

        if (filter.action && filter.subAction) {
            queryString.push('action=' + filter.action + '.' + filter.subAction);
        } else if (filter.action) {
            queryString.push('action=' + filter.action + '.');
        }

        if (filter.target) {
            queryString.push('target=' + filter.target);
        }

        if (filter.user) {
            queryString.push('user=' + filter.user);
        }

        if (filter.startDate) {
            queryString.push('startDate=' + moment(filter.startDate).utc().format());
        }

        if (filter.endDate) {
            queryString.push('endDate=' + moment(filter.endDate).utc().format());
        }

        if (filter.sort) {
            queryString.push('sort=' + filter.sort);
        }

        if (filter.direction) {
            queryString.push('direction=' + filter.direction);
        }

        if (filter.includeErrors) {
            queryString.push('extra=1');
            queryString.push('errors=1');
        }

        if (filter.orgId) {
            queryString.push(`org=${filter.orgId}`);
        }

        if (filter.orgMemberId) {
            queryString.push(`member=${filter.orgMemberId}`);
        }

        queryString.push('limit=' + (filter.limit || 100));
        queryString.push('offset=' + (filter.page ? (filter.page - 1) * (filter.limit || 100) : 0));

        let queryStringStr = queryString.join('&');

        return this.get(`api/v1/audit-logs?${queryStringStr}`);
    }

    startAuditLogCSVJob(filter: any): Observable<string> {
        let queryString = [];

        if (filter.action && filter.subAction) {
            queryString.push('action=' + filter.action + '.' + filter.subAction);
        } else if (filter.action) {
            queryString.push('action=' + filter.action + '.');
        }

        if (filter.target) {
            queryString.push('target=' + filter.target);
        }

        if (filter.user) {
            queryString.push('user=' + filter.user);
        }

        if (filter.startDate) {
            queryString.push('startDate=' + moment(filter.startDate).utc().format());
        }

        if (filter.endDate) {
            queryString.push('endDate=' + moment(filter.endDate).utc().format());
        }

        if (filter.sort) {
            queryString.push('sort=' + filter.sort);
        }

        if (filter.direction) {
            queryString.push('direction=' + filter.direction);
        }

        queryString.push('download=1');
        queryString.push('downloadType=csv');

        if (filter.includeErrors) {
            queryString.push('extra=1');
            queryString.push('errors=1');
        }

        if (filter.orgId) {
            queryString.push(`org=${filter.orgId}`);
        }

        if (filter.orgMemberId) {
            queryString.push(`member=${filter.orgMemberId}`);
        }

        queryString.push('limit=100000');

        let queryStringStr = queryString.join('&');

        return this.get(`api/v1/audit-logs/CSV/job/start?${queryStringStr}`);
    }

    checkAuditLogCSVJob(guid: string): Observable<JobQueueStatus> {
        return this.get(`api/v1/audit-logs/CSV/job/${guid}`);
    }

    startFilterOrgUsersJob(queryString: string): Observable<string> {
        return this.get(`api/v1/announcement/filter/job/start${queryString}`);
    }

    getFilterOrgUsersJobResult(guid: string): Observable<JobQueueStatus[]> {
        return this.get(`api/v1/announcement/filter/job/${guid}`);
    }

    getFilterOrgUsersJobStatus(guid: string): Observable<JobQueueStatus> {
        return this.get(`api/v1/announcement/filter/job/${guid}/status`);
    }

    checkMaintenance(): Observable<boolean> {
        return this.get(`api/v1/maintenance/check`);
    }

    createUserUpload(id: string, upload: UserUploadRequest): Observable<string> {
        return this.post(`api/v1/users/${id}/upload`, upload);
    }

    getAppStatisticsSpotValue(app: App, env: string, stat: string): Observable<number> {
        let url = `api/v1/statistics/application/${app.id}/spot?stat=${stat}&type=${env}`;

        return this.get(url);
    }

    getAppStatisticsDateRange(
        app: App,
        env: string,
        stat: string,
        start: string = null,
        end: string = null,
        interval = '5m',
    ): Observable<AppStatistics> {
        const apiUrl = 'api/v1/statistics/application/';

        let url = `${apiUrl}${app.id}/range?start=${start}&end=${end}&stat=${stat}&duration=${interval}&type=${env}`;

        if (!start && !end) {
            url = `${apiUrl}${app.id}?stat=${stat}&duration=${interval}&type=${env}`;
        }

        return this.get(url);
    }

    getOrgs(args: { type?: string; status?: string }): Observable<QuickOrg[]> {
        const queryList: string[] = [];

        queryList.push('deleted=0');
        queryList.push('data=quick');

        if (args.type) {
            queryList.push(`type=${args.type}`);
        }
        if (args.status) {
            queryList.push(`s=${args.status}`);
        }

        const params = queryList.join('&');

        return this.get(`api/v1/organizations?${params}`);
    }

    getOrgMembers(id: number, includeInvites = true): Observable<User[]> {
        if (includeInvites) {
            return this.get(`api/v1/organizations/${id}/users?include_invites=1`);
        }
        return this.get(`api/v1/organizations/${id}/users`);
    }

    getApps(query?: string, envId?: number, isCount = false, parentId?: number): Observable<App[]> {
        const paramsList = [];
        if (parentId) {
            paramsList.push(`parent=${parentId}`);
        }
        if (envId) {
            paramsList.push(`env=${envId}`);
        }
        if (isCount) {
            paramsList.push(`count=true`);
        }

        const params = paramsList.join('&');
        if (query) {
            query = `?${query}${params ? `&${params}` : ''}`;
        } else if (params) {
            query = `?${params}`;
        }

        return this.get(`api/v1/applications${query || ''}`);
    }

    deleteApp(app: App): Observable<void> {
        return this.delete(`api/v1/applications/${app.id}`);
    }

    getApp(id: number): Observable<App> {
        return this.get(`api/v1/applications/${id}`);
    }

    regenerateAppCredentials(app: App, env: AppEnv): Observable<any> {
        return this.post(`api/v1/applications/${app.id}/environment-admin/${env.title}`, {});
    }

    regenerateAppKey(app: App, env: AppEnv): Observable<string> {
        return this.post(`api/v1/applications/${app.id}/environment-key/${env.title}`, {});
    }

    revokeAppKey(app: App, env: AppEnv): Observable<any> {
        return this.delete(`api/v1/applications/${app.id}/environment-key/${env.title}/${env.keys[1]}`);
    }

    getAppName(name: string, orgId): Observable<number> {
        return this.get(`api/v1/application-name/?name=${name}&parent_org=${orgId}`);
    }

    saveApp(app: App): Observable<App> {
        // even if sending a single type it needs sent as arrays
        if (!Array.isArray(app.platform)) {
            app.platform = [app.platform];
        }

        if (!Array.isArray(app.platformVersion)) {
            app.platformVersion = [app.platformVersion];
        }

        if (app.id) {
            return this.put(`api/v1/applications/${app.id}`, app);
        }
        return this.post('api/v1/applications', app);
    }

    createAppUpload(app: App, upload: AppUploadRequest): Observable<string> {
        return this.post(`api/v1/applications/${app.id}/upload`, upload);
    }

    createAppEnvironment(app: App, environment: number, platforms: number[]): Observable<void> {
        return this.post(`api/v1/applications/${app.id}/environment`, {
            environment,
            platforms,
        });
    }

    setAppApproved(app: any, env: Env): Observable<App> {
        app.status = 'approved';
        app.customEnv = env.id;

        return this.put(`api/v1/applications/${app.id}`, app);
    }

    setAppPending(app: App): Observable<App> {
        app.status = 'pending';
        return this.put(`api/v1/applications/${app.id}`, app);
    }

    setAppRejected(app: App): Observable<App> {
        app.status = 'rejected';
        return this.put(`api/v1/applications/${app.id}`, app);
    }

    getDomainFilters(): Observable<DomainFilter[]> {
        return this.get(`api/v1/domain-filters`);
    }

    createDomainFilter(domain: string): Observable<DomainFilter> {
        return this.post(`api/v1/domain-filters`, { domain: domain });
    }

    deleteDomainFilter(id: number): Observable<void> {
        return this.http.delete<void>(`api/v1/domain-filters/${id}`);
    }

    getDescriptiveWords(): Observable<DescriptiveWords[]> {
        return this.get(`api/v1/descriptive-words`);
    }

    createDescriptiveWord(word: string): Observable<DomainFilter> {
        return this.post(`api/v1/descriptive-words`, { word: word });
    }

    deleteDescriptiveWord(id: number): Observable<void> {
        return this.http.delete<void>(`api/v1/descriptive-words/${id}`);
    }

    getMaintenanceState(): Observable<Maintenance> {
        return this.get(`api/v1/maintenance`);
    }

    getOrgName(name: string): Observable<number> {
        return this.get(`api/v1/organizations/exists?name=${encodeURIComponent(name)}`);
    }

    getOrg(id: number): Observable<Org> {
        return this.get(`api/v1/organizations/${id}`);
    }

    saveOrg(org: Org): Observable<Org> {
        if (org.id) {
            return this.put(`api/v1/organizations/${org.id}`, org);
        }
        return this.post('api/v1/organizations', org);
    }

    getOrgMigrationUrl(id: number): Observable<string> {
        return this.get(`api/v1/organizations/${id}/migration-url`);
    }

    createOrgUpload(org: Org, upload: OrgUploadRequest): Observable<string> {
        return this.post(`api/v1/organizations/${org.id}/upload`, upload);
    }

    setOrgApproved(id: number, request: any): Observable<Org> {
        return this.put(`api/v1/organizations/${id}/approve`, request);
    }

    getDefaultSalesContact() {
        return this.http.get<APIResponse<string>>(`api/v1/organizations/default-sales-contact`);
    }

    deleteOrg(org: Org, sendEmail = false): Observable<Org> {
        if (sendEmail) {
            return this.delete(`api/v1/organizations/${org.id}?sendEmail=true`);
        }
        return this.delete(`api/v1/organizations/${org.id}`);
    }

    setOrgRejected(id: number, request: any): Observable<Org> {
        return this.put(`api/v1/organizations/${id}/reject`, request);
    }

    setEnvActive(env: Env): Observable<Env> {
        env.active = 1;
        return this.put(`api/v1/environments/${env.id}`, env);
    }

    getEnvs(params?: { admin?: boolean; platform?: string; sort?: string; direction?: string }): Observable<Env[]> {
        let url = `api/v1/environments`;

        if (params) {
            const queryParams: string[] = [];
            if (params.admin) {
                queryParams.push(`admin=${params.admin}`);
            }

            if (params.platform) {
                queryParams.push(`platform=${params.platform}`);
            }

            queryParams.push(`sort=${params.sort || 'name'}`);
            queryParams.push(`direction=${params.direction || 'asc'}`);

            if (queryParams.length > 0) {
                const queryParamsString = queryParams.join('&');
                url += `?${queryParamsString}`;
            }
        }

        return this.get(url);
    }

    saveEnv(env: Env): Observable<Env> {
        if (env.id) {
            return this.put(`api/v1/environments/${env.id}`, env);
        }
        return this.post('api/v1/environments', env);
    }

    testEnv(env: Env): Observable<boolean> {
        return this.post(`api/v1/environments/test`, env);
    }

    getEnv(id: number): Observable<Env> {
        return this.get(`api/v1/environments/${id}`);
    }

    getEnvStatistics(env: Env): Observable<number> {
        return this.get(`api/v1/statistics/environment/${env.id}?stat=chanusers`);
    }

    getEnvThresholds(): Observable<EnvThreshold[]> {
        return this.get(`api/v1/thresholds`);
    }

    saveEnvThreshold(threshold: EnvThreshold): Observable<EnvThreshold> {
        return this.put(`api/v1/thresholds/${threshold.type}`, threshold);
    }

    deleteEnv(env: Env): Observable<void> {
        return this.delete(`api/v1/environments/${env.id}`);
    }

    setFTUEShown(user: User, ftue: string): Observable<void> {
        if (!user.ftues || user.ftues.findIndex((x) => x == ftue) == -1) {
            if (!user.ftues) {
                user.ftues = [ftue];
            } else {
                user.ftues.push(ftue);
            }
            return this.put(`api/v1/users/${user.userID}`, user);
        }
    }

    getSdks(param?: { status?: string; org?: number }): Observable<Sdk[]> {
        const query = ['limit=5000'];

        let queryString: string;
        if (param) {
            if (param.status) {
                query.push(`status=${param.status}`);
            }

            if (param.org) {
                query.push(`org=${param.org}`);
            }
        }

        queryString = query.join('&');

        return this.get(`api/v1/sdks?${queryString}`);
    }

    getOvertones(status?: string): Observable<Overtone[]> {
        if (status) {
            return this.get(`api/v1/ot-clients?limit=5000&status=${status}`);
        } else {
            return this.get(`api/v1/ot-clients?limit=5000`);
        }
    }

    getSdk(id: number): Observable<Sdk> {
        return this.get(`api/v1/sdks/${id}`);
    }

    getOvertone(id: number): Observable<Overtone> {
        return this.get(`api/v1/ot-clients/${id}`);
    }

    deleteSdk(sdk: Sdk): Observable<void> {
        return this.delete(`api/v1/sdks/${sdk.id}`);
    }

    deleteOvertone(overtone: Overtone): Observable<void> {
        return this.delete(`api/v1/ot-clients/${overtone.id}`);
    }

    saveSdk(sdk: SdkCreateRequest): Observable<Sdk> {
        if (sdk.id) {
            return this.put(`api/v1/sdks/${sdk.id}`, sdk);
        }
        return this.post('api/v1/sdks', sdk);
    }

    saveOvertone(overtone: Overtone): Observable<Overtone> {
        if (overtone.id) {
            return this.put(`api/v1/ot-clients/${overtone.id}`, overtone);
        }
        return this.post('api/v1/ot-clients', overtone);
    }

    createOvertoneMediaUpload(id: any, upload: OvertoneMediaUploadRequest): Observable<string> {
        return this.post(`api/v1/ot-clients/${id}/upload`, upload);
    }

    createAnnouncementMediaUpload(id: any, upload: AnnouncementMediaUploadRequest): Observable<string> {
        return this.post(`api/v1/announcements/${id}/upload`, upload);
    }

    createSdkUpload(upload: SdkUploadRequest): Observable<string> {
        return this.post('api/v1/sdks/upload', upload);
    }

    getSdkArchitectures(includeDeleted = false): Observable<SdkArchitecture[]> {
        if (includeDeleted) {
            return this.get('api/v1/sdk-architectures?deleted=1');
        } else {
            return this.get('api/v1/sdk-architectures');
        }
    }

    getOvertoneArchitectures(includeDeleted = false): Observable<OvertoneArchitecture[]> {
        if (includeDeleted) {
            return this.get('api/v1/ot-architectures?deleted=1');
        } else {
            return this.get('api/v1/ot-architectures');
        }
    }

    saveSdkArchitecture(architecture: SdkArchitecture): Observable<SdkArchitecture> {
        if (architecture.id) {
            return this.put(`api/v1/sdk-architectures/${architecture.id}`, architecture);
        }

        return this.post('api/v1/sdk-architectures', architecture);
    }

    saveOvertoneArchitecture(architecture: OvertoneArchitecture): Observable<OvertoneArchitecture> {
        if (architecture.id) {
            return this.put(`api/v1/ot-architectures/${architecture.id}`, architecture);
        }

        return this.post('api/v1/ot-architectures', architecture);
    }

    deleteSdkArchitecture(architecture: SdkArchitecture): Observable<SdkArchitecture> {
        return this.delete(`api/v1/sdk-architectures/${architecture.id}`);
    }

    deleteOvertoneArchitecture(architecture: OvertoneArchitecture): Observable<OvertoneArchitecture> {
        return this.delete(`api/v1/ot-architectures/${architecture.id}`);
    }

    getSdkEngines(includeDeleted = false): Observable<SdkEngine[]> {
        if (includeDeleted) {
            return this.get('api/v1/sdk-engines?deleted=1');
        } else {
            return this.get('api/v1/sdk-engines');
        }
    }

    saveSdkEngine(engine: SdkEngine): Observable<SdkEngine> {
        if (engine.id) {
            return this.put(`api/v1/sdk-engines/${engine.id}`, engine);
        }

        return this.post('api/v1/sdk-engines', engine);
    }

    deleteSdkEngine(engine: SdkEngine): Observable<SdkEngine> {
        return this.delete(`api/v1/sdk-engines/${engine.id}`);
    }

    createEngineUpload(id: any, upload: SdkEngineUploadRequest): Observable<string> {
        return this.post(`api/v1/sdk-engines/${id}/upload`, upload);
    }

    getSdkEngineVersions(includeDeleted = false): Observable<SdkEngineVersion[]> {
        if (includeDeleted) {
            return this.get('api/v1/sdk-engine-versions?deleted=1');
        } else {
            return this.get('api/v1/sdk-engine-versions');
        }
    }

    saveSdkEngineVersion(version: SdkEngineVersion): Observable<SdkEngineVersion> {
        if (version.id) {
            return this.put(`api/v1/sdk-engine-versions/${version.id}`, version);
        }

        return this.post('api/v1/sdk-engine-versions', version);
    }

    deleteSdkEngineVersion(version: SdkEngineVersion): Observable<SdkEngineVersion> {
        return this.delete(`api/v1/sdk-engine-versions/${version.id}`);
    }

    getSdkVersions(includeDeleted = false): Observable<SdkVersion[]> {
        if (includeDeleted) {
            return this.get('api/v1/sdk-versions?deleted=1');
        } else {
            return this.get('api/v1/sdk-versions');
        }
    }

    saveSdkVersion(version: SdkVersion): Observable<SdkVersion> {
        if (version.id) {
            return this.put(`api/v1/sdk-versions/${version.id}`, version);
        }

        return this.post('api/v1/sdk-versions', version);
    }

    deleteSdkVersion(version: SdkVersion): Observable<SdkVersion> {
        return this.delete(`api/v1/sdk-versions/${version.id}`);
    }

    getSdkPlatforms(includeDeleted = false): Observable<SdkPlatform[]> {
        if (includeDeleted) {
            return this.get('api/v1/sdk-platforms?deleted=1');
        } else {
            return this.get('api/v1/sdk-platforms');
        }
    }

    getVivoxSdkPlatforms(id: any): Observable<SdkPlatform[]> {
        return this.get(`api/v1/organizations/${id}/sdk-platforms`);
    }

    getOvertonePlatforms(includeDeleted = false): Observable<OvertonePlatform[]> {
        if (includeDeleted) {
            return this.get('api/v1/ot-platforms?deleted=1');
        } else {
            return this.get('api/v1/ot-platforms');
        }
    }

    saveSdkPlatform(platform: SdkPlatform): Observable<SdkPlatform> {
        if (platform.id) {
            return this.put(`api/v1/sdk-platforms/${platform.id}`, platform);
        }

        return this.post('api/v1/sdk-platforms', platform);
    }

    saveOvertonePlatform(platform: OvertonePlatform): Observable<OvertonePlatform> {
        if (platform.id) {
            return this.put(`api/v1/ot-platforms/${platform.id}`, platform);
        }

        return this.post('api/v1/ot-platforms', platform);
    }

    deleteSdkPlatform(platform: SdkPlatform): Observable<SdkPlatform> {
        return this.delete(`api/v1/sdk-platforms/${platform.id}`);
    }

    deleteOvertonePlatform(platform: OvertonePlatform): Observable<OvertonePlatform> {
        return this.delete(`api/v1/ot-platforms/${platform.id}`);
    }

    createPlatformUpload(id: any, upload: SdkPlatformUploadRequest): Observable<string> {
        return this.post(`api/v1/sdk-platforms/${id}/upload`, upload);
    }

    createOvertonePlatformUpload(id: any, upload: SdkPlatformUploadRequest): Observable<string> {
        return this.post(`api/v1/ot-platforms/${id}/upload`, upload);
    }

    getSdkPlatformVersions(includeDeleted = false): Observable<SdkPlatformVersion[]> {
        if (includeDeleted) {
            return this.get('api/v1/sdk-platform-versions?deleted=1');
        } else {
            return this.get('api/v1/sdk-platform-versions');
        }
    }

    getOvertonePlatformVersions(includeDeleted = false): Observable<OvertonePlatformVersion[]> {
        if (includeDeleted) {
            return this.get('api/v1/ot-platform-versions?deleted=1');
        } else {
            return this.get('api/v1/ot-platform-versions');
        }
    }

    saveSdkPlatformVersion(version: SdkPlatformVersion): Observable<SdkPlatformVersion> {
        if (version.id) {
            return this.put(`api/v1/sdk-platform-versions/${version.id}`, version);
        }

        return this.post('api/v1/sdk-platform-versions', version);
    }

    saveOvertonePlatformVersion(version: OvertonePlatformVersion): Observable<OvertonePlatformVersion> {
        if (version.id) {
            return this.put(`api/v1/ot-platform-versions/${version.id}`, version);
        }

        return this.post('api/v1/ot-platform-versions', version);
    }

    deleteSdkPlatformVersion(version: SdkPlatformVersion): Observable<SdkPlatformVersion> {
        return this.delete(`api/v1/sdk-platform-versions/${version.id}`);
    }

    deleteOvertonePlatformVersion(version: OvertonePlatformVersion): Observable<OvertonePlatformVersion> {
        return this.delete(`api/v1/ot-platform-versions/${version.id}`);
    }

    sendOtPromoEmail(fullName, email, studioName?, appName?: string): Observable<any> {
        return this.post(`api/v1/ot-promo/notify`, {
            fullName: fullName,
            email: email,
            studioName: studioName,
            appName: appName,
        });
    }

    checkInvite(token: string): Observable<any> {
        return this.http.post<void>(`api/v1/invite/check`, {
            code: token,
        });
    }

    acceptInvite(token: string, email: string): Observable<any> {
        return this.post(`api/v1/invite/accept`, {
            code: token,
            email: email,
        });
    }

    createInvite(email: string, org: number): Observable<any> {
        return this.post(`api/v1/invite/create`, {
            email: email,
            organization: org,
        });
    }

    deleteInvite(org: number, token: string): Observable<any> {
        return this.post(`api/v1/invite/delete`, {
            organization: org,
            code: token,
        });
    }

    resendInvite(org: number, token: string): Observable<any> {
        return this.post(`api/v1/invite/resend`, {
            organization: org,
            code: token,
        });
    }

    getOrgAgreements(orgId: number) {
        return this.http
            .get<ApiResponse<OrganizationAgreement[]>>(`api/v1/organizations/${orgId}/agreements/all`)
            .pipe(map((response) => response.data || []));
    }

    startCCPAReportBuild(params: Object, userId?: string): Observable<string> {
        if (userId) {
            return this.post(`api/v1/reports/CCPA/job/start?userid=${userId}`, params);
        }
        return this.post(`api/v1/reports/CCPA/job/start`, params);
    }

    checkCCPAReportBuildJob(guid: string, userId?: string): Observable<JobQueueStatus> {
        if (userId) {
            return this.get(`api/v1/reports/CCPA/job/${guid}?userid=${userId}`);
        }
        return this.get(`api/v1/reports/CCPA/job/${guid}`);
    }

    checkCCPAReportActiveJob(userId?: string): Observable<string> {
        if (userId) {
            return this.get(`api/v1/reports/CCPA/job/active?userid=${userId}`);
        }
        return this.get(`api/v1/reports/CCPA/job/active`);
    }

    checkMailingAddress(address: object): Observable<USPSAddressResult> {
        return this.post(`api/v1/reports/check-mailing-address`, address);
    }

    ccpaDownloadForCurrentUser() {
        return this.post(`api/v1/reports/CCPA/download`, null);
    }

    ccpaDownloadForSpecificUser(userId: string) {
        return this.post(`api/v1/reports/CCPA/download?userid=${userId}`, null);
    }

    ccpaDownloadFromAuditLog(targetName: string) {
        return this.post(`api/v1/reports/CCPA/download?target=${targetName}`, null);
    }

    post(endpoint: string, body: any): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/${endpoint}`, body, { headers: this.getHeaders() }).pipe(
            map((res) => res.data),
            catchError((err) => {
                if (err.status === 401) {
                    this.destroySession();
                    location.reload();
                }

                throw err;
            }),
        );
    }

    put(endpoint: string, body: any): Observable<any> {
        return this.http.put<any>(`${environment.apiUrl}/${endpoint}`, body, { headers: this.getHeaders() }).pipe(
            map((res) => res.data),
            catchError((err) => {
                if (err.status === 401) {
                    this.destroySession();
                    location.reload();
                }

                throw err;
            }),
        );
    }

    delete(endpoint: string): Observable<any> {
        return this.http.delete<any>(`${environment.apiUrl}/${endpoint}`, { headers: this.getHeaders() }).pipe(
            map((res) => res.data),
            catchError((err) => {
                if (err.status === 401) {
                    this.destroySession();
                    location.reload();
                }

                throw err;
            }),
        );
    }

    get(endpoint: string): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/${endpoint}`, { headers: this.getHeaders() }).pipe(
            map((res) => (res.data !== undefined ? res.data : res)),
            catchError((err) => {
                if (err.status === 401) {
                    this.destroySession();
                    location.reload();
                }

                throw err;
            }),
        );
    }

    private destroySession() {
        // not in CookieService because these are strictly necessary cookies
        Cookies.remove('access_token', { sameSite: 'strict' });
        Cookies.remove('id_token', { sameSite: 'strict' });
        Cookies.remove('user', { sameSite: 'strict' });
        if (localStorage) {
            localStorage.clear();
        }
    }

    private getHeaders(): HttpHeaders | null {
        // not in CookieService because it is a strictly necessary cookie
        const token = Cookies.get('id_token');
        if (!token) {
            return null;
        }

        let headers = new HttpHeaders();
        headers = headers.set('Authorization', `Bearer ${token}`);

        return headers;
    }
}
