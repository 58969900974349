import 'hammerjs';
import { of as observableOf } from 'rxjs';

import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { Routes } from '@angular/router/src/config';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared';

const langs = {};
langs['en'] = require('../assets/i18n/en.json');
langs['kr'] = require('../assets/i18n/kr.json');
langs['ja'] = require('../assets/i18n/ja.json');
langs['zh'] = require('../assets/i18n/zh.json');

class TranslateFileLoader implements TranslateLoader {
    getTranslation(lang: string): any {
        if (langs[lang]) {
            return observableOf(langs[lang]);
        }

        return observableOf({});
    }
}

export function translateLoaderFactory(): TranslateFileLoader {
    return new TranslateFileLoader();
}

const routes: Routes = [];

@NgModule({
    declarations: [AppComponent],
    imports: [
        // angular
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(routes),
        HttpClientModule,

        // app
        CoreModule,
        SharedModule.forRoot(),

        // vendor
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: translateLoaderFactory,
                deps: [HttpClient],
            },
        }),
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
