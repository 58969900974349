import { DataSource } from '@angular/cdk/collections';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ApiService } from '../api.service';
import { SdkArchitecture, SdkPlatform } from '../sdk.model';

@Injectable()
export class SdkArchitectureDataSource extends DataSource<SdkArchitecture> {
    dataChange$ = new BehaviorSubject<SdkArchitecture[]>([]);
    platforms: { [key: number]: SdkPlatform } = {};
    working = false;

    constructor(private api: ApiService) {
        super();
    }

    update() {
        this.working = true;
        this.api.getSdkPlatforms(true).subscribe(
            p => {
                p.forEach(platform => {
                    this.platforms[platform.id] = platform;
                });

                this.api.getSdkArchitectures(true).subscribe(
                    a => {
                        this.working = false;
                        if (a && a.length > 0) {
                            a.sort((a, b) => {
                                if (a.deleted > b.deleted) {
                                    return 1;
                                } else if (a.deleted < b.deleted) {
                                    return -1;
                                }

                                if (
                                    this.platforms[a.platform].name.toLowerCase() <
                                    this.platforms[b.platform].name.toLowerCase()
                                ) {
                                    return -1;
                                } else if (
                                    this.platforms[a.platform].name.toLowerCase() >
                                    this.platforms[b.platform].name.toLowerCase()
                                ) {
                                    return 1;
                                }

                                return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
                            });
                        }

                        this.dataChange$.next(a);
                    },
                    () => (this.working = false),
                );
            },
            () => (this.working = false),
        );
    }

    connect(): Observable<SdkArchitecture[]> {
        return this.dataChange$;
    }

    disconnect() {}
}
