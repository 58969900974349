import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

// todo: fixme when bug is fixed with BrowserDomAdapter not being exported
@Injectable()
export class SeoService {
    constructor(private metaService: Meta, private titleService: Title) {}

    public getTitle(): string {
        return this.titleService.getTitle();
    }

    public setTitle(title: string, hyphenate = true) {
        if (hyphenate) {
            title += ' - Vivox Developer Portal';
        }

        this.titleService.setTitle(title);
    }

    public setMetaDescription(description: string) {
        this.metaService.updateTag({ name: 'description', content: description });
    }
}
