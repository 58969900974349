import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatSnackBar } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-delete-account',
    templateUrl: './delete-account.component.html',
    styleUrls: ['./delete-account.component.scss'],
})
export class DeleteAccountComponent implements OnInit {
    form: FormGroup;

    constructor(
        private fb: FormBuilder,
        private http: HttpClient,
        private snackbar: MatSnackBar,
        public dialogRef: MatDialogRef<DeleteAccountComponent>,
        private translate: TranslateService,
    ) {
        this.createForm();
    }

    createForm() {
        this.form = this.fb.group({
            delete: ['', [Validators.required, Validators.pattern(/^DELETE$/)]],
        });
    }

    ngOnInit() {}

    onSubmit() {
        if (!this.form.valid) {
            return;
        }

        this.http.post('api/v1/user/delete', {}).subscribe(
            () => {
                this.snackbar.open(this.translate.instant('user.deleteAccount.submitted'), null, {
                    duration: 3000,
                });
                this.dialogRef.close();
            },
            () => {
                this.snackbar.open(this.translate.instant('user.deleteAccount.error'), null, {
                    duration: 3000,
                });
                this.dialogRef.close();
            },
        );
    }
}
