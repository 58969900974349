import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { LangService } from '../lang/lang.service';

@Pipe({
    name: 'ago',
    pure: false,
})
export class AgoPipe implements PipeTransform, OnDestroy {
    private subscription: Subscription;
    private lang: string;

    constructor(private langService: LangService) {
        this.subscription = langService.onLanguageChange.pipe(filter(lang => lang !== this.lang)).subscribe(lang => {
            this.lang = lang;
        });
    }

    transform(date: string): string {
        return moment(date)
            .locale(this.langService.getLangCode(this.lang || this.langService.currentLang))
            .fromNow();
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
