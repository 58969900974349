import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
    DateAdapter,
    MAT_DATE_LOCALE,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
} from '@angular/material';
import {
    MAT_MOMENT_DATE_ADAPTER_OPTIONS,
    MatMomentDateModule,
    MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { DigitOnlyModule } from '@uiowa/digit-only';
// import { TrimValueAccessorModule } from 'ng-trim-value-accessor';
import { ChartsModule } from 'ng2-charts';

import { AfterIfDirective } from './after-if.directive';
import { ApiInterceptor } from './api-interceptor';
import { ApiService } from './api.service';
import { AppFormComponent } from './app-form/app-form.component';
import { PaginationComponent } from './app-pagination/pagination.component';
import { AuthService } from './auth.service';
import { CookieService } from './cookie.service';
import { AgreementDataSource } from './datasource/agreement.datasource';
import { AppDataSource } from './datasource/app.datasource';
import { AuditLogDataSource } from './datasource/audit-log.datasource';
import { EnvDataSource } from './datasource/env.datasource';
import { OrgMembersDataSource } from './datasource/org-members.datasource';
import { OrgDataSource } from './datasource/org.datasource';
import { OvertoneArchitectureDataSource } from './datasource/overtone-architecture.datasource';
import { OvertonePlatformVersionDataSource } from './datasource/overtone-platform-version.datasource';
import { OvertonePlatformDataSource } from './datasource/overtone-platform.datasource';
import { SdkArchitectureDataSource } from './datasource/sdk-architecture.datasource';
import { SdkEngineVersionDataSource } from './datasource/sdk-engine-version.datasource';
import { SdkEngineDataSource } from './datasource/sdk-engine.datasource';
import { SdkPlatformVersionDataSource } from './datasource/sdk-platform-version.datasource';
import { SdkPlatformDataSource } from './datasource/sdk-platform.datasource';
import { SdkVersionDataSource } from './datasource/sdk-version.datasource';
import { SdkDataSource } from './datasource/sdk.datasource';
import { StudioDataSource } from './datasource/studio.datasource';
import { UserDataSource } from './datasource/user.datasource';
import { DeleteAccountComponent } from './delete-account/delete-account.component';
import { FileDropComponent } from './file-drop/file-drop.component';
import { FlatpickrComponent } from './flatpickr/flatpickr.component';
import { FormFileDropComponent } from './form-file-drop/form-file-drop.component';
import { IfAllowedDirective } from './if-allowed.directive';
import { InviteComponent } from './invite/invite.component';
import { LangPickerComponent } from './lang/lang-picker.component';
import { LangService } from './lang/lang.service';
import { LogService } from './log.service';
import { MarkdownComponent } from './markdown/markdown.component';
import { MembersComponent } from './members/members.component';
import { OrgFormIndividualComponent } from './org-form/org-form-individual.component';
import { OrgFormComponent } from './org-form/org-form.component';
import { PendingInterceptor } from './pending-interceptor';
import { AgoPipe } from './pipe/ago.pipe';
import { HTML2MarkdownPipe } from './pipe/html2markdown.pipe';
import { MarkdownPipe } from './pipe/markdown.pipe';
import { OTAppContentPipe } from './pipe/ot-app-content.pipe';
import { TargetBlankPipe } from './pipe/target-blank.pipe';
import { SdkDownloadDirective } from './sdk-download/sdk-download.directive';
import { SeoService } from './seo.service';
import { SharedDataService } from './shared-data.service';
import { StudioPickerComponent } from './studio-picker/studio-picker.component';
import { SubmenuDirective } from './submenu.directive';
import { UserChartComponent } from './user-chart/user-chart.component';
import { IsEqualValidatorDirective } from './validator/is-equal.directive';

export function PendingInterceptorFactory(): PendingInterceptor {
    return new PendingInterceptor();
}

export const PendingInterceptorExistingProvider = {
    provide: HTTP_INTERCEPTORS,
    useExisting: PendingInterceptor,
    multi: true,
};

export const PendingInterceptorFactoryProvider = {
    provide: PendingInterceptor,
    useFactory: PendingInterceptorFactory,
};

@NgModule({
    declarations: [
        // app
        AfterIfDirective,
        AgoPipe,
        AppFormComponent,
        FileDropComponent,
        FlatpickrComponent,
        FormFileDropComponent,
        PaginationComponent,
        HTML2MarkdownPipe,
        IfAllowedDirective,
        InviteComponent,
        IsEqualValidatorDirective,
        LangPickerComponent,
        MarkdownComponent,
        MarkdownPipe,
        MembersComponent,
        OTAppContentPipe,

        OrgFormComponent,
        OrgFormIndividualComponent,
        SdkDownloadDirective,
        StudioPickerComponent,
        SubmenuDirective,
        TargetBlankPipe,
        UserChartComponent,
        DeleteAccountComponent,
    ],
    // we export all these so other modules don't have to include them as well
    exports: [
        // angular
        CommonModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,

        // material
        CdkTableModule,
        FlexLayoutModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatMomentDateModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatSelectModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,

        // vendor
        TranslateModule,
        ChartsModule,

        // app
        AfterIfDirective,
        AgoPipe,
        AppFormComponent,
        FileDropComponent,
        FlatpickrComponent,
        FormFileDropComponent,
        PaginationComponent,
        HTML2MarkdownPipe,
        IfAllowedDirective,
        IsEqualValidatorDirective,
        LangPickerComponent,
        OrgFormComponent,
        OrgFormIndividualComponent,
        MarkdownComponent,
        MarkdownPipe,
        MembersComponent,
        OTAppContentPipe,
        SdkDownloadDirective,
        StudioPickerComponent,
        SubmenuDirective,
        TargetBlankPipe,
        UserChartComponent,
    ],
    imports: [
        // angular
        CommonModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,

        // material
        CdkTableModule,
        FlexLayoutModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatSelectModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,

        // vendor
        TranslateModule,
        ChartsModule,
        DigitOnlyModule,
        RouterModule,
        // TrimValueAccessorModule,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApiInterceptor,
            multi: true,
        },
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },
        {
            provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
            useValue: {
                useUtc: true,
            },
        },
        PendingInterceptorExistingProvider,
        PendingInterceptorFactoryProvider,
    ],
    entryComponents: [DeleteAccountComponent, InviteComponent],
})
export class SharedModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: SharedModule,
            providers: [
                // datasources
                AgreementDataSource,
                AppDataSource,
                AuditLogDataSource,
                CookieService,
                EnvDataSource,
                LangService,
                OrgDataSource,
                OrgMembersDataSource,
                OvertoneArchitectureDataSource,
                OvertonePlatformDataSource,
                OvertonePlatformVersionDataSource,
                SdkArchitectureDataSource,
                SdkDataSource,
                SdkEngineDataSource,
                SdkEngineVersionDataSource,
                SdkPlatformDataSource,
                SdkPlatformVersionDataSource,
                SdkVersionDataSource,
                StudioDataSource,
                UserDataSource,

                // services
                ApiService,
                AuthService,
                LogService,
                SeoService,
                SharedDataService,
            ],
        };
    }
}
