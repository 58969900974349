import { Pipe, PipeTransform } from '@angular/core';
import TurndownService from 'turndown';

@Pipe({
    name: 'html2markdown',
})
export class HTML2MarkdownPipe implements PipeTransform {
    constructor() {}

    transform(str: string): string {
        let converter = new TurndownService({
            headingStyle: 'atx',
            codeBlockStyle: 'fenced',
            emDelimiter: '*',
        });
        return converter.turndown(str);
    }
}
