import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AuthService, SeoService, UserLoginRequest } from '../../shared';
import { CheckInviteResult } from '../models';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
    private ngUnsubscribe = new Subject<void>();

    errorLogin = false;
    working = false;
    model: UserLoginRequest = {
        email: '',
        password: '',
    };

    token: string;
    tokenInvalid = false;
    createAccountRedirectModalOpen = false;
    shouldShowCreateAccountButton = !this.router.url.includes('/invite');

    constructor(
        public auth: AuthService,
        private route: ActivatedRoute,
        private router: Router,
        private seo: SeoService,
    ) {
        this.seo.setTitle('Login');
    }

    ngOnInit() {
        this.route.queryParams.pipe(takeUntil(this.ngUnsubscribe)).subscribe((p) => {
            if (p.r) {
                this.auth.loginRedirectURI = p.r;
            }
            if (p.return_to) {
                this.auth.loginReturnURL = p.return_to;
            }
        });

        this.route.data.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: { checkInvite: CheckInviteResult }) => {
            if (data && data.checkInvite) {
                const { token, isError } = data.checkInvite;
                this.token = token;

                if (!isError) {
                    localStorage.setItem('invite_token', this.token);
                } else {
                    this.tokenInvalid = true;
                }
            }
        });
    }

    onSubmit() {
        this.working = true;
        this.errorLogin = false;

        this.auth.login(this.model.email, this.model.password).subscribe(
            () => {},
            (err) => {
                if (err && !err.ok && ![401, 403, 429].includes(err.statusCode || err.status)) {
                    this.router.navigate(['/error']);
                } else if (err.status !== 401) {
                    this.errorLogin = true;
                }

                this.working = false;
            },
        );
    }

    toggleCreateAccountRedirectModal() {
        this.createAccountRedirectModalOpen = !this.createAccountRedirectModalOpen;
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
