import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';

import { AuthService } from '../shared';

@Injectable()
export class UnauthenticatedGuard implements CanActivate, CanActivateChild {
    constructor(private auth: AuthService, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (route.queryParams.r) {
            this.auth.loginRedirectURI = route.queryParams.r;
        }
        if (route.queryParams.return_to) {
            this.auth.loginReturnURL = route.queryParams.return_to;
        }

        if (this.auth.isAuthenticated() && this.auth.user) {
            const isInvite = !!route.url.find((segment) => segment.path === 'invite');
            if (isInvite) {
                this.auth.logout('unauthenticated.guard', false);
                this.router.navigateByUrl(state.url);
            }

            if (!isInvite && !this.auth.redirectIfNecessary()) {
                // only redirect to "/apps" if the auth service isn't already trying to redirect
                this.router.navigate(['/apps']);
            }
        }

        return true;
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.canActivate(route, state);
    }
}
