import { EventEmitter, Injectable, Output } from '@angular/core';

@Injectable()
export class ConfirmDeclineDialogService {
    showing = false;

    @Output()
    visibilityChange: EventEmitter<boolean> = new EventEmitter();

    show() {
        this.showing = true;
        this.visibilityChange.emit(this.showing);
    }

    hide() {
        this.showing = false;
        this.visibilityChange.emit(this.showing);
    }
}
