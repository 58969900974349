import { AfterViewInit, Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
    selector: '[submenu]',
})
export class SubmenuDirective implements AfterViewInit {
    @Input()
    submenu: string;

    private lastActive: string;

    constructor(private ele: ElementRef, private renderer: Renderer2) {}

    hideAll() {
        const sidebars = document.querySelectorAll('.sidebar-sub');
        for (let i in sidebars) {
            if (sidebars[i].classList) {
                sidebars[i].classList.remove('active');
            }
        }

        const triggers = document.querySelectorAll('.submenu-trigger');
        for (let i in triggers) {
            if (triggers[i].classList) {
                triggers[i].classList.remove('active');
            }
        }
    }

    ngAfterViewInit() {
        const menu = document.getElementById(this.submenu);
        const blocker = document.getElementById('sidebar-blocker');
        const backButton = menu.querySelector('.back-button');

        const menuItem = document.querySelectorAll('.menu-item');
        for (let i = 0; i < menuItem.length; i++) {
            menuItem[i].addEventListener('click', (e: any) => {
                const currentActive: string = e.srcElement && e.srcElement.innerText;

                if (this.lastActive === undefined) {
                    this.lastActive = currentActive;
                    return;
                } else if (this.lastActive === currentActive) {
                    return;
                }

                if (this.ele.nativeElement.classList.contains('active')) {
                    this.hideAll();
                    blocker.classList.add('hidden');
                    this.renderer.removeClass(document.body, 'menu-open');
                }

                this.lastActive = currentActive;
            });
        }

        this.ele.nativeElement.onclick = () => {
            const sub = <any>menu.querySelector('.menu');
            const subBox = sub.getBoundingClientRect();
            const windowHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
            let top = windowHeight - subBox.height - 25;

            if (this.ele.nativeElement.offsetTop < top) {
                top = this.ele.nativeElement.offsetTop;
            }

            if (subBox.height + 50 > windowHeight) {
                top = 25;
            }

            if (menu.querySelector('.menu.no-margin') == null) {
                (<any>menu.querySelector('.menu')).style.marginTop = top + 'px';
            }

            if (this.ele.nativeElement.classList.contains('active')) {
                this.hideAll();
                blocker.classList.add('hidden');
                this.renderer.removeClass(document.body, 'menu-open');
                return;
            } else {
                this.hideAll();
                this.ele.nativeElement.classList.add('active');
            }

            if (menu.classList.contains('active')) {
                menu.classList.remove('active');
                blocker.classList.add('hidden');
                this.renderer.removeClass(document.body, 'menu-open');
            } else {
                menu.classList.add('active');
                blocker.classList.remove('hidden');
                this.renderer.addClass(document.body, 'menu-open');
            }
        };

        if (!menu) {
            return;
        }

        menu.onclick = (e: any) => {
            if (e.target.classList.contains('sidebar-sub')) {
                return;
            }

            this.hideAll();
            blocker.classList.add('hidden');
            this.renderer.removeClass(document.body, 'menu-open');
        };

        blocker.onclick = () => {
            this.hideAll();
            blocker.classList.add('hidden');
            this.renderer.removeClass(document.body, 'menu-open');
        };

        if (backButton !== null) {
            backButton.addEventListener('click', () => {
                this.hideAll();
                blocker.classList.add('hidden');
                this.renderer.removeClass(document.body, 'menu-open');
            });
        }
    }
}
