import { DataSource } from '@angular/cdk/collections';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { APIResponse } from '../api';
import { Org } from '../org.model';

@Injectable()
export class OrgDataSource extends DataSource<Org> {
    dataChange$ = new BehaviorSubject<Org[]>([]);
    filter: any = {
        limit: 50,
        page: 1,
        status: 'pending',
        name: '',
    };
    pages: number;
    maxPage = 1000;
    working = false;
    isLastPage = false;

    constructor(private http: HttpClient) {
        super();
    }

    firstPage() {
        this.filter.page = 1;
        this.update();
    }
    prevPage() {
        this.filter.page -= 1;
        if (this.filter.page == 0) {
            this.filter.page = 1;
        }
        this.update();
    }
    nextPage() {
        this.filter.page += 1;
        this.update();
    }
    update() {
        if (this.filter.page >= this.maxPage) {
            this.filter.page = this.maxPage;
            return;
        }

        this.dataChange$.next([]);
        this.working = true;

        const queryString: string[] = [];
        queryString.push(`data=list`);
        queryString.push(`limit=${this.filter.limit || 100}`);
        queryString.push(`offset=${this.filter.page ? (this.filter.page - 1) * (this.filter.limit || 100) : 0}`);
        if (this.filter.status) {
            if (this.filter.status == 'deleted') {
                queryString.push(`deleted=1`);
            } else {
                let status: string;
                // auto-approved isn't a real status for the org model. Set status to 'approved' and
                // push auto-approved=true into the querystring for the API to handle it
                if (this.filter.status == 'auto-approved') {
                    status = 'approved';
                    queryString.push(`auto-approved=1`);
                } else {
                    status = this.filter.status;
                }
                queryString.push(`s=${status}`);
                if (this.filter.status !== 'all') {
                    queryString.push(`deleted=0`);
                }
            }
            if (this.filter.status == 'created') {
                queryString.push(`lookup=true`);
            }
        }
        if (this.filter.name) {
            queryString.push(`name=${this.filter.name}`);
        }
        const queryStringStr = queryString.join('&');
        this.http.get<APIResponse<number>>(`api/v1/organizations?${queryStringStr}&count=true`).subscribe(
            (countRes) => {
                const recordCount = countRes.data ? countRes.data : 0;
                this.http.get<APIResponse<Org[]>>(`api/v1/organizations?${queryStringStr}`).subscribe(
                    (res) => {
                        if (recordCount > 0) {
                            // -1 to handle the page problem when recordCount = this.filter.limit
                            this.pages = Math.floor((recordCount - 1) / this.filter.limit) + 1;
                        } else {
                            this.pages = 1;
                        }
                        this.isLastPage = this.filter.page === this.pages;
                        if (!res.data) {
                            this.dataChange$.next([]);
                            this.working = false;
                            this.isLastPage = true;
                            return;
                        }

                        const orgs = res.data;
                        this.dataChange$.next(orgs);
                        this.working = false;
                    },
                    () => (this.working = false),
                );
            },
            () => (this.working = false),
        );
    }

    connect(): Observable<Org[]> {
        return this.dataChange$;
    }

    disconnect() {}
}
