import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { DigitOnlyModule } from '@uiowa/digit-only';
import { FTUECCPAMailComponent } from 'app/core/ftue/ccpa-mail/ccpa-mail.component';
import { FTUECoBrandComponent } from 'app/core/ftue/co-brand/co-brand.component';

import { SharedModule } from '../shared';

import { AdminGuard } from './admin.guard';
import { AnnouncementDialogComponent } from './announcement/announcement-dialog.component';
import { AnnouncementComponent } from './announcement/announcement.component';
import { AnnouncementService } from './announcement/announcement.service';
import { ApprovedOrgGuard } from './approved-org.guard';
import { AppsCreateComponent } from './apps/create/apps-create.component';
import { AppsDashboardComponent } from './apps/dashboard/apps-dashboard.component';
import { AppsDetailComponent } from './apps/detail/apps-detail.component';
import { AuthenticatedGuard } from './authenticated.guard';
import { ChangeEmailDialogComponent, ChangeEmailService } from './change-email-dialog';
import { ConditionalAccessDirective } from './conditional-access.directive';
import { ConfirmDeclineDialogComponent } from './confirm-decline-dialog/confirm-decline-dialog.component';
import { ConfirmDeclineDialogService } from './confirm-decline-dialog/confirm-decline-dialog.service';
import { CoreComponent } from './core.component';
import { DocsComponent } from './docs/docs.component';
import { EmailVerifyDialogComponent } from './email-verify-dialog/email-verify-dialog.component';
import { EnterpriseOptionsDialogComponent } from './enterprise/enterprise-options-dialog.component';
import { EnterpriseSimpleTiersComponent } from './enterprise/enterprise-simple-tiers/enterprise-simple-tiers.component';
import { EnterpriseComponent } from './enterprise/enterprise.component';
import { ErrorComponent } from './error/error.component';
import { ErrorsInterceptor } from './errors.interceptor';
import { FaqCategoryComponent } from './faq/faq-category.component';
import { FaqItemComponent } from './faq/faq-item.component';
import { FaqSearchBoxComponent } from './faq/faq-search/faq-search-box.component';
import { FaqSearchComponent } from './faq/faq-search/faq-search.component';
import { FaqComponent } from './faq/faq.component';
import { FTUEService } from './ftue/ftue.service';
import { FTUEPostAppExitComponent } from './ftue/post-app/post-app-exit.component';
import { FTUEPostAppComponent } from './ftue/post-app/post-app.component';
import { FTUEPreAppExitComponent } from './ftue/pre-app/pre-app-exit.component';
import { FTUEPreAppSplashComponent } from './ftue/pre-app/pre-app-splash.component';
import { FTUEPreAppComponent } from './ftue/pre-app/pre-app.component';
import { GetVerifiedBlurbComponent } from './get-verified-blurb/get-verified-blurb.component';
import { GetVerifiedDialogComponent } from './get-verified-dialog/get-verified-dialog.component';
import { GuestComponent } from './guest/guest.component';
import { InviteResolver } from './invite.resolver';
import { LicenseComponent } from './license/license.component';
import { LoginForgotComponent } from './login-forgot/login-forgot.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { MaintenanceGuard } from './maintenace.guard';
import { MixedGuard } from './mixed.guard';
import { NeedsApprovedOrgDialogComponent } from './needs-approved-org-dialog/needs-approved-org-dialog.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { OrgMembersComponent } from './org-members/org-members.component';
import { OrgsCreateComponent } from './orgs/create/orgs-create.component';
import { RegisterComponent } from './register/register.component';
import { SdksComponent } from './sdks/sdks.component';
import { SDKsService } from './sdks/sdks.service';
import { SettingsOrgComponent } from './settings/org/settings-org.component';
import { SettingsUserComponent } from './settings/user/settings-user.component';
import { SurveyPopupDialogComponent } from './survey-popup-dialog/survey-popup-dialog.component';
import { UnauthenticatedGuard } from './unauthenticated.guard';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { VerifyEmailComponent } from './verify/verify-email.component';
import { VerifyOrgApprovalComponent } from './verify/verify-org-approval.component';
import { VerifyOrgFlaggedComponent } from './verify/verify-org-flagged.component';
import { VerifyOrgIndividualComponent } from './verify/verify-org-individual.component';
import { VerifyOrgRejectedComponent } from './verify/verify-org-rejected.component';
import { VerifyOrgComponent } from './verify/verify-org.component';
import { MigrationRedirectComponent } from './migration-redirect/migration-redirect.component';

// TODO: core, admin, and admin-overtone should be moved to app.module someday
const routes: Routes = [
    {
        path: '',
        component: CoreComponent,
        children: [
            // lazy modules - make sure to add to src/tsconfig.app.json
            {
                path: 'admin',
                canActivate: [AuthenticatedGuard, AdminGuard],
                loadChildren: '../admin/admin.module#AdminModule',
            },
            // app routes
            {
                path: '',
                component: LoginComponent,
                canActivate: [UnauthenticatedGuard],
            },
            {
                path: 'register-ue4',
                component: LoginComponent,
                canActivate: [MaintenanceGuard],
            },
            {
                path: 'register-v4',
                component: LoginComponent,
                canActivate: [MaintenanceGuard],
            },
            {
                path: 'admin/login',
                component: LoginComponent,
                canActivate: [UnauthenticatedGuard],
            },
            {
                path: 'apps',
                canActivate: [ApprovedOrgGuard],
                children: [
                    {
                        path: '',
                        component: AppsDashboardComponent,
                    },
                    {
                        path: 'detail/:id',
                        component: AppsDetailComponent,
                    },
                    {
                        path: 'edit/:id',
                        component: AppsCreateComponent,
                    },
                    {
                        path: 'create',
                        component: AppsCreateComponent,
                    },
                ],
            },
            {
                path: 'migration/redirect',
                component: MigrationRedirectComponent,
            },
            {
                path: 'guest',
                canActivate: [AuthenticatedGuard],
                component: GuestComponent,
            },
            {
                path: 'invite',
                component: LoginComponent,
                canActivate: [MaintenanceGuard, UnauthenticatedGuard],
                resolve: {
                    checkInvite: InviteResolver,
                },
            },
            {
                path: 'downloads',
                canActivate: [AuthenticatedGuard],
                children: [
                    {
                        path: '',
                        component: SdksComponent,
                    },
                    {
                        path: 'link/:link',
                        component: SdksComponent,
                    },
                ],
            },
            {
                path: 'sdks',
                redirectTo: 'downloads',
                pathMatch: 'full',
            },
            {
                path: 'sdks/link/:link',
                redirectTo: 'downloads/link/:link',
                pathMatch: 'full',
            },
            {
                path: 'docs',
                canActivate: [AuthenticatedGuard],
                component: DocsComponent,
            },
            {
                path: 'settings',
                canActivate: [AuthenticatedGuard],
                children: [
                    {
                        path: 'org',
                        component: SettingsOrgComponent,
                    },
                    {
                        path: 'org/members',
                        component: OrgMembersComponent,
                    },
                    {
                        path: 'user',
                        component: SettingsUserComponent,
                    },
                ],
            },
            {
                path: 'forgot',
                component: LoginForgotComponent,
                canActivate: [MaintenanceGuard, UnauthenticatedGuard],
            },
            {
                path: 'license',
                component: LicenseComponent,
            },
            {
                path: 'announcements',
                component: AnnouncementComponent,
                canActivate: [AuthenticatedGuard],
            },
            {
                path: 'verify',
                children: [
                    {
                        path: 'email',
                        component: VerifyEmailComponent,
                        canActivate: [MixedGuard],
                    },
                    {
                        path: 'enterprise',
                        canActivate: [AuthenticatedGuard],
                        children: [
                            {
                                path: '',
                                component: EnterpriseComponent,
                            },
                        ],
                    },
                    {
                        path: 'org',
                        component: VerifyOrgComponent,
                        canActivate: [AuthenticatedGuard],
                    },
                    {
                        path: 'org-individual',
                        component: VerifyOrgIndividualComponent,
                        canActivate: [AuthenticatedGuard],
                    },
                    {
                        path: 'org-approval',
                        component: VerifyOrgApprovalComponent,
                        canActivate: [AuthenticatedGuard],
                    },
                    {
                        path: 'org-flagged',
                        component: VerifyOrgFlaggedComponent,
                        canActivate: [AuthenticatedGuard],
                    },
                    {
                        path: 'org-rejected',
                        component: VerifyOrgRejectedComponent,
                        canActivate: [AuthenticatedGuard],
                    },
                ],
            },
            {
                path: 'error',
                component: ErrorComponent,
            },
            {
                path: 'unity',
                redirectTo: '',
            },
            {
                path: 'assetstore',
                redirectTo: '',
            },
            {
                path: 'logout',
                component: LogoutComponent,
                canActivate: [AuthenticatedGuard],
            },

            // 404 handling
            {
                path: '**',
                component: NotFoundComponent,
            },
        ],
    },
];

const COMPONENTS = [
    AnnouncementComponent,
    AppsCreateComponent,
    AppsDashboardComponent,
    AppsDetailComponent,
    ConditionalAccessDirective,
    ConfirmDeclineDialogComponent,
    CoreComponent,
    EnterpriseComponent,
    EnterpriseSimpleTiersComponent,
    FaqCategoryComponent,
    FaqItemComponent,
    FaqComponent,
    FaqSearchComponent,
    FaqSearchBoxComponent,
    GetVerifiedBlurbComponent,
    LoginComponent,
    OrgsCreateComponent,
    SdksComponent,
    SettingsOrgComponent,
    SettingsUserComponent,
    VerifyEmailComponent,
    VerifyOrgComponent,
    VerifyOrgApprovalComponent,
    VerifyOrgFlaggedComponent,
    VerifyOrgIndividualComponent,
    VerifyOrgRejectedComponent,
    LoginForgotComponent,
    OrgMembersComponent,
    DocsComponent,
    LicenseComponent,
    NotFoundComponent,
    LogoutComponent,
    ErrorComponent,
    GuestComponent,
    UserProfileComponent,
    MigrationRedirectComponent,
    RegisterComponent,
];

const MODALS = [
    AnnouncementDialogComponent,
    ChangeEmailDialogComponent,
    EmailVerifyDialogComponent,
    EnterpriseOptionsDialogComponent,
    GetVerifiedDialogComponent,
    FTUECoBrandComponent,
    FTUECCPAMailComponent,
    FTUEPostAppExitComponent,
    FTUEPostAppComponent,
    FTUEPreAppExitComponent,
    FTUEPreAppSplashComponent,
    FTUEPreAppComponent,
    NeedsApprovedOrgDialogComponent,
    SurveyPopupDialogComponent,
];

@NgModule({
    declarations: [...COMPONENTS, ...MODALS],
    entryComponents: [...MODALS, RegisterComponent],
    imports: [
        // angular
        BrowserModule,
        DigitOnlyModule,
        RouterModule.forChild(routes),

        // app
        SharedModule,
    ],
    providers: [
        AdminGuard,
        AnnouncementService,
        ApprovedOrgGuard,
        AuthenticatedGuard,
        ConfirmDeclineDialogService,
        ChangeEmailService,
        FTUEService,
        InviteResolver,
        MaintenanceGuard,
        MixedGuard,
        SDKsService,
        UnauthenticatedGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorsInterceptor,
            multi: true,
        },
    ],
})
export class CoreModule {}
