import { AfterContentInit, Directive, EventEmitter, Output } from '@angular/core';

@Directive({ selector: '[afterIf]' })
export class AfterIfDirective implements AfterContentInit {
    @Output('afterIf')
    public afterIf: EventEmitter<AfterIfDirective> = new EventEmitter();

    public ngAfterContentInit(): void {
        setTimeout(() => {
            // timeout helps prevent unexpected change errors
            this.afterIf.next(this);
        });
    }
}
