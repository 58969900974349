import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService, SeoService } from '../../shared';
import { ChangeEmailService } from '../change-email-dialog';

@Component({
    selector: 'core-verify-org-flagged',
    templateUrl: './verify-org-flagged.component.html',
    styleUrls: ['./verify-org-flagged.component.scss'],
})
export class VerifyOrgFlaggedComponent {
    working = true;
    contactFormToggle = false;

    constructor(
        public auth: AuthService,
        public changeEmail: ChangeEmailService,
        private router: Router,
        private seo: SeoService,
    ) {
        if (!this.auth.isAuthenticated()) {
            this.router.navigate(['/']);
        }

        this.working = true;
        this.auth.refreshOrgs().subscribe(() => {
            if (['pending', 'rejected', 'approved', 'created'].includes(this.auth.activeStudio.status)) {
                this.router.navigate(['/']);
                return false;
            }

            this.seo.setTitle('Organization is Being Reviewed');
            this.working = false;
        });
    }
}
