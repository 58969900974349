import { Injectable } from '@angular/core';
import * as Cookies from 'js-cookie';
import * as moment from 'moment';

@Injectable()
export class CookieService {
    OneTrustCookieCategories = {
        strictlyNecessary: 'C0001',
        performance: 'C0002',
        functional: 'C0003',
        targeting: 'C0004',
        socialMedia: 'C0005',
    };

    allowStrictlyNecessaryCookies = true;
    allowPerformanceCookies = false;
    allowFunctionalCookies = false;
    allowTargetingCookies = false;
    allowSocialMediaCookies = false;

    strictlyNecessaryCookies = ['access_token', 'id_token', 'user', 'expires_at'];
    performanceCookies = [];
    functionalCookies = [
        'vivox-app-filter',
        'vivox-env-filter',
        'active_studio',
        'active-app',
        'vivox-date-filter',
        'survey-date',
        'vivox-migration-aborted',
    ];
    targetingCookies = [];
    socialMediaCookies = [];

    private _activeApp: string;
    private _vivoxAppFilter: string;
    private _vivoxEnvFilter: string;
    private _activeStudio: any;
    private _vivoxDateFilter: string;
    private googleScriptRun = false;
    private _surveyDate: Date;

    constructor() {
        this.updateAllowed();

        const that = this;
        window.addEventListener('consent.onetrust', function () {
            that.updateAllowed();
        });
    }

    updateAllowed() {
        const w = window as any;
        if (w.OnetrustActiveGroups) {
            console.log(w.OnetrustActiveGroups);
            const cookieGroups = w.OnetrustActiveGroups.split(',');
            this.allowPerformanceCookies = cookieGroups.includes(this.OneTrustCookieCategories.performance);
            this.allowFunctionalCookies = cookieGroups.includes(this.OneTrustCookieCategories.functional);
            this.allowTargetingCookies = cookieGroups.includes(this.OneTrustCookieCategories.targeting);
            this.allowSocialMediaCookies = cookieGroups.includes(this.OneTrustCookieCategories.socialMedia);
        }

        if (this.allowPerformanceCookies && !this.googleScriptRun) {
            if (!('InstallGoogleScript' in window)) {
                return;
            }

            (window['InstallGoogleScript'] as any)();
            this.googleScriptRun = true;
        }
    }

    get vivoxAppFilter(): string {
        const cookie = 'vivox-app-filter';
        if (this.cookieAllowed(cookie)) {
            return Cookies.get(cookie);
        }
        return this._vivoxAppFilter;
    }

    set vivoxAppFilter(value: string) {
        const cookie = 'vivox-app-filter';
        if (this.cookieAllowed(cookie)) {
            Cookies.set(cookie, value, {
                expires: 365,
                path: '/',
                sameSite: 'strict',
            });
        }
        this._vivoxAppFilter = value;
    }

    removeVivoxAppFilterCookie() {
        Cookies.remove('vivox-app-filter', { sameSite: 'strict' });
    }

    get vivoxEnvFilter(): string {
        const cookie = 'vivox-env-filter';
        if (this.cookieAllowed(cookie)) {
            return Cookies.get(cookie);
        }
        return this._vivoxEnvFilter;
    }

    set vivoxEnvFilter(value: string) {
        const cookie = 'vivox-env-filter';
        if (this.cookieAllowed(cookie)) {
            Cookies.set(cookie, value, {
                expires: 365,
                path: '/',
                sameSite: 'strict',
            });
        }
        this._vivoxEnvFilter = value;
    }

    get activeStudio(): string {
        const cookie = 'active_studio';
        if (this.cookieAllowed(cookie)) {
            return Cookies.get(cookie);
        }
        return this._activeStudio;
    }

    set activeStudio(value: string) {
        const cookie = 'active_studio';
        if (this.cookieAllowed(cookie)) {
            Cookies.set(cookie, value, {
                expires: 365,
                path: '/',
                sameSite: 'strict',
            });
        }
        this._activeStudio = value;
    }

    get activeApp(): string {
        const cookie = 'active-app';
        if (this.cookieAllowed(cookie)) {
            return Cookies.get(cookie);
        }
        return this._activeApp;
    }

    set activeApp(value: string) {
        const cookie = 'active-app';
        if (this.cookieAllowed(cookie)) {
            Cookies.set(cookie, value, {
                expires: 365,
                path: '/',
                sameSite: 'strict',
            });
        }
        this._activeApp = value;
    }

    removeActiveAppCookie() {
        Cookies.remove('active-app', { sameSite: 'strict' });
    }

    get vivoxDateFilter(): string {
        const cookie = 'vivox-date-filter';
        if (this.cookieAllowed(cookie)) {
            return Cookies.get(cookie);
        }
        return this._vivoxDateFilter;
    }

    set vivoxDateFilter(value: string) {
        const cookie = 'vivox-date-filter';
        if (this.cookieAllowed(cookie)) {
            Cookies.set(cookie, value, {
                expires: 365,
                path: '/',
                sameSite: 'strict',
            });
        }
        this._vivoxDateFilter = value;
    }

    get surveyDate(): Date {
        const cookie = 'survey-date';
        if (this.cookieAllowed(cookie)) {
            const res = Cookies.get(cookie);
            if (!res) {
                return new Date(1999, 11, 31);
            }
            return moment.utc(res, 'YYYYMMDD-HHmmss').toDate();
        }
        return this._surveyDate;
    }

    set surveyDate(value: Date) {
        const cookie = 'survey-date';
        if (this.cookieAllowed(cookie)) {
            const writeValue = moment.utc(value).format('YYYYMMDD-HHmmss');
            Cookies.set(cookie, writeValue, {
                expires: 365,
                path: '/',
                sameSite: 'strict',
            });
        }
        this._surveyDate = value;
    }

    get migrationAborted(): boolean {
        const cookie = 'vivox-migration-aborted';
        if (this.cookieAllowed(cookie)) {
            return Cookies.get(cookie) != null;
        }
        return false;
    }

    set migrationAborted(value: boolean) {
        const cookie = 'vivox-migration-aborted';
        if (this.cookieAllowed(cookie)) {
            if (value) {
                Cookies.set(cookie, 'true', {
                    expires: 2,
                    path: '/',
                    sameSite: 'strict',
                });
            } else {
                Cookies.remove(cookie);
            }
        }
    }

    cookieAllowed(cookie: string) {
        if (this.strictlyNecessaryCookies.includes(cookie) && this.allowStrictlyNecessaryCookies) {
            return true;
        }
        if (this.performanceCookies.includes(cookie) && this.allowPerformanceCookies) {
            return true;
        }
        if (this.functionalCookies.includes(cookie) && this.allowFunctionalCookies) {
            return true;
        }
        if (this.targetingCookies.includes(cookie) && this.allowTargetingCookies) {
            return true;
        }
        if (this.socialMediaCookies.includes(cookie) && this.allowSocialMediaCookies) {
            return true;
        }
        return false;
    }
}
