import { DataSource } from '@angular/cdk/collections';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';

import { APIResponse } from '../api';
import { QuickOrg } from '../org.model';

@Injectable()
export class StudioDataSource extends DataSource<QuickOrg> {
    dataChange$ = new BehaviorSubject<QuickOrg[]>([]);
    organizations: QuickOrg[];
    typing = new Subject<string>();
    clearing = new Subject();
    working: boolean;

    constructor(private http: HttpClient) {
        super();

        this.typing
            .pipe(
                filter(text => text.length > 1),
                debounceTime(750),
                distinctUntilChanged(),
                tap(() => (this.working = true)),
                switchMap(text => this.getOrgs(text)),
            )
            .subscribe(r => {
                this.organizations = r && r.data ? r.data : [];
                this.dataChange$.next(this.organizations);
                this.working = false;
            });

        this.clearing
            .pipe(
                debounceTime(750),
                tap(() => (this.working = true)),
                switchMap(() => this.getOrgs('')),
            )
            .subscribe(r => {
                this.organizations = r && r.data ? r.data : [];
                this.dataChange$.next(this.organizations);
                this.working = false;
            });
    }

    updateFilter(newFilter: string) {
        newFilter = newFilter.trim();
        this.typing.next(newFilter);
    }

    resetFilter() {
        this.clearing.next();
    }

    getOrgs(name: string) {
        let q = 'deleted=0&data=picker&status=approved';

        const filter = name && name.length ? name.trim() : '';
        if (filter && filter.length) {
            q += '&name=' + filter;
        }

        return this.http.get<APIResponse<QuickOrg[]>>(`api/v1/organizations?${q}`);
    }

    connect(): Observable<QuickOrg[]> {
        return this.dataChange$;
    }

    disconnect() {}
}
