import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthService } from '../shared';

@Injectable()
export class MaintenanceGuard implements CanActivate, CanActivateChild {
    constructor(private auth: AuthService, private router: Router) {}

    canActivate(): Observable<boolean> {
        return this.auth.updateMaintenance().pipe(
            map(() => {
                if (this.auth.inMaintenance) {
                    this.router.navigate(['/']);
                    return false;
                }
                return true;
            }),
        );
    }

    canActivateChild(): Observable<boolean> {
        return this.canActivate();
    }
}
