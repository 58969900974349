import { ComponentFactoryResolver, ComponentRef, Injectable, ViewContainerRef } from '@angular/core';
import { Observable, of, timer } from 'rxjs';
import { forkJoin } from 'rxjs/observable/forkJoin';
import { first } from 'rxjs/operators';

import { AuthService } from '../../shared';

@Injectable()
export class FTUEService {
    private activeComponent: ComponentRef<any>;
    showing: boolean;

    constructor(private auth: AuthService, private componentFactoryResolver: ComponentFactoryResolver) {}

    externalHide() {
        if (this.activeComponent) {
            this.activeComponent.instance.hide();
        }
    }

    hide(key: string) {
        return new Observable((obs) => {
            if (this.activeComponent) {
                const comRef = this.activeComponent;

                let obs1 = of(true);
                if (key) {
                    obs1 = this.auth.setFTUEShown(key);
                }

                if (this.activeComponent.instance.callback) {
                    this.activeComponent.instance.callback(this.activeComponent.instance.callbackParams);
                }

                const obs2 = timer(250);

                forkJoin(obs1, obs2).subscribe(() => {
                    this.showing = false;
                    comRef.destroy();
                    obs.next();
                    obs.complete();
                });
            } else {
                this.showing = false;
                obs.next();
                obs.complete();
            }
        });
    }

    show(key: string, viewContainer: ViewContainerRef, component: any, exitComponent?: any, callback?: any) {
        this.hide(null)
            .pipe(first())
            .subscribe(() => {
                this.showing = true;

                const factory = this.componentFactoryResolver.resolveComponentFactory(component);
                this.activeComponent = viewContainer.createComponent(factory);
                this.activeComponent.changeDetectorRef.detectChanges();

                // Hides the modal, shows an "exit" modal if it exists
                // If the `isExit` bool is set on the component and this method is called again,
                // the modal will be fully closed.
                this.activeComponent.instance.hide = () => {
                    this.activeComponent.instance.isActive = false;

                    if (exitComponent && !this.activeComponent.instance.isExit) {
                        this.show(key, viewContainer, exitComponent, component);
                    } else {
                        this.hide(key).subscribe(() => {});
                    }
                };

                // Returns the user to the previous modal (if exists), useful for going "back" on an exit modal
                // Fully closes the modal if no exist modal exists.
                this.activeComponent.instance.return = () => {
                    this.activeComponent.instance.isActive = false;

                    if (exitComponent) {
                        this.show(key, viewContainer, exitComponent, component);
                    } else {
                        this.hide(key).subscribe(() => {});
                    }
                };

                // Fully closes the modal regardless of whether there's an exist step
                this.activeComponent.instance.hideForce = () => {
                    this.activeComponent.instance.isActive = false;
                    this.hide(key).subscribe(() => {});
                };

                this.activeComponent.instance.saveDone = () => {
                    return new Observable((obs) => {
                        if (key) {
                            this.auth.setFTUEShown(key).subscribe(() => obs.next());
                        } else {
                            obs.next();
                        }
                    });
                };

                this.activeComponent.instance.goTo = (component: any, exitComponent?: any) => {
                    this.activeComponent.instance.isActive = false;
                    this.show(key, viewContainer, component, exitComponent);
                };

                this.activeComponent.instance.next = () => {
                    if (
                        this.activeComponent.instance.steps > 1 &&
                        this.activeComponent.instance.currentStep < this.activeComponent.instance.steps
                    ) {
                        this.activeComponent.instance.currentStep++;
                        if (this.activeComponent.instance.stepChange) {
                            this.activeComponent.instance.stepChange(this.activeComponent.instance.currentStep);
                        }
                    }
                };

                this.activeComponent.instance.prev = () => {
                    if (this.activeComponent.instance.steps > 1 && this.activeComponent.instance.currentStep > 1) {
                        this.activeComponent.instance.currentStep--;
                        if (this.activeComponent.instance.stepChange) {
                            this.activeComponent.instance.stepChange(this.activeComponent.instance.currentStep);
                        }
                    }
                };

                this.activeComponent.instance.page = (page: number) => {
                    this.activeComponent.instance.currentStep = page;
                    if (this.activeComponent.instance.stepChange) {
                        this.activeComponent.instance.stepChange(this.activeComponent.instance.currentStep);
                    }
                };

                if (callback) {
                    this.activeComponent.instance.callback = callback;
                }
            });
    }
}
