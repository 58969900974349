import { DataSource } from '@angular/cdk/collections';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { ApiService } from '../api.service';
import { AuthService } from '../auth.service';
import { User } from '../user.model';

@Injectable()
export class OrgMembersDataSource extends DataSource<User> {
    dataChange$ = new BehaviorSubject<User[]>([]);
    working = false;

    constructor(public auth: AuthService, private api: ApiService) {
        super();
    }

    update(orgId): Observable<User[]> {
        this.working = true;
        return this.api.getOrgMembers(orgId).pipe(
            tap(r => {
                this.working = false;
                if (r) {
                    r.sort((a, b) => {
                        if (a.blocked && !b.blocked) {
                            return 1;
                        } else if (b.blocked && !a.blocked) {
                            return -1;
                        } else {
                            return a.email.localeCompare(b.email);
                        }
                    });
                }

                this.dataChange$.next(r);
            }),
            catchError(err => {
                this.working = false;
                return throwError(err);
            }),
        );
    }

    connect(): Observable<User[]> {
        return this.dataChange$;
    }

    disconnect() {}
}
