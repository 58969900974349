import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService, Org, SeoService } from '../../../shared';

@Component({
    selector: 'core-orgs-create',
    templateUrl: './orgs-create.component.html',
    styleUrls: ['./orgs-create.component.scss'],
})
export class OrgsCreateComponent {
    org: Org = {
        vivoxPlatform: '',
    };

    constructor(private router: Router, private seo: SeoService, auth: AuthService) {
        this.seo.setTitle('Create an Organization');
        this.org = auth.activeStudio
            ? auth.activeStudio
            : {
                  vivoxPlatform: auth.sdkFromUser(),
              };
    }

    onSave() {
        this.router.navigate(['/verify/org-approval']);
    }
}
