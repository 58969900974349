import { Directive, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { AuthService } from 'app/shared/auth.service';

import { EmailVerifyDialogComponent } from './email-verify-dialog/email-verify-dialog.component';
import { ConditionalAccessOption } from './models';
import { NeedsApprovedOrgDialogComponent } from './needs-approved-org-dialog/needs-approved-org-dialog.component';

@Directive({
    selector: '[conditional-access]',
})
export class ConditionalAccessDirective implements OnInit {
    @Output() conditionalAccessClick: EventEmitter<void> = new EventEmitter();
    @Input() conditionalAccessRouterLink: string;
    @Input() conditions: ConditionalAccessOption[];
    @HostListener('click', ['$event']) onClick(e) {
        if (this.conditions.includes(ConditionalAccessOption.emailIsVerified) && !this.auth.isVerified()) {
            this.dialog.open(EmailVerifyDialogComponent, {
                width: '500px',
            });
        } else if (
            this.conditions.includes(ConditionalAccessOption.hasApprovedOrg) &&
            (!this.auth.activeStudio || this.auth.activeStudio.status != 'approved')
        ) {
            this.dialog.open(NeedsApprovedOrgDialogComponent, {
                width: '500px',
            });
        } else {
            if (this.conditionalAccessRouterLink) {
                this.router.navigate([this.conditionalAccessRouterLink]);
            }
            if (this.conditionalAccessClick.observers.length) {
                this.conditionalAccessClick.emit(e);
            }
        }
    }

    constructor(
        private auth: AuthService,
        private dialog: MatDialog,
        private router: Router,
        private el: ElementRef,
        private renderer: Renderer2,
    ) {}

    ngOnInit(): void {
        this.renderer.setStyle(this.el.nativeElement, 'cursor', 'pointer');
    }
}
