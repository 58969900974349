import { LocalStateName } from '../shared/lang/lang.service';
import { Permission } from './permissions';

export interface ApiResponse<T> {
    statusCode: number;
    data: T;
}

export interface Announcement {
    id: string;
    createdBy: string;
    type: string;
    subject: string;
    body: string;
    lastUpdated: string;
    media: string;
    mediaType: string;
    actionText: string;
    actionURL: string;
    sendAt: string;
}

export interface AnnouncementMediaUploadRequest {
    id: any;
    contentType: string;
    fileName: string;
}

export interface Documentation {
    caption: string;
    captionEn: string;
    captionJa: string;
    captionKr: string;
    captionZh: string;
    id: number;
    link: string;
    linkEn: string;
    linkJa: string;
    linkKr: string;
    linkZh: string;
    sort: number;
    status: string;
    platformPermission: string;
    vivoxSdkPermission: string;
}

export interface Survey {
    enabled: boolean;
    surveyText: string;
    surveyTextEn: string;
    surveyTextJa: string;
    surveyTextKr: string;
    surveyTextZh: string;
    link: string;
    linkEn: string;
    linkJa: string;
    linkKr: string;
    linkZh: string;
    updatedAt: Date;
}

export interface Agreement {
    id: number | null;
    type: string | null;
    subType: string | null;
    agreementEn: string | null;
    vivoxPermission: string | null;
    effectiveDate: string | null;
    deleted: number | null;
}

export interface Maintenance {
    id: number | null;
    mode: number | null;
    startTime: Date | null;
    duration: number | null;
    showCountdown: boolean | null;
    countdownDuration: number | null;
}

export interface PermissionRequest {
    body: string;
    created: Date;
    createdBy: string;
    createdByName: string;
    id: number;
    organizationId: number;
    organizationName: string;
    permission: Permission;
    status: string;
}

export interface Role {
    id: number;
    name: string;
    permissions: string[];
}

export interface OTCustomization {
    backgroundColor: string;
    deleted: boolean;
    headerText: string;
    headerTextColor: string;
    heroImageColor: string;
    id: number;
    image1: string;
    image2: string;
    image3: string;
    image4: string;
    image5: string;
    name: string;
    organizationId: number;
    primaryColor: string;
    secondaryColor: string;
}

export interface OTApiResponse<T> {
    results: T;
}

export interface OTAnnouncementList<T> {
    totalCount: number;
    totalPages: number;
    limit: number;
    page: number;
    orderBy?: {};
    data?: T[];
}

export interface OTAnnouncement {
    id?: number;
    createdAt?: string;
    updatedAt?: string;
    deletedAt?: string;
    type: string;
    media?: string;
    status?: string;
    brand?: string;
    entityId?: string;
    name: string;
    dismissable?: boolean;
    autoDisplay?: boolean;
    hideMedia?: boolean;
    featured?: boolean;
    priority?: number;
    videoUrl?: string;
    localizations?: OTAnnouncementLocalization[];
    localizationMap?: {};
    schedules?: OTAnnouncementSchedule[];
    schedulesDeleted?: number[];
    segments?: OTAnnouncementSegment[];
}

export interface OTAnnouncementLocalization {
    id?: number;
    announcementId?: number;
    lang: string;
    subject: string;
    subjectPreview: string;
    body: string;
    bodyPreview: string;
    actionText?: string;
    actionURL?: string;
    media?: string;
    createdAt?: string;
    updatedAt?: string;
    deletedAt?: string;
}

export interface OTAnnouncementSchedule {
    id?: number;
    announcementId?: number;
    status: string;
    startAt: string;
    endAt: string;
    interval?: string;
    days?: string[];
    pickerIdx?: number;
    segments?: OTAnnouncementSegment[];
    createdAt?: string;
    updatedAt?: string;
    deletedAt?: string;
}

export interface OTAnnouncementSegment {
    id?: number;
    accountType?: number;
    announcementScheduleId?: number;
    platforms?: string[];
    isTester?: boolean;
    isVerified?: boolean;
    isValidated?: boolean;
    accountTypeMin?: number;
    accountTypeMax?: number;
    accountAgeMin?: number;
    accountAgeMax?: number;
    consecutiveLoginsMin?: number;
    consecutiveLoginsMax?: number;
    lastLoginAgeMin?: number;
    lastLoginAgeMax?: number;
    createdAt?: string;
    updatedAt?: string;
    deletedAt?: string;
}

export interface OTUploadTicket {
    uploadUrl?: string;
    url?: string;
    key?: string;
}

export interface JobQueueStatus {
    status: string | null;
    percent: number | null;
    resultPage: number | null;
    result: string | null;
}

export interface CheckInviteResult {
    token?: string;
    email?: string;
    isError: boolean;
}

export interface USPSAddress {
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    zip?: string;
}

export interface USPSAddressResult {
    userAddress: USPSAddress;
    uspsSuggestion: USPSAddress;
    different: boolean;
    error?: string;
}

export interface DomainFilter {
    id: number;
    domain: string;
}

export interface DescriptiveWords {
    id: number;
    word: string;
}

export enum ConditionalAccessOption {
    emailIsVerified,
    hasApprovedOrg,
    notCreatedStateOrg,
}

export const USStates: LocalStateName[] = [
    { State: 'Alabama', Code: 'AL' },
    { State: 'Alaska', Code: 'AK' },
    { State: 'Arizona', Code: 'AZ' },
    { State: 'Arkansas', Code: 'AR' },
    { State: 'California', Code: 'CA' },
    { State: 'Colorado', Code: 'CO' },
    { State: 'Connecticut', Code: 'CT' },
    { State: 'Delaware', Code: 'DE' },
    { State: 'District of Columbia', Code: 'DC' },
    { State: 'Florida', Code: 'FL' },
    { State: 'Georgia', Code: 'GA' },
    { State: 'Hawaii', Code: 'HI' },
    { State: 'Idaho', Code: 'ID' },
    { State: 'Illinois', Code: 'IL' },
    { State: 'Indiana', Code: 'IN' },
    { State: 'Iowa', Code: 'IA' },
    { State: 'Kansas', Code: 'KS' },
    { State: 'Kentucky', Code: 'KY' },
    { State: 'Louisiana', Code: 'LA' },
    { State: 'Maine', Code: 'ME' },
    { State: 'Montana', Code: 'MT' },
    { State: 'Nebraska', Code: 'NE' },
    { State: 'Nevada', Code: 'NV' },
    { State: 'New Hampshire', Code: 'NH' },
    { State: 'New Jersey', Code: 'NJ' },
    { State: 'New Mexico', Code: 'NM' },
    { State: 'New York', Code: 'NY' },
    { State: 'North Carolina', Code: 'NC' },
    { State: 'North Dakota', Code: 'ND' },
    { State: 'Ohio', Code: 'OH' },
    { State: 'Oklahoma', Code: 'OK' },
    { State: 'Oregon', Code: 'OR' },
    { State: 'Maryland', Code: 'MD' },
    { State: 'Massachusetts', Code: 'MA' },
    { State: 'Michigan', Code: 'MI' },
    { State: 'Minnesota', Code: 'MN' },
    { State: 'Mississippi', Code: 'MS' },
    { State: 'Missouri', Code: 'MO' },
    { State: 'Pennsylvania', Code: 'PA' },
    { State: 'Rhode Island', Code: 'RI' },
    { State: 'South Carolina', Code: 'SC' },
    { State: 'South Dakota', Code: 'SD' },
    { State: 'Tennessee', Code: 'TN' },
    { State: 'Texas', Code: 'TX' },
    { State: 'Utah', Code: 'UT' },
    { State: 'Vermont', Code: 'VT' },
    { State: 'Virginia', Code: 'VA' },
    { State: 'Washington', Code: 'WA' },
    { State: 'West Virginia', Code: 'WV' },
    { State: 'Wisconsin', Code: 'WI' },
    { State: 'Wyoming', Code: 'WY' },
];

export const CanadaProvinces: LocalStateName[] = [
    { State: 'Alberta', Code: 'AB' },
    { State: 'British Columbia', Code: 'BC' },
    { State: 'Manitoba', Code: 'MB' },
    { State: 'New Brunswick', Code: 'NB' },
    { State: 'Newfoundland and Labrador ', Code: 'NL' },
    { State: 'Northwest Territories', Code: 'NT' },
    { State: 'Nova Scotia', Code: 'NS' },
    { State: 'Nunavut', Code: 'NU' },
    { State: 'Ontario', Code: 'ON' },
    { State: 'Prince Edward Island', Code: 'PE' },
    { State: 'Quebec', Code: 'QC' },
    { State: 'Saskatchewan', Code: 'SK' },
    { State: 'Yukon ', Code: 'YT' },
];
