import * as showdown from 'showdown';

import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService, SeoService } from '../../shared';

@Component({
    selector: 'core-verify-org-rejected',
    templateUrl: './verify-org-rejected.component.html',
    styleUrls: ['./verify-org-rejected.component.scss'],
})
export class VerifyOrgRejectedComponent {
    working = true;
    rejectionNotes = '';
    contactFormToggle = false;

    constructor(public auth: AuthService, private router: Router, private seo: SeoService) {
        if (!this.auth.isAuthenticated()) {
            this.router.navigate(['/']);
        }

        this.auth.refreshOrgs().subscribe(() => {
            if (['pending', 'approved', 'created'].includes(this.auth.activeStudio.status)) {
                this.router.navigate(['/']);
                return false;
            }

            if (this.auth.activeStudio.rejectionNotes) {
                let converter = new showdown.Converter();
                converter.setFlavor('github');
                converter.setOption('tables', 'true');
                converter.setOption('emojis', 'true');
                this.rejectionNotes = converter.makeHtml(this.auth.activeStudio.rejectionNotes);
            }

            this.seo.setTitle('Organization Rejected');
            this.working = false;
        });
    }
}
