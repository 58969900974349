import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ApiService } from '../shared';

import { LoginComponent } from './login/login.component';
import { CheckInviteResult } from './models';
import { RegisterComponent } from './register/register.component';

@Injectable()
export class InviteResolver implements Resolve<CheckInviteResult> {
    constructor(private api: ApiService, private router: Router) {}

    resolve(route: ActivatedRouteSnapshot) {
        const params = route.queryParams;

        if (params.token) {
            const route = this.router.config[0].children.find(c => c.path === 'invite');
            route.component = params.email ? RegisterComponent : LoginComponent;

            return this.api.checkInvite(params.token).pipe(
                map(() => ({
                    token: params.token,
                    email: params.email,
                    isError: false,
                })),
                catchError(() =>
                    of({
                        token: params.token,
                        email: params.email,
                        isError: true,
                    }),
                ),
            );
        }

        return of(null);
    }
}
