import { Injectable } from '@angular/core';
import { sprintf } from 'sprintf-js';

import { ApiService } from '../api.service';
import { SdkVersionsByEngine } from '../sdk.model';

@Injectable()
export class SdkVersionDataSource {
    versionsByEngine = new Map<number, SdkVersionsByEngine>();
    engineIDs: number[];
    working = false;

    constructor(private api: ApiService) { }

    update() {
        let data = new Map<number, SdkVersionsByEngine>();

        this.working = true;
        this.api.getSdkEngines(true).subscribe(
            engines => {
                let engineIDs: number[] = [];
                engines.forEach(engine => {
                    data.set(engine.id, <SdkVersionsByEngine>{ engine: engine, versions: [] });
                    engineIDs.push(engine.id);
                });

                this.api.getSdkVersions(true).subscribe(
                    versions => {
                        if (versions && versions.length > 0) {
                            versions.sort((a, b) => {
                                const vbeA = data.get(a.engine);
                                const vbeB = data.get(b.engine);
                                if (vbeA.engine.name > vbeB.engine.name) {
                                    return 1;
                                } else if (vbeA.engine.name < vbeB.engine.name) {
                                    return -1;
                                }

                                if (a.deleted > b.deleted) {
                                    return 1;
                                } else if (a.deleted < b.deleted) {
                                    return -1;
                                }

                                const ca = this.comparableVersionNumber(a.name);
                                const cb = this.comparableVersionNumber(b.name);
                                return ca > cb ? -1 : 1;
                            });

                            let first = true;
                            versions.forEach(v => {
                                const vbe = data.get(v.engine);
                                if (first) {
                                    vbe.versions = [];
                                    first = false;
                                }

                                vbe.versions.push(v);
                            });
                        }

                        this.versionsByEngine = data;
                        this.engineIDs = engineIDs;

                        this.working = false;
                    },
                    () => {
                        this.versionsByEngine.clear();
                        this.engineIDs = [];

                        this.working = false;
                    },
                );
            },
            () => (this.working = false),
        );
    }

    comparableVersionNumber(name: string) {
        if (name) {
            const pieces = name.split('.');
            return pieces.map(x => {
                if (isNaN(x as any)) {
                    return sprintf('%7v', x);
                } else {
                    return (+x + 1000000).toString();
                }
            }).join('.');
        }
        return name;
    }
}
