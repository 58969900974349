import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, of } from 'rxjs';
import { mergeMap, takeUntil } from 'rxjs/operators';

import { ApiService, AuthService, SeoService, UserCreateRequest } from '../../shared';
import { CheckInviteResult } from '../models';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit, OnDestroy {
    private ngUnsubscribe = new Subject<void>();

    working = false;
    errorUserExists = false;
    errorUnknown = false;
    checked: false;
    nameTaken = false;
    registrationType = 'v5';

    token: string;
    tokenInvalid = false;

    model: UserCreateRequest = {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        passwordConfirm: '',
        individual: null,
        orgName: '',
        orgSize: '',
    };

    nameStateMatcher = {
        isErrorState: (control: FormControl, form: FormGroupDirective | NgForm): boolean => {
            // Error when invalid control is submitted
            const isSubmitted = form && form.submitted;
            const showErrors = this.nameTaken || (control.invalid && (control.dirty || control.touched || isSubmitted));

            return showErrors;
        },
    };

    sizeStateMatcher = {
        isErrorState: (control: FormControl, form: FormGroupDirective | NgForm): boolean => {
            // Error when invalid control is submitted
            const isSubmitted = form && form.submitted;
            const showErrors =
                (!this.sizeValid() || control.invalid) && (control.dirty || control.touched || isSubmitted);

            return showErrors;
        },
    };

    constructor(
        public auth: AuthService,
        private seo: SeoService,
        private router: Router,
        private route: ActivatedRoute,
        private api: ApiService,
        private translate: TranslateService,
    ) {
        if (this.auth.isAuthenticated()) {
            this.auth.logout('register.component', false);
        }
        this.seo.setTitle('Create an Account');
        if (this.router.url.match(/\/?register-v4\/?/) !== null) {
            this.registrationType = 'v4';
        }
    }

    ngOnInit() {
        this.route.data.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: { checkInvite: CheckInviteResult }) => {
            if (data && data.checkInvite) {
                const { token, isError } = data.checkInvite;
                this.token = token;

                if (!isError) {
                    localStorage.setItem('invite_token', this.token);

                    const { email } = data.checkInvite;
                    if (email) {
                        localStorage.setItem('invite_email', email);
                        this.model.email = email;
                    }
                } else {
                    this.tokenInvalid = true;
                }
            }
        });
    }

    emptyString(value?: string) {
        return !value || value.trim().length == 0;
    }

    missingNames() {
        return this.emptyString(this.model.firstName) || this.emptyString(this.model.lastName);
    }

    sizeValid() {
        const size = this.model.orgSize ? this.model.orgSize : '';

        return size != '' && parseInt(size) != 0;
    }

    onLeaveName() {
        this.nameTaken = false;
    }

    get registerOrgName() {
        return this.model.individual
            ? this.translate.instant('user.register.individualName')
            : this.translate.instant('user.register.name');
    }

    goToLogin(token: string) {
        this.router.navigate(['/']).then(() => this.router.navigate(['/invite'], { queryParams: { token } }));
    }

    private setIndividualOrgData() {
        this.model.orgSize = '1';

        const sub = this.api.createRegisterOrgName(this.model.firstName, this.model.lastName).subscribe(
            (data) => {
                this.model.orgName = data;
                sub.unsubscribe();
            },
            () => sub.unsubscribe(),
        );
    }

    onSubmissionTypeChange() {
        if (this.model.individual) {
            this.setIndividualOrgData();
        } else {
            this.model.orgSize = null;
            this.model.orgName = null;
        }

        // this.updateBizAndTechContact();
    }

    async onSubmit() {
        this.working = true;
        this.errorUserExists = false;
        this.errorUnknown = false;

        if (this.token && !this.tokenInvalid) {
            this.auth
                .register(
                    this.model.email,
                    this.model.password,
                    this.model.firstName,
                    this.model.lastName,
                    '',
                    '',
                    this.token,
                    this.registrationType,
                )
                .subscribe(
                    () => {
                        this.working = false;
                    },
                    (err) => {
                        if (err.code == 'user_exists') {
                            this.errorUserExists = true;
                        } else {
                            this.errorUnknown = true;
                        }

                        this.working = false;
                    },
                );
        } else {
            this.api
                .getOrgName(this.model.orgName)
                .pipe(
                    mergeMap((orgCount) => {
                        if (orgCount > 0) {
                            this.nameTaken = true;
                            return of(false);
                        } else {
                            return this.auth.register(
                                this.model.email,
                                this.model.password,
                                this.model.firstName,
                                this.model.lastName,
                                this.model.orgName,
                                this.model.orgSize,
                                this.token,
                                this.registrationType,
                            );
                        }
                    }),
                )
                .subscribe(
                    () => {
                        this.working = false;
                    },
                    (err) => {
                        if (err.code == 'user_exists') {
                            this.errorUserExists = true;
                        } else {
                            this.errorUnknown = true;
                        }

                        this.working = false;
                    },
                );
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
