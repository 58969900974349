import { DataSource } from '@angular/cdk/collections';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ApiService } from '../api.service';
import { SdkPlatform } from '../sdk.model';

@Injectable()
export class SdkPlatformDataSource extends DataSource<SdkPlatform> {
    working = false;
    dataChange$ = new BehaviorSubject<SdkPlatform[]>([]);

    constructor(private api: ApiService) {
        super();
    }

    update() {
        this.working = true;
        this.api.getSdkPlatforms(true).subscribe(
            a => {
                if (a) {
                    a.sort((a, b) => {
                        if (a.deleted > b.deleted) {
                            return 1;
                        } else if (a.deleted < b.deleted) {
                            return -1;
                        }

                        return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
                    });
                }

                this.working = false;

                this.dataChange$.next(a);
            },
            () => (this.working = false),
        );
    }

    connect(): Observable<SdkPlatform[]> {
        return this.dataChange$;
    }

    disconnect() {}
}
