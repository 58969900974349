import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { AuthService, UpdateUserEmail } from 'app/shared';

@Injectable()
export class ChangeEmailService {
    toggle = false;

    constructor(private auth: AuthService) {}

    get initModel() {
        return {
            email: this.auth.user.email,
            newEmail: '',
            confirmEmail: '',
        } as UpdateUserEmail;
    }

    change(model: UpdateUserEmail) {
        return this.auth.changeEmail(model).pipe(map((res) => res.data));
    }
}
