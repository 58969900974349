export const environment = {
    production: false,
    apiUrl: 'https://vstg-developer-api.vivox.com',
    auth0callback: 'https://vstg-developer.vivox.com/auth',
    otAppContent: 'https://overtone-app-content-dev.s3.us-west-2.amazonaws.com',
    salesforceURL: 'https://cs15.salesforce.com',
    zendeskURL: 'https://vivox-sandbox.zendesk.com',
    auth: {
        expiresIn: 10800, // 180 min
    },
    migrationEndDate: '2023-11-15T00:00:00Z',
};
