import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-confirm-decline-dialog',
    templateUrl: './confirm-decline-dialog.component.html',
    styleUrls: ['./confirm-decline-dialog.component.scss'],
})
export class ConfirmDeclineDialogComponent implements OnInit {
    @Input()
    toggle = false;
    @Input()
    alternateAgreementText: string;
    @Input()
    allowClose = false;
    @Output()
    wantSupport: EventEmitter<any> = new EventEmitter();
    @Output()
    decline: EventEmitter<any> = new EventEmitter();
    @Output()
    goBack: EventEmitter<any> = new EventEmitter();
    @Output()
    toggleStatus: EventEmitter<boolean> = new EventEmitter();

    constructor() {}

    ngOnInit() {}

    reallyYes() {
        this.decline.emit(null);
    }

    showContactForm() {
        this.wantSupport.emit(null);
    }

    closeBg(e: any) {
        if (!this.allowClose) {
            return;
        }
        if (e.target.classList.contains('modal-bg') || e.target.classList.contains('close')) {
            this.close();
        }
    }

    close() {
        this.toggle = false;
        this.toggleStatus.emit(false);
    }

    goBackClose() {
        this.close();
        this.goBack.emit(null);
    }
}
