import { HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SDKsService } from 'app/core/sdks/sdks.service';
import * as moment from 'moment';
import { Subject, combineLatest, of } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';
import * as showdown from 'showdown';

import {
    ApiService,
    App,
    AppEnv,
    AuthService,
    Sdk,
    SdkArchitecture,
    SdkEngine,
    SdkPlatform,
    SdkPlatformVersion,
    SdkVersion,
    SeoService,
} from '../../../shared';
import { CookieService } from '../../../shared/cookie.service';
import { FTUECoBrandComponent } from '../../ftue/co-brand/co-brand.component';
import { FTUEService } from '../../ftue/ftue.service';
import { FTUEPostAppExitComponent } from '../../ftue/post-app/post-app-exit.component';
import { FTUEPostAppComponent } from '../../ftue/post-app/post-app.component';

@Component({
    selector: 'core-apps-detail',
    templateUrl: 'apps-detail.component.html',
    styleUrls: ['apps-detail.component.scss'],
})
export class AppsDetailComponent implements OnDestroy, OnInit {
    submitting = false;

    app: App;
    sdks: Sdk[];
    appToSdks: { [key: number]: Sdk[] } = {};
    engines: { [key: number]: SdkEngine } = {};
    platforms: { [key: number]: SdkPlatform } = {};
    platformVersions: { [key: number]: SdkPlatformVersion } = {};
    platformVersionsArr: SdkPlatformVersion[] = [];
    architectures: { [key: number]: SdkArchitecture } = {};
    sdkVersions: { [key: number]: SdkVersion } = {};
    rejectionNotes = '';
    activeSdk: Sdk;
    sdkDetailsOpen = false;

    environments: { [type: string]: AppEnv[] } = {};
    activeEnvironment: string;

    private ngUnsubscribe = new Subject<void>();
    displayLaunchDate: string;

    constructor(
        private api: ApiService,
        private route: ActivatedRoute,
        private seo: SeoService,
        private translate: TranslateService,
        private ftue: FTUEService,
        private viewContainer: ViewContainerRef,
        private http: HttpClient,
        public auth: AuthService,
        public sdksService: SDKsService,
        private cookieService: CookieService,
    ) {
        this.seo.setTitle('Application Management');
    }

    private filterSdks(sdks: Sdk[]): Sdk[] {
        return sdks.filter((sdk) => {
            const hasTargetOrgs = sdk.targetOrgs && sdk.targetOrgs.length > 0;

            const targetOrgsOk =
                !hasTargetOrgs ||
                (this.auth.activeStudio &&
                    this.auth.activeStudio.id &&
                    sdk.targetOrgs.indexOf(this.auth.activeStudio.id) != -1);

            return targetOrgsOk;
        });
    }

    private ftueCheck() {
        if (this.app.status == '' && !this.auth.seenFTUE('ftue_app')) {
            this.cookieService.activeApp = this.app.name;
            this.onSandboxTutorial(true);
        }
    }

    get isUe4Org() {
        return this.auth.isSDKue4Org();
    }

    onSandboxTutorial(promptExit: boolean) {
        if (promptExit) {
            this.ftue.show('ftue_app', this.viewContainer, FTUEPostAppComponent, FTUEPostAppExitComponent);
        } else {
            this.ftue.show('ftue_app', this.viewContainer, FTUEPostAppComponent);
        }
    }

    ngOnInit() {
        this.route.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe(async (res) => {
            if (!res.id) {
                return;
            }

            this.api
                .getApp(res.id)
                .pipe(
                    takeUntil(this.ngUnsubscribe),
                    switchMap((app) => combineLatest(of(app), this.api.getOrg(app.parentOrganization))),
                )
                .subscribe(([app, org]) => {
                    this.auth.activeStudio = org;

                    this.app = app;
                    this.auth.activeApp = this.app;
                    if (this.app.launchDate && +new Date(this.app.launchDate) > 0) {
                        this.displayLaunchDate = moment.utc(this.app.launchDate).format('MM/DD/YYYY');
                    } else {
                        this.displayLaunchDate = null;
                    }
                    this.ftueCheck();

                    if (app.rejectionNotes && app.status == 'rejected') {
                        let converter = new showdown.Converter();
                        converter.setFlavor('github');
                        converter.setOption('tables', 'true');
                        converter.setOption('emojis', 'true');
                        this.rejectionNotes = converter.makeHtml(app.rejectionNotes);
                    }

                    this.api.getSdks({ org: this.auth.activeStudio && this.auth.activeStudio.id }).subscribe((sdks) => {
                        this.sdks = this.filterSdks(sdks);

                        if (!this.app) {
                            return;
                        }

                        if (this.app.environments) {
                            this.app.environments.forEach((env) => {
                                if (!this.environments[env.environmentData.type]) {
                                    this.environments[env.environmentData.type] = [];
                                }

                                this.environments[env.environmentData.type].push(env);
                            });
                        }

                        if (this.environments['live']) {
                            this.activeEnvironment = 'live';
                        } else if (this.environments['dedicated-live']) {
                            this.activeEnvironment = 'dedicated-live';
                        } else if (this.environments['test']) {
                            this.activeEnvironment = 'test';
                        } else if (this.environments['dedicated-test']) {
                            this.activeEnvironment = 'dedicated-test';
                        }

                        this.seo.setTitle(this.app.name + ' - Application Management');

                        this.api.getSdkPlatforms().subscribe((platforms) => {
                            const res = {};
                            platforms.forEach((platform) => {
                                res[platform.id] = platform;

                                if (this.sdks) {
                                    this.appToSdks[platform.id] = this.sdks.filter(
                                        (s) =>
                                            s.engine == this.app.engine &&
                                            s.platform == platform.id &&
                                            s.status == 'live',
                                    );
                                }
                            });

                            this.platforms = res;
                        });
                    });
                });

            this.api
                .getSdkEngines()
                .pipe(
                    takeUntil(this.ngUnsubscribe),
                    map((r) => {
                        const res = {};
                        r.forEach((e) => (res[e.id] = e));

                        return res;
                    }),
                )
                .subscribe((r) => {
                    this.engines = r;
                });

            this.api
                .getSdkPlatformVersions()
                .pipe(
                    takeUntil(this.ngUnsubscribe),
                    map((r) => {
                        const res = {};
                        r.forEach((e) => (res[e.id] = e));
                        this.platformVersionsArr = r;

                        return res;
                    }),
                )
                .subscribe((r) => {
                    this.platformVersions = r;
                });

            this.api
                .getSdkArchitectures()
                .pipe(
                    takeUntil(this.ngUnsubscribe),
                    map((r) => {
                        const res = {};
                        r.forEach((e) => (res[e.id] = e));

                        return res;
                    }),
                )
                .subscribe((r) => {
                    this.architectures = r;
                });

            this.api
                .getSdkVersions()
                .pipe(
                    takeUntil(this.ngUnsubscribe),
                    map((r) => {
                        const res = {};
                        if (r && r.length > 0) {
                            r.forEach((e) => (res[e.id] = e));
                        }

                        return res;
                    }),
                )
                .subscribe((r) => {
                    this.sdkVersions = r;
                });
        });
    }

    async uploadImage(file, prop: string) {
        if (!file) {
            return;
        }

        const url = await this.api
            .createAppUpload(this.app, { id: this.app.id, contentType: file.type, fileName: file.name })
            .toPromise();

        let headers = new HttpHeaders();
        headers = headers.set('x-amz-acl', 'public-read');

        const req = new HttpRequest('PUT', url, file, {
            reportProgress: true,
            headers: headers,
            responseType: 'text',
        });

        await this.http
            .request(req)
            .pipe(filter((e) => e instanceof HttpResponse))
            .toPromise();

        this.app[prop] = url.split('?')[0];
    }

    cobrandCheck(app: App) {
        this.cookieService.activeApp = app.name;
        this.ftue.show(null, this.viewContainer, FTUECoBrandComponent, null, (params) => {
            if (params.optOut || params.cobrandScreenshot) {
                this.onSubmitApp(app, params);
            }
        });
    }

    async onSubmitApp(app: App, params) {
        this.submitting = true;

        if (params.cobrandScreenshot) {
            await this.uploadImage(params.cobrandScreenshot, 'applicationCobra');
        }

        this.auth.activeStudio.billingAddress = params.billingAddress;
        if (params.pcu) {
            app.pcu = params.pcu;
        }
        if (params.launchDate) {
            app.launchDate = params.launchDate;
            this.displayLaunchDate = moment.utc(this.app.launchDate).format('MM/DD/YYYY');
        }

        await this.api.saveOrg(this.auth.activeStudio).toPromise();
        await this.api.setAppPending(app).toPromise();

        this.submitting = false;
    }

    toggleEnvironment(env: string) {
        if (!this.environments[env]) {
            return;
        }

        this.activeEnvironment = env;
    }

    regenerateCredentials(env: AppEnv) {
        let dialog: any = this.translate.instant('user.apps.detail.regenerateCredentialsConfirm');
        let conf = window.confirm(dialog);

        if (conf) {
            this.api
                .regenerateAppCredentials(this.app, env)
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe((r) => {
                    env.adminPassword = r;
                });
        }
    }

    regenerateKey(env: AppEnv) {
        if (env.keys.length !== 1) {
            return;
        }

        let dialog: any = this.translate.instant('user.apps.detail.regenerateKeyConfirm');
        let conf = window.confirm(dialog);

        if (conf) {
            this.api
                .regenerateAppKey(this.app, env)
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe((r) => {
                    env.keys.unshift(r);
                });
        }
    }

    revokeKey(env: AppEnv) {
        if (env.keys.length <= 1) {
            return;
        }

        let dialog: any = this.translate.instant('user.apps.detail.revokeKeyConfirm');
        let conf = window.prompt(dialog);

        if (conf === 'CONFIRM') {
            this.api
                .revokeAppKey(this.app, env)
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(() => env.keys.splice(-1, 1));
        }
    }

    closeBg(e: any) {
        if (e.target.classList.contains('modal-bg') || e.target.classList.contains('close')) {
            this.close();
        }
    }

    close() {
        this.sdkDetailsOpen = false;
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
