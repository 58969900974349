import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { getNames, registerLocale } from 'i18n-iso-countries';
import { Subject } from 'rxjs';

export type LocalCountryName = {
    Code: string;
    Country: string;
};

export type LocalStateName = {
    Code: string;
    State: string;
};

@Injectable()
export class LangService {
    onLanguageChange = new Subject<string>();

    supportedLanguages = {
        en: 'English',
        kr: '한국어',
        ja: '日本語',
        zh: '中文',
    };

    constructor(public translate: TranslateService) {
        registerLocale(require('i18n-iso-countries/langs/en.json'));
        registerLocale(require('i18n-iso-countries/langs/ja.json'));
        registerLocale(require('i18n-iso-countries/langs/ko.json'));
        registerLocale(require('i18n-iso-countries/langs/zh.json'));
    }

    get currentLang() {
        return this.translate.currentLang;
    }

    set currentLang(lang) {
        if (!this.supportedLanguages[lang]) {
            return;
        }

        try {
            localStorage.setItem('lang', lang);
        } catch (e) {
            console.log('localStorage not available');
            console.log(e);
        }

        this.translate.use(lang);
        this.onLanguageChange.next(lang);

        if (!('LoadOTBanner' in window)) {
            return;
        }

        (window['LoadOTBanner'] as any)(lang);
    }

    getLangs() {
        return Object.keys(this.supportedLanguages);
    }

    load() {
        let browserLang = null;
        const storedLang = localStorage.getItem('lang');

        if (navigator && navigator.language) {
            browserLang = navigator.language.substring(0, 2);
        }

        // use stored, then detected, then the url portion, then English
        let lang = 'en';
        if (storedLang) {
            lang = storedLang;
        } else if (browserLang) {
            lang = browserLang;
        }

        if (!this.supportedLanguages[lang]) {
            lang = 'en';
        }

        // finally, set the base locale for the user
        this.translate.use(lang);
        this.translate.setDefaultLang('en');

        if (!('LoadOTBanner' in window)) {
            return;
        }

        (window['LoadOTBanner'] as any)(lang);
    }

    getLangCode(lang: string) {
        const languageMap = new Map([
            ['en', 'en-us'],
            ['kr', 'ko'],
            ['ja', 'ja'],
            ['zh', 'zh'],
        ]);
        let language = 'en-us';
        if (languageMap.has(lang)) {
            language = languageMap.get(lang);
        }
        return language;
    }

    getCountries(forceLang?: string): LocalCountryName[] {
        let lang = this.currentLang;
        if (forceLang) {
            lang = forceLang;
        }
        if (lang == 'kr') {
            lang = 'ko';
        }
        const countries = getNames(lang);

        let result = [];

        for (let code in countries) {
            let country = {
                Code: code,
                Country: countries[code],
            };

            result.push(country);
        }

        return result.sort((a, b) => {
            if (a.Country < b.Country) {
                return -1;
            }
            if (b.Country < a.Country) {
                return 1;
            }
            return 0;
        });
    }
}
