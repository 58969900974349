import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
    selector: 'app-faq-search-box',
    templateUrl: './faq-search-box.component.html',
    styleUrls: ['./faq-search-box.component.scss'],
})
export class FaqSearchBoxComponent implements OnInit {
    @Input('query') query = null;

    search: FormGroup;

    constructor(private fb: FormBuilder, private router: Router) {}

    onSearch() {
        this.router.navigate(['/faqs/search'], { queryParams: { query: this.search.get('query').value } });
    }

    ngOnInit() {
        this.search = this.fb.group({
            query: [this.query, Validators.required],
        });
    }
}
