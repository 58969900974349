import { DataSource } from '@angular/cdk/collections';
import { Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';
import * as moment from 'moment';
import { BehaviorSubject, Observable, forkJoin } from 'rxjs';
import { sprintf } from 'sprintf-js';

import { ApiService } from '../api.service';
import {
    Sdk,
    SdkArchitecture,
    SdkEngine,
    SdkEngineVersion,
    SdkPlatform,
    SdkPlatformVersion,
    SdkVersion,
} from '../sdk.model';

@Injectable()
export class SdkDataSource extends DataSource<Sdk> {
    working = false;
    dataChange$ = new BehaviorSubject<Sdk[]>([]);
    sort: Sort = { direction: 'asc', active: 'sdkVersion' };
    filter = {
        engine: null,
        platform: null,
    };

    engines: { [key: number]: SdkEngine } = {};
    enginesRaw: SdkEngine[] = [];
    engineVersions: { [key: number]: SdkEngineVersion } = {};
    sdkVersions: { [key: number]: SdkVersion } = {};
    platforms: { [key: number]: SdkPlatform } = {};
    platformsRaw: SdkPlatform[] = [];
    platformVersions: { [key: number]: SdkPlatformVersion } = {};
    architectures: { [key: number]: SdkArchitecture } = {};

    constructor(private api: ApiService) {
        super();
    }

    update() {
        let statusOrder = {
            pending: 1,
            live: 2,
            archived: 3,
            deleted: 4,
        };

        let arr: Observable<any>[] = [
            this.api.getSdks(),
            this.api.getSdkVersions(true),
            this.api.getSdkEngines(true),
            this.api.getSdkEngineVersions(true),
            this.api.getSdkPlatforms(true),
            this.api.getSdkPlatformVersions(true),
            this.api.getSdkArchitectures(true),
        ];

        // TODO: probably move this to API eventually
        this.working = true;
        forkJoin(...arr).subscribe(
            r => {
                const sdks = r[0].filter(row => {
                    if (this.filter.engine && row.engine !== this.filter.engine) {
                        return false;
                    }

                    if (this.filter.platform && row.platform !== this.filter.platform) {
                        return false;
                    }

                    return true;
                });

                const sdkVersions = r[1];
                const sdkEngines = r[2];
                const sdkEngineVersions = r[3];
                const sdkPlatforms = r[4];
                const sdkPlatformVersions = r[5];
                const sdkArchitectures = r[6];

                this.enginesRaw = sdkEngines;
                let temp = {};
                if (sdkEngines && sdkEngines.length > 0) {
                    sdkEngines.forEach(e => (temp[e.id] = e));
                }
                this.engines = temp;

                temp = {};
                if (sdkEngineVersions && sdkEngineVersions.length > 0) {
                    sdkEngineVersions.forEach(e => (temp[e.id] = e));
                }
                this.engineVersions = temp;

                temp = {};
                if (sdkVersions && sdkVersions.length > 0) {
                    sdkVersions.forEach(e => (temp[e.id] = e));
                }
                this.sdkVersions = temp;

                this.platformsRaw = sdkPlatforms;
                temp = {};
                if (sdkPlatforms && sdkPlatforms.length > 0) {
                    sdkPlatforms.forEach(e => (temp[e.id] = e));
                }
                this.platforms = temp;

                temp = {};
                if (sdkPlatformVersions && sdkPlatformVersions.length > 0) {
                    sdkPlatformVersions.forEach(e => (temp[e.id] = e));
                }
                this.platformVersions = temp;

                temp = {};
                if (sdkArchitectures && sdkArchitectures.length > 0) {
                    sdkArchitectures.forEach(e => (temp[e.id] = e));
                }
                this.architectures = temp;

                sdks.sort((a, b) => {
                    if (statusOrder[a.status] < statusOrder[b.status]) {
                        return -1;
                    } else if (statusOrder[a.status] > statusOrder[b.status]) {
                        return 1;
                    }

                    let propertyA: number | string = '';
                    let propertyB: number | string = '';

                    switch (this.sort.active) {
                        case 'name':
                            [propertyA, propertyB] = [a.name, b.name];
                            break;
                        case 'engine':
                            const ea = this.engines[a.engine];
                            const eb = this.engines[b.engine];
                            const eva = this.engines[a.engineVersion];
                            const evb = this.engines[b.engineVersion];

                            let res = 0;
                            if (ea && eb) {
                                res = ea.name < eb.name ? -1 : ea.name > eb.name ? 1 : 0;
                                if (res == 0) {
                                    if (eva && evb) {
                                        res =
                                            this.comparableVersionNumber(eva.name) <
                                                this.comparableVersionNumber(evb.name)
                                                ? -1
                                                : 1;
                                    } else if (eva) {
                                        res = 1;
                                    } else if (evb) {
                                        res = -1;
                                    }
                                }
                            } else if (ea) {
                                res = 1;
                            } else if (eb) {
                                res = -1;
                            }

                            return res * (this.sort.direction == 'asc' ? 1 : -1);
                        case 'minVersion':
                            return this.comparePlatformVersions(a.minPlatformVersion, b.minPlatformVersion);
                        case 'platformVersion':
                            return this.comparePlatformVersions(a.platformVersion, b.platformVersion);
                        case 'permissions':
                            const av = this.sortablePermissions(a.permissions, a.targetOrgs);
                            const bv = this.sortablePermissions(b.permissions, b.targetOrgs);
                            return av.localeCompare(bv) * (this.sort.direction == 'asc' ? 1 : -1);
                        case 'uploaded':
                            return (
                                (moment(a.uploaded).isBefore(moment(b.uploaded)) ? 1 : -1) *
                                (this.sort.direction == 'asc' ? 1 : -1)
                            );
                        default:
                            const va = this.sdkVersions[a.sdkVersion];
                            const vb = this.sdkVersions[b.sdkVersion];

                            let result = 0;
                            if (va && vb) {
                                result =
                                    this.comparableVersionNumber(va.name) < this.comparableVersionNumber(vb.name)
                                        ? -1
                                        : 1;
                            } else if (va) {
                                result = 1;
                            } else if (vb) {
                                result = -1;
                            } else {
                                result = 0;
                            }

                            return result * (this.sort.direction == 'asc' ? -1 : 1);
                    }

                    let valueA = isNaN(+propertyA) ? propertyA : +propertyA;
                    let valueB = isNaN(+propertyB) ? propertyB : +propertyB;

                    return (valueA < valueB ? -1 : 1) * (this.sort.direction == 'asc' ? 1 : -1);
                });

                this.working = false;

                this.dataChange$.next(sdks);
            },
            () => (this.working = false),
        );
    }

    comparePlatformVersions(versionA: number, versionB: number) {
        const va = this.platformVersions[versionA];
        const vb = this.platformVersions[versionB];

        let res = 0;
        if (va && vb) {
            res = this.comparableVersionNumber(va.name) < this.comparableVersionNumber(vb.name) ? -1 : 1;
        } else if (va) {
            res = 1;
        } else if (vb) {
            res = -1;
        } else {
            res = 0;
        }

        return res * (this.sort.direction == 'asc' ? -1 : 1);
    }

    comparableVersionNumber(name: string) {
        name = name.trim();

        const re = new RegExp(/[0-9]+(\.[0-9a-zA-Z]+)*$/);
        if (re.test(name)) {
            const pieces = name.split('.');
            return pieces.map(x => {
                if (isNaN(x as any)) {
                    return sprintf('%7v', x);
                } else {
                    return (+x + 1000000).toString();
                }
            }).join('.');
        }

        return name;
    }

    sortablePermissions(permissions: string[], targetOrgs: number[]): string {
        if (!permissions || permissions.length == 0) {
            return '';
        }
        if (targetOrgs.length != 0) {
            // ignore sdk and platform permissions if it has orgs
            return 'Orgs';
        }

        const sortedPermissions = permissions.sort((a, b) => {
            if (a.startsWith('sdk.') && b.startsWith('platform.')) {
                return -1;
            } else if (b.startsWith('sdk.') && a.startsWith('platform.')) {
                return 1;
            }
            if (a.startsWith('platform.')) {
                return a.localeCompare(b);
            }
            return -a.localeCompare(b);
        });

        const sdks = sortedPermissions.filter(x => x.startsWith('sdk.')).map(y => y.replace('sdk.', ''));
        const platforms = sortedPermissions.filter(x => x.startsWith('platform.')).map(y => y.replace('platform.', ''));

        let result = '';
        if (sdks.length) {
            result += sdks.join(', ');
        }
        if (sdks.length && platforms.length) {
            result += ', ';
        }
        if (platforms.length) {
            result += platforms.join(', ');
        }

        return result;
    }

    connect(): Observable<Sdk[]> {
        return this.dataChange$;
    }

    disconnect() { }
}
