import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { AuthService, LogService } from '../shared';

@Injectable()
export class AuthenticatedGuard implements CanActivate, CanActivateChild {
    constructor(private auth: AuthService, private router: Router, private log: LogService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        if (route.queryParams.r) {
            this.auth.loginRedirectURI = route.queryParams.r;
        }
        if (route.queryParams.return_to) {
            this.auth.loginReturnURL = route.queryParams.return_to;
        }

        if (!this.auth.isAuthenticated() || !this.auth.user) {
            this.log.warn(this, 'User not authenticated');

            this.auth.loginReturnPath = state.url;
            this.router.navigate(['/']);
            return of(false);
        }

        if (this.auth.isZendeskSSOReq()) {
            // zendesk SSO request, do not load app details
            this.log.info(this, 'Zendesk SSO request');
            return of(false);
        }

        return this.auth.refreshOrgs().pipe(
            mergeMap(() => {
                return of(true);
            }),
        );
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.canActivate(route, state);
    }
}
