import { Injectable } from '@angular/core';
import { AuthService, SdkPlatform } from 'app/shared';

import { Permission } from '../permissions';

const ps4RegEx = /playstation 4/i;
const ps5RegEx = /playstation 5/i;
const xboxOneRegEx = /xbox one/i;
const xboxXSRegEx = /xbox series x/i;
const nintendoRegEx = /nintendo/i;
const webkitRegEx = /browser-/i;
const stadiaRegEx = /stadia/i;
@Injectable()
export class SDKsService {
    constructor(private auth: AuthService) {}

    getMissingPermission(platform: SdkPlatform): Permission {
        if (platform.name.match(ps4RegEx) && !this.auth.hasPermission(Permission.PlatformPS4)) {
            return Permission.PlatformPS4;
        }
        if (platform.name.match(ps5RegEx) && !this.auth.hasPermission(Permission.PlatformPS5)) {
            return Permission.PlatformPS5;
        }
        if (platform.name.match(xboxOneRegEx) && !this.auth.hasPermission(Permission.PlatformXboxOne)) {
            return Permission.PlatformXboxOne;
        }
        if (platform.name.match(xboxXSRegEx) && !this.auth.hasPermission(Permission.PlatformXboxSXS)) {
            return Permission.PlatformXboxSXS;
        }
        if (platform.name.match(nintendoRegEx) && !this.auth.hasPermission(Permission.PlatformNintendo)) {
            return Permission.PlatformNintendo;
        }
        if (platform.name.match(webkitRegEx) && !this.auth.hasPermission(Permission.PlatformWebkit)) {
            return Permission.PlatformWebkit;
        }
        if (platform.name.match(stadiaRegEx) && !this.auth.hasPermission(Permission.PlatformStadia)) {
            return Permission.PlatformStadia;
        }
        return Permission.Blank;
    }

    isAllowed(platform: SdkPlatform, common = false): boolean {
        if (platform.name.match(ps4RegEx)) {
            return this.auth.hasPermission(Permission.PlatformPS4, null, common);
        } else if (platform.name.match(ps5RegEx)) {
            return this.auth.hasPermission(Permission.PlatformPS5, null, common);
        } else if (platform.name.match(xboxOneRegEx)) {
            return this.auth.hasPermission(Permission.PlatformXboxOne, null, common);
        } else if (platform.name.match(xboxXSRegEx)) {
            return this.auth.hasPermission(Permission.PlatformXboxSXS, null, common);
        } else if (platform.name.match(nintendoRegEx)) {
            return this.auth.hasPermission(Permission.PlatformNintendo, null, common);
        } else if (platform.name.match(webkitRegEx)) {
            return this.auth.hasPermission(Permission.PlatformWebkit, null, common);
        } else if (platform.name.match(stadiaRegEx)) {
            return this.auth.hasPermission(Permission.PlatformStadia, null, common);
        }
        return true;
    }

    isPending(platform: SdkPlatform): boolean {
        if (platform.name.match(ps4RegEx)) {
            return this.auth.hasPendingPermission(Permission.PlatformPS4);
        }
        if (platform.name.match(ps5RegEx)) {
            return this.auth.hasPendingPermission(Permission.PlatformPS5);
        }
        if (platform.name.match(xboxOneRegEx)) {
            return this.auth.hasPendingPermission(Permission.PlatformXboxOne);
        }
        if (platform.name.match(xboxXSRegEx)) {
            return this.auth.hasPendingPermission(Permission.PlatformXboxSXS);
        }
        if (platform.name.match(nintendoRegEx)) {
            return this.auth.hasPendingPermission(Permission.PlatformNintendo);
        }
        if (platform.name.match(webkitRegEx)) {
            return this.auth.hasPendingPermission(Permission.PlatformWebkit);
        }
        if (platform.name.match(stadiaRegEx)) {
            return this.auth.hasPendingPermission(Permission.PlatformStadia);
        }
        return false;
    }
}
