import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import flatpickr from 'flatpickr';

import * as moment from 'moment';

@Component({
    selector: 'shared-flatpickr',
    template: `
        <mat-form-field>
            <input matInput [placeholder]="placeholder" [value]="value" type="text" #datetime />
        </mat-form-field>
    `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: FlatpickrComponent, // forwardRef(() => FlatpickrComponent),
            multi: true,
        },
    ],
})
export class FlatpickrComponent implements AfterViewInit, ControlValueAccessor {
    // @ts-ignore: fp is declared but never read
    private fp: flatpickr.Instance;
    private onChange: any;

    @Input() placeholder: string;
    @Input() minDate: string;
    @Input() maxDate: string;

    @ViewChild('datetime') datetime: ElementRef;

    value: any;

    ngAfterViewInit() {
        // todo: if this component gets reused the options should probably be passed in
        let opts: any = {
            enableTime: true,
            dateFormat: 'Y-m-d H:i',
        };

        if (this.minDate) {
            opts = {
                ...opts,
                minDate: this.minDate,
            };
        }

        if (this.maxDate) {
            opts = {
                ...opts,
                maxDate: this.maxDate,
            };
        }

        opts.onChange = (val: moment.MomentInput[]) => {
            if (!this.onChange) {
                return;
            }

            if (!val || !val[0]) {
                return;
            }

            this.onChange(
                moment(val[0])
                    .utc()
                    .format(),
            );
        };

        this.fp = flatpickr(this.datetime.nativeElement, opts)[0];
    }

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched() {}

    writeValue(value: any) {
        this.value = value;
    }
}
