import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';

import { APIResponse, Announcement, AnnouncementType } from '../../shared';

import { AnnouncementDialogComponent } from './announcement-dialog.component';

@Injectable()
export class AnnouncementService {
    public maintenance: Announcement[] = [];
    public maintenanceLevel = 1;
    public features: Announcement[] = [];

    constructor(private http: HttpClient, public dialog: MatDialog) {}

    check() {
        this.http.get<APIResponse<Announcement[]>>(`api/v1/announcement/check`).subscribe(res => {
            this.features = [];
            this.maintenance = [];
            this.maintenanceLevel = 1;

            if (res && res.data) {
                res.data.forEach(a => {
                    if (a.type === AnnouncementType.MAINTENANCE) {
                        this.maintenance.push(a);
                    } else if (a.type === AnnouncementType.EMERGENCY) {
                        this.maintenanceLevel = 2;
                        this.maintenance.push(a);
                    } else {
                        this.features.push(a);
                    }
                });
            }

            this.features = this.features.sort((a, b) => (a.id < b.id ? 1 : -1));
            this.maintenance = this.maintenance.sort((a, b) => (a.id < b.id ? 1 : -1));

            if (this.features.length > 0) {
                this.render();
            }
        });
    }

    render() {
        if (this.features.length === 0) {
            return;
        }

        let dialogRef = this.dialog.open(AnnouncementDialogComponent, {
            width: '800px',
            maxHeight: '75vh',
            data: {
                announcement: this.features[0],
            },
        });

        dialogRef.afterClosed().subscribe(() => {
            this.http.post(`api/v1/announcements/${this.features[0].id}/read`, {}).subscribe(() => {
                this.features.shift();
                this.render();
            });
        });
    }
}
