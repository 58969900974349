import { DataSource } from '@angular/cdk/collections';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ApiService } from '../api.service';
import { SdkPlatform, SdkPlatformVersion } from '../sdk.model';

@Injectable()
export class SdkPlatformVersionDataSource extends DataSource<SdkPlatformVersion> {
    working = false;
    dataChange$ = new BehaviorSubject<SdkPlatformVersion[]>([]);

    versionsByPlatform: { [key: number]: SdkPlatformVersion[] } = {};
    platforms: { [key: number]: SdkPlatform } = {};

    constructor(private api: ApiService) {
        super();
    }

    update() {
        this.versionsByPlatform = {};

        this.working = true;

        this.api.getSdkPlatforms(true).subscribe(
            p => {
                p.forEach(platform => {
                    this.platforms[platform.id] = platform;
                });

                this.api.getSdkPlatformVersions(true).subscribe(
                    a => {
                        if (a && a.length > 0) {
                            a.sort((a, b) => {
                                if (a.deleted > b.deleted) {
                                    return 1;
                                } else if (a.deleted < b.deleted) {
                                    return -1;
                                }

                                return this.platforms[a.platform].name.toLowerCase() <
                                    this.platforms[b.platform].name.toLowerCase()
                                    ? -1
                                    : 1;
                            });

                            a.forEach(i => {
                                if (!this.versionsByPlatform[i.platform]) {
                                    this.versionsByPlatform[i.platform] = [];
                                }

                                this.versionsByPlatform[i.platform].push(i);
                            });
                        }

                        this.working = false;

                        this.dataChange$.next(a);
                    },
                    () => (this.working = false),
                );
            },
            () => (this.working = false),
        );
    }

    connect(): Observable<SdkPlatformVersion[]> {
        return this.dataChange$;
    }

    disconnect() {}
}
