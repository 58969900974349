import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { timer } from 'rxjs';
import * as showdown from 'showdown';

import { ApiService, AuthService, SeoService } from '../../shared';

@Component({
    selector: 'core-verify-org',
    templateUrl: './verify-org.component.html',
    styleUrls: ['./verify-org.component.scss'],
})
export class VerifyOrgComponent {
    working = false;
    rejectionNotes = '';
    showDeleteDialog = false;

    constructor(
        public auth: AuthService,
        private router: Router,
        private seo: SeoService,
        private api: ApiService,
        private translate: TranslateService,
        private snackBar: MatSnackBar,
    ) {
        if (!this.auth.isAuthenticated()) {
            this.router.navigate(['/']);
            return;
        }

        if (this.auth.activeStudio && this.auth.activeStudio.status === 'rejected') {
            this.router.navigate(['/verify/org-rejected']);
            return;
        }
        if (this.auth.user.registerOrgSize === '1') {
            this.router.navigate(['/verify/org-individual']);
            return;
        }

        this.onRefresh();
    }

    onRefresh(force = false) {
        this.working = true;

        this.auth.refreshOrgs(force).subscribe(() => {
            // show rejection page
            if (this.auth.activeStudio) {
                if (this.auth.activeStudio.status === 'approved') {
                    this.router.navigate(['/']);
                    return false;
                }

                if (['pending', 'rejected', 'created'].includes(this.auth.activeStudio.status)) {
                    const approvedOrg = this.auth.organizations.find((x) => x.status === 'approved');
                    if (approvedOrg) {
                        this.showDeleteDialog = true;
                    } else {
                        if (this.auth.activeStudio.status === 'pending') {
                            this.router.navigate(['/verify/org-approval']);
                            return false;
                        }
                    }
                }

                if (this.auth.activeStudio.rejectionNotes) {
                    let converter = new showdown.Converter();
                    converter.setFlavor('github');
                    converter.setOption('tables', 'true');
                    converter.setOption('emojis', 'true');
                    this.rejectionNotes = converter.makeHtml(this.auth.activeStudio.rejectionNotes);
                }

                if (this.auth.activeStudio.status == 'created') {
                    this.seo.setTitle('Create an Organization');
                } else {
                    this.seo.setTitle('Update an Organization');
                }
            }

            this.working = false;
        });
    }

    delete() {
        this.showDeleteDialog = false;
        this.working = true;
        this.api.deleteOrg(this.auth.activeStudio, true).subscribe(
            () => {
                this.snackBar.open(this.translate.instant('user.verify.org.deleteSuccess'), null, {
                    duration: 2000,
                });
                timer(2000).subscribe(() => {
                    this.onRefresh(true);
                });
            },
            () => {
                this.snackBar.open(this.translate.instant('user.verify.org.deleteFail'), null, {
                    duration: 2000,
                    panelClass: 'bad-snack',
                });
                timer(2000).subscribe(() => {
                    this.onRefresh(true);
                });
            },
        );
    }

    keep() {
        this.showDeleteDialog = false;

        if (this.auth.activeStudio.status == 'pending') {
            this.router.navigate(['/verify/org-approval']);
        }
    }
}
