import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import * as Cookies from 'js-cookie';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { AuthService } from './auth.service';
import { LangService } from './lang/lang.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
    constructor(private inj: Injector, private auth: AuthService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.auth.isAuthenticated()) {
            this.auth.updateExpires();
        }

        if (
            req.url.startsWith('/') ||
            req.url.startsWith('http://') ||
            req.url.startsWith('https://') ||
            req.url.startsWith('data:')
        ) {
            return next.handle(req);
        }

        // not in CookieService because it is a strictly necessary cookie
        const token = Cookies.get('id_token');
        let headers = req.headers;
        if (token) {
            headers = headers.set('Authorization', `Bearer ${token}`);
        }

        const lang: LangService = this.inj.get(LangService);

        const updated = req.clone({
            url: environment.apiUrl + '/' + req.url,
            headers: headers,
            params: req.params.set('lc', lang.currentLang ? lang.currentLang : 'en'),
        });

        return next.handle(updated).pipe(
            tap(
                (event: HttpEvent<any>) => {
                    if (event instanceof HttpResponse) {
                        return event;
                    }
                },
                (err: any) => {
                    if (err instanceof HttpErrorResponse) {
                        if (err.status === 401) {
                            // none of these in CookieService because these are strictly necessary cookies
                            Cookies.remove('access_token', { sameSite: 'strict' });
                            Cookies.remove('id_token', { sameSite: 'strict' });
                            Cookies.remove('user', { sameSite: 'strict' });
                            if (localStorage) {
                                localStorage.clear();
                            }
                            location.reload();
                        }
                    }
                    return observableThrowError(err);
                },
            ),
        );
    }
}
