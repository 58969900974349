import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-migration-redirect',
    template: `<div class="content centered">
        <div class="content-blocker">
            <h1>You are now being redirected to the Unity Dashboard...</h1>
            <mat-spinner></mat-spinner>
        </div>
    </div>`,
    styleUrls: ['./migration-redirect.component.scss'],
})
export class MigrationRedirectComponent implements OnInit, OnDestroy {
    private ngUnsubscribe = new Subject<void>();

    ngOnInit() {
        const redirectUrl = localStorage.getItem('migration-redirect-url');
        if (redirectUrl) {
            return window.location.replace(redirectUrl);
        }
        return window.location.replace('/');
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
