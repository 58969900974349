import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SharedErrorDuplicateOrgName } from 'app/core/shared-errors';
import { VivoxPlatform } from 'app/core/vivox-platforms';
import { mergeMap, tap } from 'rxjs/operators';
import { USStates, CanadaProvinces } from '../../core';

import { ApiService } from '../api.service';
import { AuthService } from '../auth.service';
import { LangService, LocalCountryName } from '../lang/lang.service';
import { Org, OrgType } from '../org.model';
import { User } from '../user.model';

@Component({
    selector: 'shared-org-form-individual',
    templateUrl: './org-form-individual.component.html',
    styleUrls: ['./org-form-individual.component.scss'],
})
export class OrgFormIndividualComponent implements OnInit {
    _org: Org = {
        vivoxPlatform: VivoxPlatform.SDKv5,
    };

    orgType = OrgType;

    bannedCountry = false;
    bannedCountryMap = new Map([
        ['KP', 'North Korea'],
        ['CU', 'Cuba'],
        ['SY', 'Syria'],
        ['CM', 'Crimea'],
        ['SD', 'Sudan'],
        ['IR', 'Iran'],
    ]);

    countries: LocalCountryName[];

    descriptionSelector: string;

    nameTaken = false;
    members: User[];

    otosChecked = false;
    usStates = USStates;
    caProvinces = CanadaProvinces;
    canadaPostalCodePattern = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
    usPostalCodePattern = /(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)/;

    get org(): Org {
        return this._org;
    }

    @Input('org')
    set org(value: Org) {
        if (!value) {
            value = {
                vivoxPlatform: this.auth.sdkFromUser(),
            };
        }
        this._org = value;
        if (value.status == 'created') {
            // clear out the placeholder name created for zendesk. Now user can enter name
            value.name = '';
        }
    }

    agreementsValid(): boolean {
        return this.otosChecked;
    }

    descriptionSelectorChanged() {
        if (this.descriptionSelector == 'other') {
            this.org.description = '';
            console.log(this.org.description);
            return;
        }
        this.org.description = this.descriptionSelector;
        console.log(this.org.description);
    }

    working = false;

    constructor(
        public auth: AuthService,
        private api: ApiService,
        private translate: TranslateService,
        private sb: MatSnackBar,
        private langService: LangService,
        private router: Router,
    ) {
        this.org = auth.activeStudio
            ? auth.activeStudio
            : {
                  vivoxPlatform: auth.sdkFromUser(),
              };

        if (this.org.id) {
            this.api.getOrgMembers(this.org.id, false).subscribe((r) => {
                this.members = r;
                this.updateBizAndTechContact();
            });
        }
    }

    ngOnInit() {
        this.countries = this.langService.getCountries();
        this.langService.onLanguageChange.subscribe(() => {
            this.countries = this.langService.getCountries();
        });
    }

    memberFullName(u: User): string {
        let result = '';
        if (u.firstName) {
            result += u.firstName;
        }
        if (u.lastName) {
            if (result) {
                result += ' ';
            }
            result += u.lastName;
        }
        return result;
    }

    updateBizAndTechContact() {
        let bizContactFound = false;
        let techContactFound = false;
        if (this.members && this.members.length) {
            this.members.forEach((m) => {
                if (this.org.contactEmail && m.email == this.org.contactEmail) {
                    this.org.contactName = this.memberFullName(m);
                    this.org.contactEmail = m.email;
                    bizContactFound = true;
                }
                if (this.org.supportContactEmail && m.email == this.org.supportContactEmail) {
                    this.org.supportContactName = this.memberFullName(m);
                    this.org.supportContactEmail = m.email;
                    techContactFound = true;
                }
            });
        }

        if (!this.auth.isVivox && (!bizContactFound || !techContactFound)) {
            const u = this.auth.user;

            if (!bizContactFound) {
                this.org.contactName = u.firstName + ' ' + u.lastName;
                this.org.contactEmail = u.email;
            }

            if (!techContactFound) {
                this.org.supportContactName = u.firstName + ' ' + u.lastName;
                this.org.supportContactEmail = u.email;
            }
        }
    }

    isEmpty(str: string) {
        return !str || 0 === str.trim().length;
    }

    trimOrgField(field: string) {
        this.org[field] = this.org[field] ? this.org[field].trim() : '';
    }

    trimOrgFields() {
        this.org.description = this.org.description ? this.org.description.trim() : '';
        this.org.country = this.org.country ? this.org.country.trim() : '';
        this.org.streetAddress = this.org.streetAddress ? this.org.streetAddress.trim() : '';
        this.org.city = this.org.city ? this.org.city.trim() : '';
        this.org.state = this.org.state ? this.org.state.trim() : '';
        this.org.postalCode = this.org.postalCode ? this.org.postalCode.trim() : '';
    }

    onSave() {
        this.router.navigate(['/verify/org-approval']);
    }

    async onSubmit() {
        this.working = true;
        this.trimOrgFields();
        // If the country provided in the input form is a flagged one - disallow submission.
        if (this.bannedCountryMap.has(this.org.country)) {
            this.bannedCountry = true;
            return;
        }

        this.submit();
    }

    async submit() {
        this.working = true;

        this.org.name = this.auth.user.registerOrgName;
        this.org.size = this.auth.user.registerOrgSize;
        this.org.individual = this.auth.user.registerOrgSize && this.auth.user.registerOrgSize == '1';

        // only set vivox platform if adding new org
        this.api
            .saveOrg(this.org)
            .pipe(
                mergeMap(() => this.auth.refreshOrgs(true)),
                tap(() => {
                    this.working = false;
                }),
            )
            .subscribe(
                () => {
                    this.onSave();
                },
                (err) => {
                    this.working = false;
                    let errorText = err.message;
                    if (err.error && err.error.data) {
                        if (typeof err.error.data == 'number') {
                            switch (err.error.data) {
                                case SharedErrorDuplicateOrgName:
                                    this.nameTaken = true;
                                    errorText = this.translate.instant('user.settings.org.duplicateOrgName');
                                    break;
                                default:
                                    errorText = this.translate.instant('user.settings.org.defaultSaveError');
                            }
                        } else {
                            errorText = err.error.data;
                        }
                    }
                    console.log(err);
                    this.sb.open(`Error saving: ${errorText}`, null, {
                        duration: 5000,
                        panelClass: 'bad-snack',
                    });
                },
            );
    }
}
