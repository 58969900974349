import { Component, Input, OnInit } from '@angular/core';

import { SeoService } from '../../shared/seo.service';

@Component({
    selector: 'app-license',
    templateUrl: 'license.component.html',
    styleUrls: ['license.component.scss'],
})
export class LicenseComponent implements OnInit {
    @Input()
    embedded = false;

    constructor(private seo: SeoService) {}

    ngOnInit() {
        if (!this.embedded) {
            this.seo.setTitle('Terms Of Service');
        }
    }
}
