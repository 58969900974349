import { Observable, of as observableOf } from 'rxjs';

import { map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild } from '@angular/router';

import { AuthService } from '../shared';

@Injectable()
export class MixedGuard implements CanActivate, CanActivateChild {
    constructor(private auth: AuthService) {}

    canActivate(): Observable<boolean> {
        if (this.auth.isVivox) {
            return observableOf(true);
        }

        if (!this.auth.isAuthenticated() || !this.auth.user) {
            return observableOf(true);
        }

        return this.auth.refreshOrgs().pipe(
            map(() => {
                return true;
            }),
        );
    }

    canActivateChild(): Observable<boolean> {
        return this.canActivate();
    }
}
