import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

import { AuthService } from '../../shared/auth.service';
import { Permission } from '../permissions';

@Component({
    selector: 'core-get-verified-dialog',
    templateUrl: './get-verified-dialog.component.html',
    styleUrls: ['./get-verified-dialog.component.scss'],
})
export class GetVerifiedDialogComponent {
    showForm = false;
    showBody = false;
    platform: string;
    form: FormGroup;
    working: boolean;
    public pending: boolean;

    constructor(
        fb: FormBuilder,
        public dialogRef: MatDialogRef<GetVerifiedDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: { missingPermission?: string; platform?: string; showForm: boolean | undefined },
        private snackBar: MatSnackBar,
        private auth: AuthService,
        private translate: TranslateService,
        private http: HttpClient,
    ) {
        this.pending = data.missingPermission && this.auth.hasPendingPermission(data.missingPermission as Permission);
        if (data.platform) {
            this.platform = data.platform;
        }

        if (data.missingPermission == Permission.PlatformXboxOne) {
            this.platform = 'Xbox One';
            this.showBody = true;
        } else if (data.missingPermission == Permission.PlatformXboxSXS) {
            this.platform = 'Xbox Series XS';
            this.showBody = true;
        } else if (data.missingPermission == Permission.PlatformPS4) {
            this.platform = 'PlayStation 4';
            this.showBody = true;
        } else if (data.missingPermission == Permission.PlatformPS5) {
            this.platform = 'PlayStation 5';
            this.showBody = true;
        } else if (data.missingPermission == Permission.PlatformNintendo) {
            this.platform = 'Nintendo';
            this.showBody = true;
        } else if (data.missingPermission == Permission.PlatformStadia) {
            this.platform = 'Stadia';
            this.showBody = true;
        } else if (data.missingPermission == Permission.PlatformWebkit) {
            this.platform = 'Webkit';
        }

        this.showForm = !!data.showForm;

        this.form = fb.group({
            body: [''],
            organizationId: [this.auth.activeStudio.id, Validators.required],
            permission: [data.missingPermission, Validators.required],
        });

        if (this.showBody) {
            this.form.controls['body'].setValidators(Validators.required);
        }
    }

    sendRequest() {
        this.working = true;
        this.http.post<void>(`api/v1/permission-requests`, this.form.value).subscribe(
            () => {
                const msg = this.translate.instant('user.get-verified-dialog.requestVerificationSuccess');
                this.snackBar.open(msg, null, { duration: 2000 });
                this.dialogRef.close(true);
                this.auth.refreshOrgs(true).subscribe(
                    () => (this.working = false),
                    () => (this.working = false),
                );
            },
            () => {
                const msg = this.translate.instant('user.get-verified-dialog.requestVerificationFailed');
                this.snackBar.open(msg, null, { duration: 2000, panelClass: 'bad-snack' });
                this.dialogRef.close(false);
                this.working = false;
            },
        );
    }
}
