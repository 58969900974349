import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, takeWhile } from 'rxjs/operators';

import { AuthService, LogService } from '../shared';

@Injectable()
export class ApprovedOrgGuard implements CanActivate, CanActivateChild {
    constructor(private auth: AuthService, private router: Router, private log: LogService) {}

    canActivate(): Observable<boolean> {
        return this.auth.refreshOrgs().pipe(
            takeWhile(() => {
                if (!this.auth.activeStudio || ['rejected', 'created'].includes(this.auth.activeStudio.status)) {
                    if (!this.auth.activeStudio) {
                        this.log.warn(this, 'User has no org');
                    } else if (this.auth.activeStudio.status === 'rejected') {
                        this.log.warn(this, 'User has rejected org');
                        this.router.navigate(['/verify/org-rejected']);
                        return false;
                    } else if (this.auth.activeStudio.status === 'created') {
                        this.log.warn(this, 'User has a placeholder org');
                    }
                    this.log.warn(this, 'Navigating to org form');

                    if (this.auth.user.registerOrgSize == '1') {
                        this.router.navigate(['/verify/org-individual']);
                    } else {
                        this.router.navigate(['/verify/org']);
                    }
                    return false;
                }
                return true;
            }),
            takeWhile(() => {
                // requires an approved organization otherwise they should see a message
                if (this.auth.activeStudio.status === 'pending') {
                    this.log.info(this, 'User org is pending');

                    // show approval page
                    this.router.navigate(['/verify/org-approval']);
                    return false;
                }
                return true;
            }),
            takeWhile(() => {
                if (this.auth.activeStudio.status === 'flagged') {
                    this.log.warn(this, 'User has a flagged org');

                    this.router.navigate([`/verify/org-flagged`]);
                    return false;
                }
                return true;
            }),
            takeWhile(() => {
                if (!this.auth.isVerified()) {
                    this.log.warn(this, 'User not verified');
                    this.router.navigate(['/verify/email']);
                    return false;
                }
                return true;
            }),
            takeWhile(() => {
                if (
                    !this.auth.isVivox &&
                    !this.auth.isSDKue4Org() &&
                    !this.auth.activeStudio.nonStandardMSA &&
                    !this.auth.activeStudio.dedicatedServer &&
                    !this.auth.activeStudio.enterpriseSupport
                ) {
                    this.log.warn(this, 'Enterprise options incomplete');
                    this.router.navigate([`/verify/enterprise`]);
                    return false;
                }
                return true;
            }),
            map(() => {
                return true;
            }),
        );
    }

    canActivateChild() {
        return this.canActivate();
    }
}
