import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';

import { APIResponse, Faq, FaqCategory, SeoService } from '../../shared';
import { GetVerifiedDialogComponent } from '../get-verified-dialog/get-verified-dialog.component';

@Component({
    selector: 'app-faq',
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.scss'],
})
export class FaqComponent implements OnInit {
    categories: FaqCategory[];
    faqs: Faq[];

    constructor(private dialog: MatDialog, private http: HttpClient, private seo: SeoService) {}

    ngOnInit() {
        this.seo.setTitle('FAQs');

        this.http.get<APIResponse<FaqCategory[]>>('api/v1/faq-categories').subscribe(r => {
            this.categories = [];

            if (!r.data) {
                return;
            }

            r.data.forEach(cat => {
                if (cat.parentId === 0) {
                    cat.questions = [];
                    this.categories.push(cat);
                }
            });

            this.categories.forEach(cat => {
                cat.subCategories = [];

                r.data.forEach(sub => {
                    if (sub.parentId === cat.id) {
                        cat.subCategories.push(sub);
                    }
                });
            });

            this.http.get<APIResponse<Faq[]>>(`api/v1/faqs`).subscribe(r => {
                this.faqs = r.data;

                this.categories.forEach(cat => {
                    r.data.forEach(faq => {
                        if (faq.categoryId == cat.id) {
                            cat.questions.push(faq);
                        }
                    });
                });

                this.categories.sort((a, b) => a.sort - b.sort);

                for (const category of this.categories) {
                    if (category) {
                        category.questions.sort((a, b) => a.sort - b.sort);
                    }
                }
            });

            const div = this.categories.length % 3;
            if (div !== 0) {
                this.categories = this.categories.concat(new Array(3 - div));
            }
        });
    }

    getVerified(faq: Faq) {
        this.dialog.open(GetVerifiedDialogComponent, { data: { missingPermission: faq.missingPermission } });
    }
}
