import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import * as Chart from 'chart.js';

// chart.js defaults
Chart.defaults.global.defaultFontFamily = '"Montserrat", sans-serif';
Chart.defaults.global.defaultFontSize = 11;

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule);
