import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { fromEvent, merge, timer } from 'rxjs';
import { debounce } from 'rxjs/operators';
import * as showdown from 'showdown';

@Component({
    selector: 'shared-markdown',
    templateUrl: './markdown.component.html',
    styleUrls: ['./markdown.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: MarkdownComponent, // forwardRef(() => MarkdownComponent),
            multi: true,
        },
    ],
})
export class MarkdownComponent implements AfterViewInit, OnInit, ControlValueAccessor {
    @ViewChild('textarea') comp1: ElementRef;
    @ViewChild('preview') comp2: ElementRef;

    @Input() value;
    @Input('previewShowing') previewShowing = false;
    @Input('sideBySidePreview') sideBySidePreview = true;

    converter: showdown.Converter;
    previewValue: string;
    helpShowing = false;
    _onChange: any;
    _onTouched: any;

    constructor() {
        this.converter = new showdown.Converter();
        this.converter.setFlavor('github');
        this.converter.setOption('tables', 'true');
        this.converter.setOption('emojis', 'true');
    }

    ngOnInit() {
        merge(fromEvent(this.textarea, 'keyup'), fromEvent(this.textarea, 'paste'))
            .pipe(debounce(() => timer(500)))
            .subscribe(() => {
                if (this._onChange !== undefined) {
                    this._onChange(this.textarea.value);
                }
                if (this.previewShowing) {
                    this.previewValue = this.converter.makeHtml(this.textarea.value);
                }
            });
    }

    ngAfterViewInit(): void {
        if (this.value) {
            this.textarea.value = this.value;
        }
    }

    get textarea() {
        return this.comp1.nativeElement as HTMLTextAreaElement;
    }

    get preview() {
        return this.comp2.nativeElement as HTMLDivElement;
    }

    toggleHelp() {
        this.helpShowing = !this.helpShowing;
    }

    togglePreview() {
        if (this.helpShowing) {
            this.helpShowing = false;
        }
        this.previewShowing = !this.previewShowing;
        if (this.previewShowing) {
            this.previewValue = this.converter.makeHtml(this.textarea.value);
        }
    }

    writeValue(obj: any): void {
        this.textarea.value = obj;
        if (this.previewShowing) {
            this.previewValue = this.converter.makeHtml(this.textarea.value);
        }
        if (this._onChange !== undefined) {
            this._onChange(obj);
        }
    }

    registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.textarea.disabled = isDisabled;
    }
}
