import { DataSource } from '@angular/cdk/collections';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ApiService } from '../api.service';
import { OvertonePlatform } from '../overtone.model';

@Injectable()
export class OvertonePlatformDataSource extends DataSource<OvertonePlatform> {
    dataChange$ = new BehaviorSubject<OvertonePlatform[]>([]);
    working = false;

    constructor(private api: ApiService) {
        super();
    }

    update() {
        this.working = true;
        this.api.getOvertonePlatforms(true).subscribe(
            a => {
                if (a) {
                    a.sort((a, b) => {
                        return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
                    });
                }

                this.working = false;

                this.dataChange$.next(a);
            },
            () => (this.working = false),
        );
    }

    connect(): Observable<OvertonePlatform[]> {
        return this.dataChange$;
    }

    disconnect() {}
}
