import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { delay } from 'rxjs/operators';

import { OrgMembersDataSource } from '../../shared/datasource/org-members.datasource';
import { AuthService } from '../auth.service';

@Component({
    selector: 'app-members',
    templateUrl: './members.component.html',
    styleUrls: ['./members.component.scss'],
})
export class MembersComponent implements OnInit {
    @Input()
    orgId: number;
    singleRow: boolean;
    private _working = false;

    constructor(
        public auth: AuthService,
        public data: OrgMembersDataSource,
        private http: HttpClient,
        private translate: TranslateService,
        private snackBar: MatSnackBar,
    ) {}

    get working(): boolean {
        return this._working || this.data.working;
    }

    set working(value: boolean) {
        this._working = value;
    }

    ngOnInit() {
        this.update();
    }

    update() {
        this.data.update(this.orgId).subscribe(result => {
            this.singleRow = result && result.length == 1;
        });
    }

    deleteInvite(inviteCode: string) {
        this.working = true;
        this.http.post(`api/v1/invite/delete`, { organization: this.orgId, code: inviteCode }).subscribe(
            () => {
                this.working = false;
                this.update();
            },
            () => {
                this.working = false;
            },
        );
    }

    resendInvite(inviteCode: string) {
        this.working = true;
        this.http.post(`api/v1/invite/resend`, { organization: this.orgId, code: inviteCode }).subscribe(
            () => {
                this.working = false;
                this.update();
            },
            () => {
                this.working = false;
            },
        );
    }

    removeMember(userId: string) {
        this.working = true;
        this.http.delete(`api/v1/organizations/${this.orgId}/users/${userId}`).subscribe(
            () => {
                this.working = false;
                this.update();
            },
            err => {
                this.working = false;
                const message = (err.error && err.error.data) || err.message;
                this.snackBar.open(message, null, {
                    duration: 5000,
                    panelClass: 'bad-snack',
                });
            },
        );
    }

    blockUser(userId: string) {
        let dialog: any = this.translate.instant('admin.apps.blockConfirm');
        let conf = window.prompt(dialog);

        if (conf == 'BLOCK') {
            this.working = true;
            this.http
                .post(`api/v1/users/${userId}/block`, {})
                .pipe(delay(2000))
                .subscribe(
                    () => {
                        this.working = false;
                        this.update();
                    },
                    () => {
                        this.working = false;
                    },
                );
        }
    }

    unblockUser(userId: string) {
        this.working = true;
        this.http
            .post(`api/v1/users/${userId}/unblock`, {})
            .pipe(delay(2000))
            .subscribe(
                () => {
                    this.working = false;
                    this.update();
                },
                () => {
                    this.working = false;
                },
            );
    }
}
