import { AfterViewInit, Component } from '@angular/core';

import { AuthService } from '../../../shared/auth.service';
import { FTUEComponent } from '../ftue.component';

@Component({
    selector: 'app-core-ftue-post-app',
    templateUrl: './post-app.component.html',
    styleUrls: ['../ftue.scss'],
})
export class FTUEPostAppComponent extends FTUEComponent implements AfterViewInit {
    isActive = false;
    currentStep = 1;
    steps = 3;
    stepsArr = [];
    sdkImage = '/assets/image/ftue/sdk.png';

    constructor(private auth: AuthService) {
        super();
        this.steps = this.isSDKv5Org ? 3 : 2;
        if (this.auth.isSDKue4Org()) {
            this.sdkImage = '/assets/image/ftue/sdk-ue4.png';
        }
    }

    ngAfterViewInit() {
        this.isActive = true;
        this.stepsArr = new Array(this.steps);
    }

    gotoOtPromo() {
        this.saveDone().subscribe(() => {
            this.hideForce();
        });
    }

    get isSDKv5Org() {
        return this.auth.isSDKv5Org();
    }
}
