import { DataSource } from '@angular/cdk/collections';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from '../api.service';
import { App } from '../app.model';

@Injectable()
export class AppDataSource extends DataSource<App> {
    dataChange$ = new BehaviorSubject<App[]>([]);

    typeOrdinal: any = {
        pending: 1,
        approved: 2,
        sandbox: 3,
        rejected: 4,
    };

    filter: any = {
        limit: 50,
        page: 1,
        status: '',
    };
    working = false;
    pages: number;
    isLastPage = false;

    envId: number;

    constructor(private api: ApiService) {
        super();
    }

    firstPage() {
        this.filter.page = 1;
        this.update();
    }

    prevPage() {
        this.filter.page -= 1;
        if (this.filter.page == 0) {
            this.filter.page = 1;
        }
        this.update();
    }

    nextPage() {
        this.filter.page += 1;
        this.update();
    }

    update() {
        const queryString: string[] = [];
        queryString.push(`limit=${this.filter.limit || 100}`);
        queryString.push(`offset=${this.filter.page ? (this.filter.page - 1) * (this.filter.limit || 100) : 0}`);
        if (this.filter.status) {
            queryString.push(`s=${this.filter.status}`);
        }
        if (this.filter.name) {
            queryString.push(`name=${this.filter.name}`);
        }
        const queryStringStr = queryString.join('&');

        this.working = true;

        forkJoin(this.api.getApps(queryStringStr, this.envId, true), this.api.getApps(queryStringStr, this.envId))
            .pipe(map(res => ({ totalCount: +res[0], data: res[1] })))
            .subscribe(
                res => {
                    this.pages = Math.floor(res.totalCount / this.filter.limit) + 1;
                    this.isLastPage = this.filter.page === this.pages;

                    this.dataChange$.next(res.data);

                    this.working = false;
                },
                () => {
                    this.working = false;
                },
            );
    }

    connect(): Observable<App[]> {
        return this.dataChange$;
    }

    disconnect() {}
}
