import { BehaviorSubject, Observable, of as observableOf } from 'rxjs';

import { DataSource } from '@angular/cdk/collections';
import { Injectable } from '@angular/core';
import { map, mergeMap } from 'rxjs/operators';

import { ApiService } from '../api.service';
import { OvertonePlatform, OvertonePlatformVersion } from '../overtone.model';

@Injectable()
export class OvertonePlatformVersionDataSource extends DataSource<OvertonePlatformVersion> {
    dataChange$ = new BehaviorSubject<OvertonePlatformVersion[]>([]);

    versionsByPlatform: { [key: number]: OvertonePlatformVersion[] } = {};
    platforms: { [key: number]: OvertonePlatform } = {};

    working = false;

    constructor(private api: ApiService) {
        super();
    }

    update() {
        this.versionsByPlatform = {};

        let obs: Observable<{ [key: number]: OvertonePlatform }> = null;

        if (Object.keys(this.platforms).length > 0) {
            obs = observableOf(this.platforms);
        } else {
            obs = this.api.getOvertonePlatforms(true).pipe(
                map(p => {
                    p.forEach(platform => (this.platforms[platform.id] = platform));
                    return p;
                }),
            );
        }

        this.working = true;

        obs.pipe(mergeMap(() => this.api.getOvertonePlatformVersions(true))).subscribe(
            a => {
                if (!a) {
                    this.dataChange$.next(a);
                    return;
                }

                a.sort((a, b) => {
                    if (a.deleted > b.deleted) {
                        return 1;
                    } else if (a.deleted < b.deleted) {
                        return -1;
                    }

                    return this.platforms[a.platform].name.toLowerCase() < this.platforms[b.platform].name.toLowerCase()
                        ? -1
                        : 1;
                });

                a.forEach(i => {
                    if (!this.versionsByPlatform[i.platform]) {
                        this.versionsByPlatform[i.platform] = [];
                    }

                    this.versionsByPlatform[i.platform].push(i);
                });

                this.working = false;

                this.dataChange$.next(a);
            },
            () => (this.working = false),
        );
    }

    connect(): Observable<OvertonePlatformVersion[]> {
        return this.dataChange$;
    }

    disconnect() {}
}
