import * as moment from 'moment';

import { AppStatistics, AppStatisticsResultSeries } from '../app.model';

export class UserChart {
    type = 'line';

    singleDay = false;

    labels = [];

    colors = [
        {
            backgroundColor: 'rgba(195, 143, 30, 0.6)',
            borderColor: 'rgba(195, 143, 30, 0.9)',
        },
    ];

    datasets = [
        {
            data: [],
            borderWidth: 2,
            fill: 'start',
        },
    ];

    options = {
        hover: {
            mode: 'nearest',
            intersect: true,
        },
        legend: {
            display: false,
        },
        title: {
            display: false,
        },
        tooltips: {
            mode: 'index',
            intersect: false,
            callbacks: {
                title: (item) => {
                    if (this.singleDay) {
                        return moment(+item[0].label)
                            .utc()
                            .format('HH:mm');
                    }
                    return moment(+item[0].label)
                        .utc()
                        .format('MMM DD');
                },
            },
        },
        maintainAspectRatio: false,
        spanGaps: false,
        elements: {
            point: {
                radius: 0,
            },
        },
        plugins: {
            filler: {
                propagate: false,
            },
        },
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        callback: function (label) {
                            if (Math.floor(label) === label) {
                                return label;
                            }
                        },
                    },
                },
            ],
            xAxes: [
                {
                    stacked: true,
                    gridLines: {
                        display: false,
                    },
                    ticks: {
                        callback: (label) => {
                            if (this.singleDay) {
                                return moment(label).utc().format('HH:mm');
                            } else {
                                return moment(label).utc().format('MMM DD');
                            }
                        },
                    },
                },
            ],
        },
    };

    interval: string;

    constructor(data: AppStatistics, interval: string) {
        if (!data || !data.results) {
            return;
        }

        this.interval = interval;

        if (data.results.length > 0) {
            if (data.results[0].series && data.results[0].series.length > 0) {
                this.loadSeries(data.results[0].series[0]);
            }
        }
    }

    loadSeries(series: AppStatisticsResultSeries) {
        let groupByDay = {};

        (series.values || []).forEach((d) => {
            let day = moment.utc(d[0]).format('YYYY-MM-DD');
            let count = d[1];

            if (!groupByDay[day]) {
                groupByDay[day] = count;
            } else {
                groupByDay[day] += count;
            }
        });

        if (this.interval == '10m') {
            this.singleDay = true;
            (series.values || []).forEach((d) => {
                this.labels.push(moment(d[0]));
                this.datasets[0].data.push(Math.round(d[1] * 100) / 100);
            });
        } else if (Object.keys(groupByDay).length > 1) {
            this.singleDay = false;
            for (let day in groupByDay) {
                this.labels.push(moment(day));
                this.datasets[0].data.push(Math.round(groupByDay[day] * 100) / 100);
            }
        } else {
            this.singleDay = false;
        }
    }
}
