import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { APIResponse, Faq, FaqCategory, SeoService } from '../../shared';

@Component({
    selector: 'app-faq-item',
    templateUrl: './faq-item.component.html',
    styleUrls: ['./faq-item.component.scss'],
})
export class FaqItemComponent implements OnInit {
    category: FaqCategory;
    parent: FaqCategory;
    item: Faq;

    constructor(
        private http: HttpClient,
        private route: ActivatedRoute,
        private router: Router,
        private seo: SeoService,
    ) {}

    ngOnInit() {
        this.route.params.subscribe(p => {
            this.http.get<APIResponse<FaqCategory>>(`api/v1/faq-categories/${p.id}`).subscribe(
                r => {
                    this.category = r.data;

                    if (this.category.parentId) {
                        this.http
                            .get<APIResponse<FaqCategory>>(`api/v1/faq-categories/${this.category.parentId}`)
                            .subscribe(r => {
                                this.parent = r.data;
                            });
                    }
                },
                () => this.router.navigate(['/faqs']),
            );

            this.http.get<APIResponse<Faq>>(`api/v1/faqs/${p.item}`).subscribe(
                r => {
                    this.item = r.data;
                    this.seo.setTitle(this.item.question + ' - FAQs');
                },
                () => this.router.navigate(['/faqs/category/', p.id]),
            );
        });
    }
}
