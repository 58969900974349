import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

const ZENDESK_ERROR = '(zendesk_error)';
const SALESFORCE_ERROR = '(salesforce_error)';

@Injectable()
export class ErrorsInterceptor implements HttpInterceptor {
    constructor(private router: Router) {}

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        return next.handle(req).pipe(
            catchError(err => {
                if (err instanceof HttpErrorResponse && !err.ok) {
                    const { error, status } = err;

                    const zendeskError =
                        error && error.data && typeof error.data === 'string' && error.data.startsWith(ZENDESK_ERROR);
                    const salesforceError = typeof error === 'string' && error.includes(SALESFORCE_ERROR);

                    if (
                        zendeskError ||
                        salesforceError ||
                        status === 0 ||
                        status === 503 // http.StatusServiceUnavailable
                    ) {
                        this.router.navigate(['/error']);
                    }
                }

                return throwError(err);
            }),
        );
    }
}
