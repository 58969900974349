import { animate, style, transition, trigger } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, Renderer2, ViewChild, ViewContainerRef } from '@angular/core';
import { MatDialog, MatMenu, MatSnackBar } from '@angular/material';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'app/shared/cookie.service';
import moment from 'moment';
import { Observable } from 'rxjs';
import { fromEvent } from 'rxjs/observable/fromEvent';
import { of } from 'rxjs/observable/of';
import { filter, map, merge, mergeMap, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Permission, formatMigrationUrl } from '../core';
import { ApiService, AuthService, EnterpriseSupport, LangService, QuickOrg } from '../shared';
import { PendingInterceptor } from '../shared/pending-interceptor';

import { ChangeEmailService } from './change-email-dialog';
import { ConfirmDeclineDialogService } from './confirm-decline-dialog/confirm-decline-dialog.service';
import { EmailVerifyDialogComponent } from './email-verify-dialog/email-verify-dialog.component';
import { EnterpriseOptionsDialogComponent } from './enterprise/enterprise-options-dialog.component';
import { FTUEService } from './ftue/ftue.service';
import { FTUEPreAppExitComponent } from './ftue/pre-app/pre-app-exit.component';
import { FTUEPreAppSplashComponent } from './ftue/pre-app/pre-app-splash.component';
import { GetVerifiedDialogComponent } from './get-verified-dialog/get-verified-dialog.component';
import { ApiResponse, ConditionalAccessOption, Documentation, Survey } from './models';
import { NeedsApprovedOrgDialogComponent } from './needs-approved-org-dialog/needs-approved-org-dialog.component';
import { SurveyPopupDialogComponent } from './survey-popup-dialog/survey-popup-dialog.component';

@Component({
    selector: 'app-core',
    templateUrl: './core.component.html',
    styleUrls: ['./core.component.scss'],
    animations: [
        trigger('fadeInOut', [
            transition(':enter', [style({ opacity: 0 }), animate(300, style({ opacity: 1 }))]),
            transition(':leave', [animate(600, style({ opacity: 0 }))]),
        ]),
    ],
})
export class CoreComponent implements OnInit {
    @ViewChild('sidebar')
    sidebar: ElementRef;
    @ViewChild('orgMenu')
    orgMenu: MatMenu;
    @ViewChild('sidebarBlocker')
    sidebarBlocker: ElementRef;
    @ViewChild('otPromoMenu')
    otPromoMenu: ElementRef;

    loadingVisible = false;
    sidebarVisible = false;
    studioPickerToggle = false;
    contactFormToggle = false;
    contactFormDefault = 'developer';
    docs: Documentation[] = [];
    nonStandardMSAShow = false;
    year = new Date().getFullYear();
    showEnterpriseOptionsPopup = false;
    zendeskForumLink: string;
    zendeskFaqLink: string;
    zendeskTicketLink: string;
    ue4DocumentationLink: string;
    enterpriseDialogOpen = false;
    standalone = false;
    declineDialogToggle = false;
    enterpriseContactFormToggle = false;
    enterpriseContactFormDefault = 'developer';
    working: boolean;
    spinner = false;
    isLoginMain = true;
    showSizzleReelPopup = false;
    videoSrc: SafeResourceUrl;
    ConditionalAccessOption = ConditionalAccessOption;
    survey: Survey;
    orgName: string;
    orgLogo: string;
    enterpriseDialogRef: any;
    showMigrationPopup = false;
    showAlreadyMigratedPopup = false;
    orgsNeedingMigration: QuickOrg[] = [];
    loginMigrationUrl: string;
    signupMigrationUrl: string;
    migratedUrl: string;
    migratedContent: string;
    showMigrationDeadlinePassedContent = false;
    isSpecialUser = false;

    constructor(
        sanitizer: DomSanitizer,
        private api: ApiService,
        public auth: AuthService,
        public changeEmail: ChangeEmailService,
        private ftue: FTUEService,
        private http: HttpClient,
        private dialog: MatDialog,
        private pending: PendingInterceptor,
        private router: Router,
        private viewContainer: ViewContainerRef,
        private langService: LangService,
        private snackBar: MatSnackBar,
        private translate: TranslateService,
        private declineService: ConfirmDeclineDialogService,
        private renderer: Renderer2,
        private element: ElementRef,
        private cookieService: CookieService,
    ) {
        this.videoSrc = sanitizer.bypassSecurityTrustResourceUrl('assets/video/sne-unity_overtone.mp4');

        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
            this.isLoginMain = this.router.url === '/';
            if (this.router.url == '/apps') {
                this.getSurveyInfo();
            }
        });

        if (this.auth.isZendeskSSOReq() || this.auth.isLoginRedirect()) {
            this.standalone = true;
        }

        this.auth.userChange.subscribe(() => {
            this.loadDocs();
        });

        if (this.auth.activeStudio) {
            this.populateOrgData();
        }

        this.auth.activeStudioChange.subscribe(() => {
            this.loadDocs();
            this.checkPlanStartup();
            if (this.auth.activeStudio) {
                this.populateOrgData();
            }
        });
        this.pending.pendingRequestsStatus.subscribe((r) => (this.loadingVisible = r));
        this.router.events.subscribe((e) => {
            if (e instanceof NavigationEnd) {
                if (this.isSimpleEnterpriseURL()) {
                    if (this.enterpriseDialogOpen && this.enterpriseDialogRef) {
                        this.enterpriseDialogRef.close();
                    }
                } else {
                    if (!this.checkPlanStartup()) {
                        this.ftueCheck();
                    }
                }
                (<any>window).scrollTo(0, 0);
            }
        });

        this.langService.onLanguageChange.subscribe((lang) => {
            this.loadDocs();

            this.switchZendeskLinks(lang);
            this.switchUE4DocumentationLink(lang);
        });

        this.loadDocs();

        this.switchZendeskLinks(this.langService.currentLang);
        this.switchUE4DocumentationLink(this.langService.currentLang);

        if (!this.checkPlanStartup()) {
            this.ftueCheck();
        }

        if (this.router.url.includes('access-token')) {
            const accessToken = this.router.url.split('access-token=')[1];
            sessionStorage.setItem('accessToken', accessToken);
        }

        if (sessionStorage.getItem('accessToken')) {
            const value = sessionStorage.getItem('accessToken');

            // base64 decode the token
            const token = atob(value);
            if (token === 'letmein' || token === 'customertoken') {
                this.isSpecialUser = true;
            }
        }
    }

    isSimpleEnterpriseURL() {
        return this.router.url == '/verify/enterprise/simple';
    }

    ngOnInit() {
        this.declineService.visibilityChange.subscribe((nowShowing) => {
            this.declineDialogToggle = nowShowing;
        });
    }

    get modalVideoHeight() {
        return window.innerWidth * 0.7 * 0.5625;
    }

    goToSupportLink() {
        window.location.href = 'https://support.unity.com';
    }

    public submitTicketClick() {
        if (this.auth && this.auth.activeStudio) {
            if (
                [
                    EnterpriseSupport.FREE.toString(),
                    EnterpriseSupport.PAID.toString(),
                    EnterpriseSupport.BILLED.toString(),
                ].includes(this.auth.activeStudio.enterpriseSupport)
            ) {
                window.location.href = 'https://support.unity.com/hc/en-us/requests/new?ticket_form_id=360003199531';
            } else {
                this.openEnterpriseDialog(false);
            }
        }
    }

    public nonStandardMsaAgreementClick() {
        this.nonStandardMSAShow = true;
    }

    get ue4OrgActive() {
        return this.auth.orgAvailable && this.auth.isSDKue4Org();
    }

    get v4OrgActive() {
        return this.auth.orgAvailable && this.auth.isSDKv4Org();
    }

    get v5OrgActive() {
        return this.auth.orgAvailable && this.auth.isSDKv5Org();
    }

    checkSurveyPopup() {
        if (this.ftue.showing || this.enterpriseDialogOpen || !this.survey.enabled) {
            return;
        }
        const surveyUpdatedAt = moment.utc(this.survey.updatedAt);
        const cookieUpdatedAt = moment.utc(this.cookieService.surveyDate);
        if (!surveyUpdatedAt.isSame(cookieUpdatedAt)) {
            this.cookieService.surveyDate = surveyUpdatedAt.toDate();
            this.dialog.open(SurveyPopupDialogComponent, {
                width: '400px',
                height: 'auto',
                data: {
                    surveyText: this.survey.surveyText,
                    surveyLink: this.survey.link,
                },
            });
        }
    }

    switchZendeskLinks(lang: string) {
        const zendeskLanguage = this.langService.getLangCode(lang);

        this.zendeskForumLink = environment.zendeskURL + '/hc/' + zendeskLanguage + '/community/topics';
        this.zendeskFaqLink = environment.zendeskURL + '/hc/' + zendeskLanguage;
        this.zendeskTicketLink = environment.zendeskURL + '/hc/' + zendeskLanguage + '/requests/new';
    }

    switchUE4DocumentationLink(lang: string) {
        const language = this.langService.getLangCode(lang);

        this.ue4DocumentationLink = `https://docs.unrealengine.com/${language}/Engine/Plugins/VivoxPlugin/index.html`;
    }

    onGettingStarted(promptExit: boolean) {
        if (promptExit && this.auth.isSDKv5Org()) {
            this.ftue.show('ftue_org', this.viewContainer, FTUEPreAppSplashComponent, FTUEPreAppExitComponent);
        } else {
            this.ftue.show('ftue_org', this.viewContainer, FTUEPreAppSplashComponent);
        }
    }

    getVerified(permission: Permission) {
        let missingPermission = this.auth.hasPermission(permission) ? '' : permission;
        if (missingPermission) {
            this.dialog.open(GetVerifiedDialogComponent, { data: { missingPermission } });
        }
    }

    documentationCheck(doc: Documentation) {
        this.auth.refreshOrgs(true).subscribe(() => {
            if (!this.auth.isVerified()) {
                this.dialog.open(EmailVerifyDialogComponent, {
                    width: '500px',
                });
                return;
            }
            if (!this.auth.activeStudio || this.auth.activeStudio.status != 'approved') {
                this.dialog.open(NeedsApprovedOrgDialogComponent, {
                    width: '500px',
                });
                return;
            }
            if (this.auth.hasPermission(doc.platformPermission as Permission)) {
                this.http
                    .get<void>(`api/v1/documentation-signed-permission?page=${doc.link}`, { withCredentials: true })
                    .subscribe(
                        () => {
                            window.open(doc.link, '_blank');
                        },
                        (err) => {
                            if (err && err.error && err.error.data) {
                                this.snackBar.open(err.error.data, null, {
                                    duration: 5000,
                                    panelClass: 'bad-snack',
                                });
                            }
                        },
                    );
            } else {
                this.getVerified(doc.platformPermission as Permission);
            }
        });
    }

    documentationPageClicked(page: string) {
        this.http.get<void>(`api/v1/documentation-signed-permission?page=${page}`, { withCredentials: true }).subscribe(
            () => {
                window.open(page, '_blank');
            },
            (err) => {
                if (err && err.error && err.error.data) {
                    this.snackBar.open(err.error.data, null, {
                        duration: 5000,
                        panelClass: 'bad-snack',
                    });
                }
            },
        );
    }

    private ftueCheck() {
        if (
            this.auth.isAuthenticated() &&
            this.auth.activeStudio &&
            this.auth.activeStudio.status == 'approved' &&
            this.auth.user &&
            !this.auth.seenFTUE('ftue_org') &&
            this.auth.user.emailVerified &&
            !this.isSimpleEnterpriseURL()
        ) {
            this.onGettingStarted(true);
        }
    }

    private getSurveyInfo() {
        if (this.auth.user) {
            this.http.get<ApiResponse<Survey>>(`api/v1/survey`).subscribe((r) => {
                this.survey = r.data;
                setTimeout(() => this.checkSurveyPopup(), 5000);
            });
        }
    }

    private loadDocs() {
        if (
            !this.auth.isAuthenticated() ||
            (!this.auth.isSDKv4Org() && !this.auth.isSDKv5Org()) ||
            this.auth.isZendeskSSOReq() ||
            this.auth.isLoginRedirect()
        ) {
            return;
        }

        this.http.get<ApiResponse<Documentation[]>>(`api/v1/documentations?status=live`).subscribe((r) => {
            if (!r.data) {
                return;
            }

            const patt = /http.*\.com\/.*\/.*\/.*\/([a-zA-Z_0-9]*)\//i;
            this.docs = (r.data || []).filter((doc) => {
                const matches = patt.exec(doc.link);
                if (matches && matches.length > 1) {
                    return true;
                }
                return false;
            });
        });
    }

    private attachSidebarMinimizeClick(obs: Observable<{}>) {
        let that = this;
        obs.subscribe((e) => {
            if (!(e instanceof MouseEvent)) {
                return;
            }
            let el = (e as MouseEvent).srcElement as HTMLElement;
            let close = false;
            if (el.tagName && el.tagName.toUpperCase() == 'A') {
                close = true;
            } else {
                if (el.closest('A') !== null) {
                    close = true;
                }
            }
            if (close) {
                that.sidebarVisible = false;
            }
        });
    }

    public sidebarNowVisible() {
        // create mechanism to close mobile menu on click.
        let obs = fromEvent(this.sidebar.nativeElement.children, 'click');
        this.attachSidebarMinimizeClick(obs);
    }

    public orgMenuNowVisible() {
        // create mechanism to close mobile menu on click.
        let obs = of({});
        this.orgMenu.items.forEach((item) => {
            obs = obs.pipe(merge(fromEvent(item._getHostElement(), 'click')));
        });

        this.attachSidebarMinimizeClick(obs);
    }

    public checkPlanStartup(): boolean {
        if (
            !this.auth.isVivox &&
            this.auth.isAuthenticated() &&
            this.auth.activeStudio &&
            this.auth.activeStudio.status == 'approved' &&
            !this.auth.activeStudio.nonStandardMSA &&
            this.auth.user &&
            this.auth.user.emailVerified &&
            !this.auth.activeStudio.dedicatedServer &&
            !this.auth.activeStudio.enterpriseSupport &&
            !this.isSimpleEnterpriseURL()
        ) {
            this.openEnterpriseDialog(true);
            return true;
        }

        return false;
    }

    public checkPlan() {
        if (
            this.auth.activeStudio.nonStandardMSA ||
            this.auth.activeStudio.enterpriseSupport === EnterpriseSupport.PAID
        ) {
            this.showEnterpriseOptionsPopup = true;
        } else {
            this.openEnterpriseDialog(false);
        }
    }

    public openEnterpriseDialog(autoOpen: boolean) {
        if (
            this.enterpriseDialogOpen ||
            this.standalone ||
            this.enterpriseContactFormToggle ||
            this.declineDialogToggle
        ) {
            return;
        }
        this.enterpriseDialogOpen = true;
        this.enterpriseDialogRef = this.dialog.open(EnterpriseOptionsDialogComponent, {
            width: '900px',
            height: 'auto',
            maxHeight: '90vh',
            disableClose: true,
            panelClass: 'enterprise',
            data: {
                enterprise: this.auth.activeStudio.enterpriseSupport,
                dedicated: this.auth.activeStudio.dedicatedServer,
                billingAddress: this.auth.activeStudio.billingAddress,
                estimatedPCU: this.auth.activeStudio.estimatedPCU,
                estimatedGoLiveDate: this.auth.activeStudio.estimatedGoLiveDate,
            },
        });

        this.enterpriseDialogRef.afterClosed().subscribe((res) => {
            this.enterpriseDialogOpen = false;

            if (res && res.submit) {
                this.spinner = true;
                of(true)
                    .pipe(
                        mergeMap(() => {
                            if (res.support.includes('none') && res.server.includes('none')) {
                                return of(false);
                            }
                            return of(true);
                        }),
                        tap((show) => {
                            this.auth.activeStudio.enterpriseSupport = res.support;
                            this.auth.activeStudio.dedicatedServer = res.server;
                            this.auth.activeStudio.billingAddress = res.billingAddress;
                            this.auth.activeStudio.estimatedPCU = res.estimatedPCU;
                            if (res.estimatedGoLiveDate) {
                                const formatted = moment.utc(res.estimatedGoLiveDate).toISOString();
                                this.auth.activeStudio.estimatedGoLiveDate = formatted;
                            }
                            return show;
                        }),
                        mergeMap((show) => {
                            return this.api.saveOrg(this.auth.activeStudio).pipe(map(() => show));
                        }),
                    )
                    .subscribe(
                        (show) => {
                            this.spinner = false;
                            if (show) {
                                this.snackBar.open(this.translate.instant('user.enterprise-dialog.contact'), null, {
                                    duration: 3000,
                                });
                            }
                            this.router.navigate(['/']);
                        },
                        (err) => {
                            this.spinner = false;
                            console.log('Enterprise options save fail:', err);
                            this.snackBar.open(this.translate.instant('user.enterprise-dialog.saveFail'), null, {
                                duration: 3000,
                                panelClass: 'bad-snack',
                            });
                            this.router.navigate(['/']);
                        },
                    );
            }

            if (res && res.cancel && autoOpen) {
                this.declineService.show();
            }
        });
    }

    agreementDeclined() {
        this.declineService.hide();
    }

    showSupportDialog() {
        this.declineService.hide();

        this.enterpriseContactFormToggle = true;
    }

    supportMailSent() {}

    studioPickerPicked(orgId: number) {
        this.cookieService.activeStudio = orgId.toString();

        this.studioPickerToggle = false;
        this.working = true;
        this.api.getOrg(orgId).subscribe((newActive) => {
            this.auth.activeStudio = newActive;
            this.working = false;
            this.router.navigate(['/apps']);
        });
    }

    showSizzleReel(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();

        this.showSizzleReelPopup = true;
    }

    closeSizzleReel(close = false) {
        this.showSizzleReelPopup = false;

        if (close) {
            this.hideOtPromoMenu();
        }
    }

    continueMigration() {
        this.showMigrationPopup = true;
        this.cookieService.migrationAborted = false;
    }

    abortMigration() {
        this.showMigrationPopup = false;
        this.cookieService.migrationAborted = true;
    }

    async getMigrationUrl(orgId: number) {
        const selectedOrg = this.orgsNeedingMigration.find((org: QuickOrg) => org.id === orgId);
        if (selectedOrg != null) {
            this.loginMigrationUrl = null;
            this.signupMigrationUrl = null;
            const url = await this.api.getOrgMigrationUrl(selectedOrg.id).toPromise();
            [this.loginMigrationUrl, this.signupMigrationUrl] = formatMigrationUrl(url);
        }
    }

    private async populateOrgData() {
        if (this.auth.activeStudio) {
            const {
                id: activeOrgId,
                migratedToUdash,
                lastUpdated,
                migratedByName,
                migratedByEmail,
                migratedToOrgId,
                logo,
                name: orgName,
            } = this.auth.activeStudio;

            // Generic org data
            this.orgLogo = logo;
            this.orgName = orgName;

            // Migration modal window related related data
            this.showMigrationPopup = !this.cookieService.migrationAborted && migratedToUdash === 'NOT_MIGRATED';
            this.showAlreadyMigratedPopup = migratedToUdash === 'MIGRATED';
            this.showMigrationDeadlinePassedContent =
                !this.auth.isVivox &&
                migratedToUdash === 'NOT_MIGRATED' &&
                moment.utc().isAfter(moment.utc(environment.migrationEndDate));

            // Migration org data

            if (migratedToUdash !== 'NOT_NEEDED') {
                const url = await this.api.getOrgMigrationUrl(activeOrgId).toPromise();
                if (migratedToUdash === 'NOT_MIGRATED') {
                    [this.loginMigrationUrl, this.signupMigrationUrl] = formatMigrationUrl(url);
                }
                if (migratedToUdash === 'MIGRATED') {
                    this.migratedUrl = `${url.split('.com')[0]}.com/gaming/organizations/${migratedToOrgId}`;
                    this.migratedContent = this.translate.instant('user.migration.migrated.body', {
                        date: moment.utc(lastUpdated).format('MM/DD/YYYY'),
                        migratedBy: migratedByName,
                        migratorEmail: migratedByEmail,
                    });
                }
            }

            if (this.auth.organizations) {
                this.orgsNeedingMigration = this.auth.organizations.filter(
                    (org) => org.migratedToUdash === 'NOT_MIGRATED',
                );
            }
        }
    }

    private hideOtPromoMenu() {
        const triggers: HTMLElement[] = this.element.nativeElement.querySelectorAll('.submenu-trigger');
        triggers.forEach((trigger) => {
            this.renderer.removeClass(trigger, 'active');
        });
        this.renderer.removeClass(document.body, 'menu-open');
        this.renderer.removeClass(this.otPromoMenu.nativeElement, 'active');
        this.renderer.addClass(this.sidebarBlocker.nativeElement, 'hidden');
    }
}
