import { of as observableOf } from 'rxjs';

export class FTUEComponent {
    isActive = false;
    isExit = false;

    currentStep = 1;
    steps = 0;

    hide() {}

    prev() {}

    next() {}

    page(_page: number) {}

    hideForce() {}

    saveDone() {
        return observableOf(true);
    }

    return() {}
}
