import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { APIResponse } from '../api';
import { AuthService } from '../auth.service';
import { Org } from '../org.model';

@Component({
    selector: 'app-invite',
    templateUrl: './invite.component.html',
    styleUrls: ['./invite.component.scss'],
})
export class InviteComponent implements OnDestroy {
    inviteForm: FormGroup;
    orgs: Org[];
    onInviteSuccess = new Subject();

    working = false;

    errorMessage: string;

    private ngUnsubscribe = new Subject<void>();

    constructor(
        public dialogRef: MatDialogRef<InviteComponent>,
        public auth: AuthService,
        private fb: FormBuilder,
        private http: HttpClient,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private snackbar: MatSnackBar,
        private translate: TranslateService,
    ) {
        this.createForm();

        if (this.data) {
            return;
        }

        this.auth.activeStudioChange
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => this.inviteForm.patchValue({ org: this.auth.activeStudio.id }));

        this.http
            .get<APIResponse<Org[]>>(`api/v1/organizations?deleted=0&data=simple&s=approved`)
            .pipe(
                map((r) => {
                    r.data.sort((a, b) => {
                        return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
                    });
                    return r.data;
                }),
            )
            .subscribe((r) => (this.orgs = r));
    }

    createForm() {
        this.inviteForm = this.fb.group({
            email: ['', [Validators.required, Validators.pattern(/.+?@.+?/)]],
            org: [this.data ? this.data.orgId : this.auth.activeStudio.id, Validators.required],
        });
    }

    onSubmit() {
        if (!this.inviteForm.valid) {
            return;
        }

        this.working = true;
        this.errorMessage = '';

        this.http
            .post(`api/v1/invite/create`, {
                email: this.inviteForm.get('email').value,
                organization: this.inviteForm.get('org').value,
            })
            .subscribe(
                (res: any) => {
                    if (res.data && res.data === 'existing') {
                        this.snackbar.open(
                            this.translate.instant('user.apps.dashboard.inviteUserExistingSuccess'),
                            null,
                            {
                                duration: 3000,
                            },
                        );
                    } else {
                        this.snackbar.open(this.translate.instant('user.apps.dashboard.inviteUserSuccess'), null, {
                            duration: 3000,
                        });
                    }
                    this.working = false;
                    this.dialogRef.close();
                    this.onInviteSuccess.next();
                    this.onInviteSuccess.complete();
                },
                (err) => {
                    this.working = false;

                    const { data } = err.error;
                    switch (data) {
                        case 'not_org_member':
                            this.errorMessage = this.translate.instant('user.apps.dashboard.inviteError.notOrgMember');
                            break;
                        case 'user_not_exist':
                            this.errorMessage = this.translate.instant('user.apps.dashboard.inviteError.userNotExist');
                            break;
                        case 'user_not_verified':
                            this.errorMessage = this.translate.instant(
                                'user.apps.dashboard.inviteError.userNotVerified',
                            );
                            break;
                        case 'invalid_org':
                            this.errorMessage = this.translate.instant('user.apps.dashboard.inviteError.invalidOrg');
                            break;
                        case 'invalid_user':
                            this.errorMessage = this.translate.instant('user.apps.dashboard.inviteError.invalidUser');
                            break;
                        case 'org_member_already_exists':
                            this.errorMessage = this.translate.instant(
                                'user.apps.dashboard.inviteError.orgMemberAlreadyExists',
                            );
                            break;
                        case 'no_associated_org':
                            this.errorMessage = this.translate.instant(
                                'user.apps.dashboard.inviteError.noAssociatedOrg',
                            );
                            break;
                        case 'user_was_invited':
                            this.errorMessage = this.translate.instant(
                                'user.apps.dashboard.inviteError.userWasInvited',
                            );
                            break;
                    }

                    if (!this.errorMessage) {
                        this.errorMessage = this.translate.instant('user.apps.dashboard.inviteUserError');
                    }
                    this.snackbar.open(this.errorMessage, null, {
                        duration: 3000,
                        panelClass: 'bad-snack',
                    });
                    this.dialogRef.close();
                },
            );
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
