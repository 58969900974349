import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { MembersComponent } from 'app/shared/members/members.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AuthService } from '../../shared';
import { InviteComponent } from '../../shared/invite/invite.component';

@Component({
    selector: 'app-org-members',
    templateUrl: './org-members.component.html',
    styleUrls: ['./org-members.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrgMembersComponent implements OnInit {
    @ViewChild(MembersComponent) members;

    private ngUnsubscribe = new Subject<void>();
    orgId: number;
    working = false;

    constructor(public auth: AuthService, public dialog: MatDialog, private cdr: ChangeDetectorRef) {}

    ngOnInit() {
        this.orgId = this.auth.activeStudio.id;
        this.auth.activeStudioChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
            this.orgId = this.auth.activeStudio.id;
        });
    }

    inviteUser() {
        const dlg = this.dialog.open(InviteComponent, {
            width: '600px',
            data: {
                orgId: this.orgId,
            },
        });

        this.working = true;
        const subscription = dlg.componentInstance.onInviteSuccess.subscribe(
            () => {
                this.members.update();
                this.working = false;
            },
            () => (this.working = false),
        );

        dlg.afterClosed().subscribe(() => {
            this.working = false;
            this.cdr.detectChanges();
            subscription.unsubscribe();
        });
    }
}
