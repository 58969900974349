export interface Announcement {
    id: string;
    createdBy: string;
    type: string;
    subject: string;
    body: string;
    lastUpdated: string;
    media: string;
    mediaType: string;
    actionText: string;
    actionURL: string;
    sendAt: string;
    showUntil: string;
    youtubeEmbedUrl?: any;
}

export interface AnnouncementMediaUploadRequest {
    id: any;
    contentType: string;
    fileName: string;
}

export enum AnnouncementMediaType {
    NONE = 'none',
    IMAGE = 'image',
    YOUTUBE = 'youtube',
}

export enum AnnouncementType {
    FEATURES = 'features',
    MAINTENANCE = 'maintenance',
    EMERGENCY = 'emergency',
}
