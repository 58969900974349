import { AfterViewInit, Component } from '@angular/core';

import { AuthService } from '../../../shared/auth.service';
import { FTUEComponent } from '../ftue.component';

import { FTUEPreAppExitComponent } from './pre-app-exit.component';
import { FTUEPreAppComponent } from './pre-app.component';

@Component({
    selector: 'app-core-ftue-pre-app-splash',
    templateUrl: './pre-app-splash.component.html',
    styleUrls: ['../ftue.scss'],
})
export class FTUEPreAppSplashComponent extends FTUEComponent implements AfterViewInit {
    isActive = false;
    currentStep = 0;
    steps = 0;

    constructor(private auth: AuthService) {
        super();
    }

    ngAfterViewInit() {
        this.isActive = true;
    }

    onStartClick() {
        if (this.auth.isSDKue4Org() || this.auth.isSDKv4Org()) {
            (<any>this).goTo(FTUEPreAppComponent);
        } else {
            (<any>this).goTo(FTUEPreAppComponent, FTUEPreAppExitComponent);
        }
    }
}
